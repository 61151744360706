import { mergeAttributes, Node } from '@tiptap/core';
import './styles.scss';

const IntroZone = Node.create({
  name: 'introzone',
  group: 'block',
  content: 'block+',
  draggable: false,

  parseHTML() {
    return [
      {
        tag: 'div[id=introzone]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { id: 'introzone' }), 0];
  },
});

export default IntroZone;
