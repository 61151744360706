import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import handleLogout from '../../redux/logout';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from "react-redux";
import useMobileView from "../../hooks/mobileView"
import { t } from 'i18next';

function Header({ handleDrawer }) {
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const navigate = useNavigate();
  const isMobile = useMobileView();
  const dispatch = useDispatch();

  return (
    <>
      <nav className="bg-white fixed border-gray-200 z-40 w-full shadow-2">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4 sm:pl-9 pl-6 sm:pr-7 pr-2">
          <div className='flex flex-wrap items-center'>
            <button data-collapse-toggle="navbar-dropdown" type="button" className="items-center p-2 w-10 h-10 justify-center text-sm sm:ml-7 text-[#0000008a] xl:hidden block" aria-controls="navbar-dropdown" aria-expanded="false" onClick={handleDrawer}>
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
          </div>
          <div className='flex items-center sm:gap-6 gap-3 justify-between'>
            <div className="relative flex justify-center items-center group">
              <button
                className="hover:bg-blue-200 *: bg-blue-100 text-white disabled:bg-slate-200 disabled:text-slate-400 font-light px-5 py-[6px] rounded flex items-center"
                disabled={userInfo?.plan === "Demo"}
                onClick={() => navigate("/pricing")}
              >
                {userInfo?.plan === "Ultimate" ? t("Manage-plan","Gérer mon abonnement") : t("Upgrade-plan","Mettre à niveau mon abonnement")}
              </button>

              {/* Tooltip on hover */}
              {userInfo.plan === "Demo" &&
                <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 hidden group-hover:flex flex-col items-center">
                  <div className="w-3 h-2 bg-slate-600/80" style={{clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)"}}></div>
                  <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                    {t("Not available in demo mode", "Indisponible en mode démo")}
                  </span>
                </div>
              }
            </div>
            <button className='h-8 w-8 flex items-center justify-center hover:text-blue-50 bg-Lightwhite rounded-full  text-gray font-light' onClick={() => navigate('/profile')}>
              <PersonIcon sx={{ fontSize: "20px" }} />
            </button>
            <button className='h-8 w-8 flex items-center justify-center hover:text-blue-50 bg-Lightwhite rounded-full  text-gray font-light' onClick={() => handleLogout(dispatch)}>
              <LogoutIcon sx={{
                fontSize: "20px"
              }} />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;