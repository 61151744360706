import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { ClipLoader } from 'react-spinners';
import { DialogContent, DialogContentText } from '@mui/material';

export default function DeleteDialog({ text, open, setOpen, handleDelete, loading, title }) {
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus variant="contained" color="error">
            {loading ? <ClipLoader size={25} color="white" /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
