import React from 'react';
import { Box } from '@mui/material';
import { GenerationEditor, MainEditor, RewriteModal } from './FeatureOutputs'

const OutputEditorZone = ({ 
  mainOutput, 
  mainOutputHandler, 
  isNbOutputs,
  outputs=null,
  outputsHandler=null,
  removeOutputHandler=null,
  rewritenOutput=null,
  rewritenOutputHandler=null,
  customExtensions = [],
  customClasses = "",
  placeholder = "",
  showToolbar = true,
  toolbarButtons = [],
  rewriteOpen=false,
  handleRewriteClose=null,
  handleRewriteOpen=null,
  isReadOnly=false
}) => {


  return (
    <>
        <Box>
          <MainEditor 
            content={mainOutput}
            handleDataChange={mainOutputHandler}
            placeholder={placeholder}
            customClasses={customClasses}
            customExtensions={customExtensions}
            showToolbar={showToolbar}
            toolbarButtons={toolbarButtons}
            isReadOnly={isReadOnly}
          />
        </Box>

        {isNbOutputs ? 
          <Box>
            <h2>Contenus générés</h2>
            {outputs?.length > 0 ?
              outputs.map((output, index) => (
                <GenerationEditor
                  content={output}
                  handleDataChange={(content) => outputsHandler(index, content)}
                  customClasses={customClasses}
                  customExtensions={customExtensions}
                  onAdd={mainOutputHandler}
                  onRemove={() => removeOutputHandler(index)}
                  isReadOnly={isReadOnly}
                />
              ))
              :
              <p>Les contenus que vous générerez s'afficheront ici.</p>
            }
          </Box>
        :
          <Box mt={2}>
            {rewritenOutput ?
              <RewriteModal
                rewriteOpen={rewriteOpen}
                handleRewriteOpen={handleRewriteOpen}
                handleRewriteClose={handleRewriteClose}
                mainOutput={mainOutput}
                mainOutputHandler={mainOutputHandler}
                rewritenOutput={rewritenOutput}
                rewritenOutputHandler={rewritenOutputHandler}
                placeholder={placeholder}
                customClasses={customClasses}
                customExtensions={customExtensions}
                showToolbar={showToolbar}
                toolbarButtons={toolbarButtons}
                isReadOnly={isReadOnly}
              />
            :
              <p>Aucune version réécrite pour le moment.</p>
            }
          </Box>
          
        }
       
    </>
  );
};

export default OutputEditorZone;