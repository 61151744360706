const isDevMode = process.env.REACT_APP_ST_DEV === "true";

const stripeConfig = {
  publishableKey: isDevMode
    ? process.env.REACT_APP_ST_TPK
    : process.env.REACT_APP_ST_LPK,
  priceIds: {
    monthly: {
      standard: isDevMode
        ? process.env.REACT_APP_ST_TMSI
        : process.env.REACT_APP_ST_LMSI,
      premium: isDevMode
        ? process.env.REACT_APP_ST_TMPI
        : process.env.REACT_APP_ST_LMPI,
      ultimate: isDevMode
        ? process.env.REACT_APP_ST_TMUI
        : process.env.REACT_APP_ST_LMUI,
    },
    yearly: {
      standard: isDevMode
        ? process.env.REACT_APP_ST_TYSI
        : process.env.REACT_APP_ST_LYSI,
      premium: isDevMode
        ? process.env.REACT_APP_ST_TYPI
        : process.env.REACT_APP_ST_LYPI,
      ultimate: isDevMode
        ? process.env.REACT_APP_ST_TYUI
        : process.env.REACT_APP_ST_LYUI,
    },
  },
};

export default stripeConfig;