import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import {
  Account,
  Subscription,
  ChangePassword,
  LinkOpenAI,
  ManageVariable,
} from "./index";
import { ChangeEmail } from "./emailChange";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Profile = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("account");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialTab = query.get("tab") || "account";

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  const tabs = [
    { name: t("Personal-infos", "Informations personnelles"), value: "account" },
    { name: t("Subscription", "Abonnement"), value: "subscription" },
    { name: t("Change-password", "Changer le mot de passe"), value: "change-password" },
    { name: t("Change-email-adress", "Changer l'adresse email"), value: "change-email" },
    { name: t("Generation-variables", "Variables de génération"), value: "manage-variables" },
    { name: t("OpenAI-integration", "Intégration OpenAI"), value: "integrate-openai" },
  ];

  return (
    <>
      <Helmet>
        <title>{t("my-account", "Mon compte")} | BotBlogR</title>
      </Helmet>

      <div className="mt-3 px-2 pl-6 pr-6">

        <div>
          <div className="sm:hidden">
            <select
              id="tabs"
              name="tabs"
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              className="block w-full bg-blue-50 text-white rounded-md border-gray-300 focus:outline-none p-2"
            >
              {tabs.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <nav
              aria-label="Tabs"
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            >
              {tabs.map((tab, tabIdx) => (
                <button
                  key={tab.value}
                  onClick={() => handleChange(tab.value)}
                  className={classNames(
                    tab.value === value
                      ? "text-blue-50 border-b border border-blue-50 hover:bg-white"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-black/10"
                  )}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.value === value ? "bg-blue-50" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </button>
              ))}
            </nav>
          </div>

        </div>

        <div className="mt-6">
          {value === "account" && <Account />}
          {value === "subscription" && <Subscription />}
          {value === "change-password" && <ChangePassword />}
          {value === "change-email" && <ChangeEmail />}
          {value === "manage-variables" && <ManageVariable />}
          {value === "integrate-openai" && <LinkOpenAI />}
        </div>

      </div>
    </>
  );
};

export default Profile;
