import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Outlet } from 'react-router-dom';
import blogBot from "../../assets/images/botblogrLogo-black.png";

const AuthLayout = () => {
    const location = useLocation();
    const { t } = useTranslation();
    

    const getTitle = (pathname) => {
        switch (true) {
            case pathname.startsWith('/login'):
                return t("signin", "Se connecter");
            case pathname.startsWith('/signup'):
                return t("signup", "Inscrivez-vous !");
            case pathname.startsWith('/forgot-password'):
                return 'Mot de passe oublié ?';
            case pathname.startsWith('/reset-password'):
                return "Changer le mot de passe"
            case pathname.startsWith("/confirmation"):
                return t("pending-confirmation", "Confirmation en attente")
            default:
                return 'Welcome';
        }
    };

    const pageTitle = getTitle(location.pathname)

  return (
    location.pathname.startsWith('/confirm-email') || location.pathname.startsWith('/oauth-login') ? (
        <div className="flex items-center justify-center min-h-screen">
            <Outlet />
        </div>
    ) : (
        <div className="flex items-center justify-center py-[55px]">
            <div className="flex flex-1 max-w-md md:max-w-xl bg-white rounded-2xl shadow-sm mx-auto w-[90%] py-4">
                <div className="flex flex-1 flex-col justify-center px-4 py-6 sm:px-6 lg:px-10">
                    <div className="mx-auto w-full max-w-sm md:max-w-lg">
                        <div className="flex justify-center">
                            <img
                                alt="Your Company"
                                src={blogBot}
                                className="h-10 w-auto"
                            />
                        </div>
                        <h1 className="mt-4 mb-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            {pageTitle}
                        </h1>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
  ));
};

export default AuthLayout;