import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsage: builder.query({
      query: () => ({
        url: ENDPOINTS.DASHBOARD_USAGE,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getGuideInfo: builder.query({
      query: () => ({
        url: ENDPOINTS.DASHBOARD_GET_STARTED_GUIDE,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateGuideInfo: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.DASHBOARD_GET_STARTED_GUIDE,
        method: "PUT",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getAllFavoriteFeatures: builder.query({
      query: () => ({
        url: ENDPOINTS.DASHBOARD_FAVORITE_FEATURES,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllFavoriteFeaturesIds: builder.query({
      query: () => ({
        url: `${ENDPOINTS.DASHBOARD_FAVORITE_FEATURES}ids/`,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    addFavoriteFeature: builder.mutation({
      query: (feature_id) => ({
        url: `${ENDPOINTS.DASHBOARD_FAVORITE_FEATURES}${feature_id}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteFavoriteFeature: builder.mutation({
      query: (feature_id) => ({
        url: `${ENDPOINTS.DASHBOARD_FAVORITE_FEATURES}${feature_id}`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getLastBlogPosts: builder.query({
      query: () => ({
        url: ENDPOINTS.DASHBOARD_LAST_BLOG_POSTS,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    createNotification: builder.mutation({
      query: (notification) => ({
        url: `${ENDPOINTS.DASHBOARD_NOTIFICATIONS}/new/`,
        method: "POST",
        body: notification
      }),
      extraOptions: { requiresAuth: true },
    }),
    getNotification: builder.query({
      query: (notification_id, notification_type) => ({
        url: `${ENDPOINTS.DASHBOARD_NOTIFICATIONS}/${notification_id}/`,
        body: notification_type
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllNotification: builder.query({
      query: () => ({
        url: ENDPOINTS.DASHBOARD_NOTIFICATIONS,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateNotification: builder.mutation({
      query: (notification_id, notification) => ({
        url: `${ENDPOINTS.DASHBOARD_NOTIFICATIONS}/${notification_id}/`,
        method: "PUT",
        body: notification
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteNotification: builder.mutation({
      query: ({notification_id, notification_type}) => ({
        url: `${ENDPOINTS.DASHBOARD_NOTIFICATIONS}/${notification_id}/?notification_type=${notification_type}`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    dismissNotification: builder.mutation({
      query: ({notification_id, notification_type}) => ({
        url: `${ENDPOINTS.DASHBOARD_NOTIFICATIONS}${notification_id}/dismiss/?notification_type=${notification_type}`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
  }),
});

export const {
    useGetGuideInfoQuery,
    useUpdateGuideInfoMutation,
    useGetUsageQuery,
    useGetAllFavoriteFeaturesQuery,
    useGetAllFavoriteFeaturesIdsQuery,
    useAddFavoriteFeatureMutation,
    useDeleteFavoriteFeatureMutation,
    useGetLastBlogPostsQuery,
    useCreateNotificationMutation,
    useGetNotificationQuery,
    useGetAllNotificationQuery,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
    useDismissNotificationMutation
} = dashboardApiSlice;
