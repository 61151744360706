import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { BsMagic } from 'react-icons/bs';
import { useGenerateCustomMutation } from '../../../../../redux/slices/featuresApiSlice';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useWebSocket } from '../WebSocketContext';
import { toast } from 'react-toastify';

const CustomModal = ({ editor, customData }) => {
  const { t } = useTranslation();
  const {
    setOpenCustomModal,
    bufferRef,
    cleanGeneratedContent
  } = useWebSocket();
  const [generateCustom, { isLoading }] = useGenerateCustomMutation();
  const formikProps = useFormikContext();
  const [prompt, setPrompt] = useState('');

  const handleGenerateCustom = async () => {
    try {
      let payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        prompt: prompt
      };

      console.log(`custom generation payload: `, payload);

      const res = await generateCustom({...payload, ...{ stream: true }}).unwrap();

      if (res?.status !== 200) {
        toast.error(t("something-went-wrong-with-generation", "Une erreur s'est produite lors de la génération"))
        return;
      }

      console.log(`custom generation res: `, res);

      editor
        .chain()
        .focus()
        .deleteRange({ from: customData.startOfCustom , to: editor.state.selection.$from.end(2) })
        .insertContentAt(customData.startOfCustom, cleanGeneratedContent(res?.data))
        .run();

      setOpenCustomModal(false);
      bufferRef.current = "";
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else if (error?.data?.detail === "Access forbidden: you must have registered a functional OpenAI API Key to generate contents") {
        toast.error(t("missing-api-key", "Vous devez avoir une clé API active pour générer du contenu"))
      } else {
        toast.error(t("something-went-wrong-with-generation", "Une erreur s'est produite lors de la génération"))
      }
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        //overflow: { md: 'unset', xs: 'scroll' },
        height: '100vh',
        width: { md: 'calc(100vw - 350px)', xs: '100%' },
        display: 'flex',
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 99999,
      }}
    >
      <div className="m-auto bg-white rounded-xl w-[80%] max-w-[500px]">
        <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
          <p>{t("Generate-custom-instruction", "Générer un contenu personnalisé")}</p>
          <Close
            onClick={() => setOpenCustomModal(false)}
            className="cursor-pointer"
            sx={{ fontSize: 32 }}
          />
        </div>
        <div className="p-4">
          <div className="mb-4">
            <p className="mb-2">{t("custom-instructions", "Instructions personnalisées")}</p>
            <TextField
              id="prompt"
              name="prompt"
              required={true}
              fullWidth
              onChange={(e) => setPrompt(e.target.value)}
              multiline={true}
              minRows={2}
              maxRows={18}
              placeholder={
                'Ex: ' +
                t(
                  'placeholder-custom-instructions',
                  "Crée un tableau des meilleurs outils SEO ...'"
                )
              }
            />
          </div>

          <LoadingButton
            startIcon={<BsMagic />}
            loading={isLoading}
            onClick={handleGenerateCustom}
            variant="contained"
            sx={{
              textTransform: 'none',
              bgcolor: '#8b5cf6',
              '&:hover': {
                bgcolor: '#7c3aed'
              }
            }}
          >
            {t(
              'Generate-custom-introduction',
              'Générer le contenu personnalisé'
            )}
          </LoadingButton>
        </div>
      </div>
    </Box>
  );
};

export default CustomModal;
