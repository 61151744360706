import React from 'react';
import './BaseToolbar.css';
import {
  BoldIcon,
  ItalicIcon,
  StrikeThroughIcon,
  UnderlineIcon,
  ParagraphIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon
} from '../../../others/Icons';

const BaseToolbar = ({
  editor,
  buttons = []
}) => {

  if (!editor) {
    return null;
  }

  const renderButton = (button) => {
    switch (button) {
      case 'bold':
        return (
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <BoldIcon />
          </button>
        );
      case 'italic':
        return (
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <ItalicIcon />
          </button>
        );
      case 'strike':
        return (
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            <StrikeThroughIcon />
          </button>
        );
      case 'underline':
        return (
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={!editor.can().chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'is-active' : ''}
          >
            <UnderlineIcon />
          </button>
        );
      case 'tag-selector':
        return (
          <select
            onChange={(event) => {
              const level = event.target.value;
              if (level === 'paragraph') {
                editor.chain().focus().setParagraph().run();
              } else {
                editor.chain().focus().toggleHeading({ level: parseInt(level) }).run();
              }
            }}
          >
            <option value="paragraph"><ParagraphIcon /> Paragraph</option>
            <option value="1"><Heading1Icon /> Heading 1</option>
            <option value="2"><Heading2Icon /> Heading 2</option>
            <option value="3"><Heading3Icon /> Heading 3</option>
            <option value="4"><Heading4Icon /> Heading 4</option>
            <option value="5"><Heading5Icon /> Heading 5</option>
            <option value="6"><Heading6Icon /> Heading 6</option>
          </select>
        );
      default:
        return null;
    }
  };

  return (
    <div className='toolbar-container'>
      {buttons.map((button) => renderButton(button))}
    </div>
  );
};

export default BaseToolbar;