import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const VerifiedRoute = () => {
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const notify = () => {
    toast.error('You need to verify your account to access this page.');
  };
  return <>{userInfo.is_verified ? <Outlet /> : <>{notify()} <Navigate to="/confirmation" replace /></>}</>;
};
export default VerifiedRoute;
