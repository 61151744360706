import { Button, Drawer, IconButton } from '@mui/material';
import './styles.css';

import { Close, Menu } from '@mui/icons-material';
import { useState } from 'react';
import { SidePanel } from './SidePanel';
import { useSelector } from 'react-redux';

const MobileSidePanel = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton
        sx={{
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: 9999,
        }}
        onClick={() => setOpen(true)}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onClick={() => setOpen(false)}
        >
          <Close />
        </IconButton>
        <SidePanel />
      </Drawer>
    </div>
  );
};

export default MobileSidePanel;
