import './styles.css';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { dispatch } from '../../../../../redux/store';
import { setActivePanel } from '../../../../../redux/slices/panelSlice';

export const SidePanel = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        color: 'black',
        overflow: 'scroll',
        px: '15px',
        pt: 3,
        width: { sm: '350px', xs: 'auto' },
        bgcolor: 'whitesmoke',
        borderLeft: '3px solid #e5e7eb',
      }}
    >
      <div>
        <p className="text-center font-semibold mb-4">
          {t('Page-analysis', 'Analyse de la page')}
        </p>

        <div className="mb-4">
          <p className="font-semibold mb-2">
            {t('Preparation', 'Préparation')}
          </p>
          <div
            onClick={() => dispatch(setActivePanel('outline'))}
            className="panel-button"
          >
            {t('Outline', 'Plan')}
          </div>
        </div>

        <div className="mb-4">
          <p className="font-semibold mb-2">{t('Redaction', 'Rédaction')}</p>
          <div className="flex flex-col gap-2">
            <div
              onClick={() => dispatch(setActivePanel('urlSlug'))}
              className="panel-button"
            >
              {t('URL-Slug', "Slug d'URL")}
            </div>
            <div
              onClick={() => dispatch(setActivePanel('h1Title'))}
              className="panel-button"
            >
              {t('H1-tag', 'Titre (Balise H1)')}
            </div>
            <div
              onClick={() => dispatch(setActivePanel('content'))}
              className="panel-button"
            >
              {t('Content', 'Contenu')}
            </div>
            <div
              onClick={() => dispatch(setActivePanel('metaData'))}
              className="panel-button"
            >
              {t('Metadatas', 'Métadonnées')}
            </div>
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">
            {t('Optimization', 'Optimisation')}
          </p>
          <div className="flex flex-col gap-2">
            <div
              onClick={() => dispatch(setActivePanel('semantics'))}
              className="panel-button"
            >
              {t('Semantics', 'Sémantique')}
            </div>
            <div
              onClick={() => dispatch(setActivePanel('mesh'))}
              className="panel-button"
            >
              {t(
                'Interna-and-external-linking',
                'Maillage (interne et externe)'
              )}
            </div>
            <div
              onClick={() => dispatch(setActivePanel('medias'))}
              className="panel-button"
            >
              {t('Medias', 'Médias (Images et vidéos)')}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const DesktopSidePanel = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        right: 0,
        left: { sm: 'unset', xs: 0 },
        mt: '48px',
        zIndex: 999,
      }}
    >
      <SidePanel />
    </Box>
  );
};

export default DesktopSidePanel;
