import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import {  useSelector } from "react-redux";
import { FeaturesTemplate, InputFormZone, OutputTableZone, GenerationVariablesZone } from '../../../../shared/components/features/FeaturesTemplate';
import { GlobalTextField, GlobalNumberField } from '../../../../shared/components/features/FeaturesTemplate/FeatureFields'
import withFormik from '../../../../shared/utils/features/withFormik';
import {
  useGetAllLexicalFieldQuery,
  useGenerateLexicalFieldMutation,
  useCreateLexicalFieldMutation,
  useGetSingleLexicalFieldMutation,
  useUpdateSingleLexicalFieldMutation,
  useDeleteSingleLexicalFieldMutation
} from "../../../../redux/slices/featuresApiSlice";
import { InitialValues, validationConfig, Schema, tableColumns, tableHeaders} from "./helper";

const output_key = "lexical_field_table";

const LexicalFieldObject = ({ formikProps, config }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const isReadOnly = userInfo.payment_status === "pending";

  return (
    <FeaturesTemplate
      title={location?.state?.data?.["h1-tag"]}
      description={location?.state?.data?.description}
      formikProps={formikProps}
      onSave={config.handleSave}
      onDelete={config.handleDelete}
      loadingSave={config.createLoading || config.updateLoading}
      loadingDelete={config.deleteLoading}
      deleteButton={config.deleteButton}
      isReadOnly={isReadOnly}
    >
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <InputFormZone
          selectText={t("select-text-lexical-field", "Créer un nouveau ou modifier un champ lexical")}
          generateText={t("generate-lexical-field", "Générer des champs lexicaux")}
          rewriteText={t("rewrite-lexical-field", "Générer des mots supplémentaires")}
          formikProps={formikProps}
          contents={config.contents}
          isTable={true}
          isNbOutputs={validationConfig.hasOwnProperty('nb_outputs')}
          tableOutput={config.tableOutput}
          handleGetSingle={config.handleGetSingle}
          handleCreateNew={config.handleCreateNew}
          setDeleteButton={config.setDeleteButton}
          isLoading={config.generateLoading}
          validationConfig={validationConfig}
          isReadOnly={isReadOnly}
          generationVariablesPanel={
            <GenerationVariablesZone
              formikProps={formikProps}
              isWriting={false}
              isCompany={false}
              isProduct={false}
              writingOpen={config.writingOpen}
              companyOpen={config.companyOpen}
              productOpen={config.productOpen}
              writingStylesVariables={config.writingStyles}
              companyVariables={config.companyVariables}
              productVariables={config.productVariables}
              handleWritingOpen={config.handleWritingOpen}
              handleCompanyOpen={config.handleCompanyOpen}
              handleProductOpen={config.handleProductOpen}
              handleWritingClose={config.handleWritingClose}
              handleCompanyClose={config.handleCompanyClose}
              handleProductClose={config.handleProductClose}
              isReadOnly={isReadOnly}
            />
          }
        >
          <GlobalTextField
            formikProps={formikProps}
            text={t("words", "Mots à utiliser pour générer le champ lexical (à séparer par des virgules)")}
            name="words"
            placeholder={t("words-ex", "Ex: seo,référencement naturel,optimisation,moteurs de recherche ...")}
            isRequired={validationConfig.words.required}
            multipleLines={true}
            maxLength={validationConfig.words.max}
            isReadOnly={isReadOnly}
          />

          <GlobalTextField
            formikProps={formikProps}
            text={t("excluded-words", "Mots à exclure des résultats (à séparer par des virgules)")}
            name="excluded_words"
            placeholder={t("excluded-words-ex", "Ex: sea,référencement,Google ...")}
            isRequired={validationConfig.excluded_words.required}
            multipleLines={true}
            maxLength={validationConfig.excluded_words.max}
            isReadOnly={isReadOnly}
          />

          <GlobalNumberField 
            formikProps={formikProps}
            text={t("nb-words-table", "Nombre (approximatif) de mots à générer dans le champ lexical")}
            name="nb_words"
            isRequired={validationConfig.nb_words.required}
            minValue={validationConfig.nb_words.min}
            maxValue={validationConfig.nb_words.max}
            isReadOnly={isReadOnly}
          />
          
        </InputFormZone>
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
        <OutputTableZone
          columns={tableColumns}
          headers={tableHeaders}
          rows={config.tableOutput}
          rowsDelete={config.tableOutputRemove}
          allowCellDelete={true}
          onRowDelete={config.handleRowDelete}
          onCellDelete={config.handleCellDelete}
          filename={config.singleContent ? `${config.singleContent.obj_name}-lexical-field` : "lexical-field.csv"}
          isReadOnly={isReadOnly}
        />
      </Grid>

    </FeaturesTemplate>
  );
};

const LexicalField = withFormik(LexicalFieldObject, {    
  output_key: output_key,
  useGenerateContentMutation: useGenerateLexicalFieldMutation, 
  useCreateContentMutation: useCreateLexicalFieldMutation, 
  useGetSingleContentMutation: useGetSingleLexicalFieldMutation, 
  useUpdateContentMutation: useUpdateSingleLexicalFieldMutation, 
  useDeleteContentMutation: useDeleteSingleLexicalFieldMutation, 
  useGetAllContentsQuery: useGetAllLexicalFieldQuery,
  InitialValues: InitialValues,
  Schema: Schema,
  isTable: output_key.includes("_table"),
  isNbOutputs: validationConfig.hasOwnProperty('nb_outputs')
});

export default LexicalField;