import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import {  useSelector } from "react-redux";
import withFormik from '../../../../shared/utils/features/withFormik';
import { CustomHeading, CustomDocument } from '../../../../shared/components/features/FeaturesTemplate/FeatureOutputs/CustomDocuments/SingleH1Tag';
import { FeaturesTemplate, InputFormZone, OutputEditorZone, GenerationVariablesZone } from '../../../../shared/components/features/FeaturesTemplate';
import { GlobalTextField } from '../../../../shared/components/features/FeaturesTemplate/FeatureFields'
import {
  useGetAllPostH1TagQuery,
  useGeneratePostH1TagMutation,
  useCreatePostH1TagMutation,
  useGetSinglePostH1TagMutation,
  useUpdateSinglePostH1TagMutation,
  useDeleteSinglePostH1TagMutation
} from "../../../../redux/slices/featuresApiSlice";
import { InitialValues, validationConfig, Schema } from "./helper";

const output_key = "h1_tag";

const PostH1TagObject = ({ formikProps, config }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const isReadOnly = userInfo.payment_status === "pending";

  return (
    <FeaturesTemplate
      title={location?.state?.data?.["h1-tag"]}
      description={location?.state?.data?.description}
      formikProps={formikProps}
      onSave={config.handleSave}
      onDelete={config.handleDelete}
      loadingSave={config.createLoading || config.updateLoading}
      loadingDelete={config.deleteLoading}
      deleteButton={config.deleteButton}
      isReadOnly={isReadOnly}
    >
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <InputFormZone
          selectText={t("select-text-post-h1-tag", "Créer un nouveau ou modifier un ancien titre H1")}
          generateText={t("generate-post-h1-tag", "Générer des titre H1")}
          rewriteText={t("rewrite-post-h1-tag", "Générer des autres titres H1")}
          formikProps={formikProps}
          contents={config.contents}
          contentNameKey="subject"
          isTable={false}
          isNbOutputs={validationConfig.hasOwnProperty('nb_outputs')}
          outputs={config.outputs}
          mainOutput={config.mainOutput}
          handleGetSingle={config.handleGetSingle}
          handleCreateNew={config.handleCreateNew}
          setDeleteButton={config.setDeleteButton}
          isLoading={config.generateLoading}
          validationConfig={validationConfig}
          isReadOnly={isReadOnly}
          generationVariablesPanel={
            <GenerationVariablesZone
              formikProps={formikProps}
              isWriting={true}
              isCompany={true}
              isProduct={true}
              writingOpen={config.writingOpen}
              companyOpen={config.companyOpen}
              productOpen={config.productOpen}
              writingStylesVariables={config.writingStyles}
              companyVariables={config.companyVariables}
              productVariables={config.productVariables}
              handleWritingOpen={config.handleWritingOpen}
              handleCompanyOpen={config.handleCompanyOpen}
              handleProductOpen={config.handleProductOpen}
              handleWritingClose={config.handleWritingClose}
              handleCompanyClose={config.handleCompanyClose}
              handleProductClose={config.handleProductClose}
              isReadOnly={isReadOnly}
            />
          }
        >
          <GlobalTextField
            formikProps={formikProps}
            text={t("subject", "Sujet")}
            name="subject"
            placeholder={t("subject-ex", "Ex: Un article de blog sur comment utiliser BotBlogR pour booster son SEO ...")}
            isRequired={validationConfig.subject.required}
            multipleLines={true}
            maxLength={validationConfig.subject.max}
            isReadOnly={isReadOnly}
          />

          <GlobalTextField
            formikProps={formikProps}
            text={t("seo-target-request", "Requête SEO cible")}
            name="seo_request"
            placeholder={t("seo-target-request-ex", "Ex: Optimiser SEO Blog")}
            isRequired={validationConfig.seo_request.required}
            multipleLines={false}
            maxLength={validationConfig.seo_request.max}
            isReadOnly={isReadOnly}
          />

          <GlobalTextField
            formikProps={formikProps}
            text={t("outline-hn-structure", "Plan de l'article (structure en hn)")}
            name="outline"
            placeholder={t("outline-ex", "Ex: <h2>Introduction</h2><h2>Pourquoi optimiser son Blog pour le SEO ?</h2>...")}
            isRequired={validationConfig.outline.required}
            multipleLines={true}
            maxLength={validationConfig.outline.max}
            isReadOnly={isReadOnly}
          />
          
        </InputFormZone>
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
        <OutputEditorZone
          mainOutput={config.mainOutput}
          mainOutputHandler={config.mainOutputHandler} 
          isNbOutputs={validationConfig.hasOwnProperty('nb_outputs')}
          outputs={config.outputs}
          outputsHandler={config.outputsHandler}
          removeOutputHandler={config.removeOutputHandler}
          showToolbar={false}
          customExtensions={[CustomHeading, CustomDocument]}
          placeholder={t("post-h1-tag-placeholder", "Votre titre H1 ...")}
          isReadOnly={isReadOnly}
        />
      </Grid>

    </FeaturesTemplate>
  );
};

const PostH1Tag = withFormik(PostH1TagObject, {    
  output_key: output_key,
  useGenerateContentMutation: useGeneratePostH1TagMutation, 
  useCreateContentMutation: useCreatePostH1TagMutation, 
  useGetSingleContentMutation: useGetSinglePostH1TagMutation, 
  useUpdateContentMutation: useUpdateSinglePostH1TagMutation, 
  useDeleteContentMutation: useDeleteSinglePostH1TagMutation, 
  useGetAllContentsQuery: useGetAllPostH1TagQuery,
  InitialValues: InitialValues,
  Schema: Schema,
  isTable: output_key.includes("_table"),
  isNbOutputs: validationConfig.hasOwnProperty('nb_outputs')
});

export default PostH1Tag;