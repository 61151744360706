import { CSVLink } from "react-csv";
import { Box, Button } from "@mui/material";
import { MainTable } from "./FeatureOutputs";

const OutputTableZone = ({
    columns,
    headers,
    rows,
    rowsDelete,
    allowCellDelete=false,
    allowRowDelete=false,
    onRowDelete,
    onCellDelete,
    filename,
    isReadOnly=false
}) => {

    return (
        <>
            <Button variant="contained" color="success">
                <CSVLink
                    data={rows && rows?.length > 0 ? rows : []}
                    headers={headers}
                    filename={filename}
                    style={{ textDecoration: "none", color: "white" }}
                >
                    Télécharger au format CSV
                </CSVLink>
            </Button>

            <Button variant="contained" color="error" onClick={rowsDelete}>
                Supprimer le contenu du tableau
            </Button>


            <Box pt={2}>
                <MainTable
                    rows={rows}
                    columns={columns}
                    filterMode = "client"
                    allowRowDelete={allowRowDelete}
                    allowCellDelete={allowCellDelete}
                    onRowDelete={onRowDelete}
                    onCellDelete={onCellDelete}
                    isReadOnly={isReadOnly}
                />
            </Box>
        </>
    )
}

export default OutputTableZone;