import { DataGrid } from "@mui/x-data-grid";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const MainTable = ({
  rows,
  columns,
  filterMode = "client",
  allowRowDelete = false,
  allowCellDelete = false,
  onRowDelete,
  onCellDelete,
  isReadOnly=false
}) => {

  const handleRowDelete = (index) => {
    if (onRowDelete) {
      onRowDelete(index);
    }
  };

  const handleCellDelete = (index, field) => {
    if (onCellDelete) {
      onCellDelete(index, field);
    }
  };

  const enhancedColumns = columns.map((col) => ({
    ...col,
    renderCell: (params) => (
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        {params.value}
        {allowCellDelete && (
          <IconButton
            size="small"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => handleCellDelete(params.row.rowIndex, params.field)}
            disabled={isReadOnly}
          >
            <Delete fontSize="small" />
          </IconButton>
        )}
      </div>
    )
  }));

  if (allowRowDelete) {
    enhancedColumns.push({
      field: 'delete',
      headerName: 'Delete',
      renderCell: (params) => (
        <Delete
          style={{ cursor: 'pointer' }}
          onClick={() => handleRowDelete(params.row.rowIndex)}
          disabled={isReadOnly}
        />
      ),
      sortable: false,
      width: 50,
    });
  }

  return (
    <DataGrid
      rows={rows.map((row, index) => ({ ...row, rowIndex: index }))}
      columns={enhancedColumns}
      getRowId={(row) => row.rowIndex}
      className="table-header"
      filterMode={filterMode}
      sx={{ cursor: "pointer" }}
      rowHeight={50}
      autoHeight
      hideFooter={true}
      hideFooterRowCount={true}
    />
  );
};

export default MainTable;