import generateYupSchema from '../../FeaturesTemplate/CreateFeatureValidationSchema';

export const initialValues = {
  style_id: 0,
  company_id: 0,
  product_id: 0,
  
  subject: '',
  seo_request: '',
  nb_words: 1500,
  nb_parts: 3,
  outline: '',
  outline_rewrite: '',

  //URL SLUG
  url_path: '',
  nb_url_slug: 3,
  url_slug_ideas: [],
  url_slug: '',

  //H1 TITLE
  nb_h1_title: 3,
  h1_title_ideas: [],
  h1_title: '',

  //CONTENT
  introduction_length: '',
  // nb_introduction: 2,
  // introduction_ideas: [],
  introduction: '',
  introduction_rewrite: '',

  content: '',
  content_rewrite: '',

  conclusion_length: '',
  transition_article: '',
  // nb_conclusion: 2,
  // conclusion_ideas: [],
  conclusion: '',
  conclusion_rewrite: '',

  //METADATAS
  nb_meta_title: 3,
  meta_title_ideas: [],
  meta_title: '',
  nb_meta_description: 3,
  meta_description_ideas: [],
  meta_description: '',
};

export const globalValidationConfig = {
  subject: {
    type: 'string',
    max: 2000,
    required: true,
    requiredMessage: 'Le sujet est requis',
    maxMessage: 'Le sujet doit faire un maximum de 2000 caractères',
  },
  seo_request: {
    type: 'string',
    max: 100,
    required: true,
    requiredMessage: 'La requête SEO cible est requise',
    maxMessage: 'La requête SEO cible doit faire un maximum de 100 caractères',
  },
  nb_parts: {
    type: 'number',
    min: 2,
    max: 100,
    required: true,
    minMessage: 'Le nombre de h2 minimum est 2',
    maxMessage: 'Le nombre de h2 maximum est 100',
    requiredMessage: 'Le nombre de h2 est requis',
  },
  nb_words: {
    type: 'number',
    min: 200,
    max: 50000,
    required: true,
    requiredMessage: 'Le nombre de mots est requis',
    minMessage: 'Le nombre de mots minimum est 200',
    maxMessage: 'Le nombre de mots maximum est 50000',
  },
  outline: {
    type: 'string',
    max: 20000,
    required: false,
    maxMessage: "L'outline doit faire un maximum de 2000 caractères",
  },
  outline_rewrite: {
    type: 'string',
    max: 2000,
    required: false,
    maxMessage: "L'outline réécrit doit faire un maximum de 2000 caractères",
  },
  url_path: {
    type: 'string',
    max: 500,
    required: false,
    maxMessage: 'Le chemin doit faire un maximum de 500 caractères',
  },
  nb_url_slug: {
    type: 'number',
    min: 1,
    max: 5,
    required: true,
    requiredMessage: 'Le nombre de slugs URL est requis',
    minMessage: 'Le nombre de slugs URL minimum est 1',
    maxMessage: 'Le nombre de slugs URL maximum est 5',
  },
  url_slug_ideas: {
    type: 'array',
    required: false,
  },
  url_slug: {
    type: 'string',
    max: 200,
    required: false,
    maxMessage: 'Le slug URL doit faire un maximum de 200 caractères',
  },
  nb_h1_title: {
    type: 'number',
    min: 1,
    max: 5,
    required: true,
    requiredMessage: 'Le nombre de titres H1 est requis',
    minMessage: 'Le nombre de titres H1 minimum est 1',
    maxMessage: 'Le nombre de titres H1 maximum est 5',
  },
  h1_title_ideas: {
    type: 'array',
    required: false,
  },
  h1_title: {
    type: 'string',
    max: 200,
    required: false,
    maxMessage: 'Le titre H1 doit faire un maximum de 200 caractères',
  },
  introduction_length: {
    type: 'string',
    max: 20,
    required: false,
    maxMessage:
      "La longueur de l'introduction doit faire un maximum de 20 caractères",
  },
  // nb_introduction: {
  //   type: 'number',
  //   min: 1,
  //   max: 5,
  //   required: true,
  //   requiredMessage: "Le nombre d'introductions est requis",
  //   minMessage: "Le nombre d'introductions minimum est 1",
  //   maxMessage: "Le nombre d'introductions maximum est 5",
  // },
  // introduction_ideas: {
  //   type: 'array',
  //   required: false,
  // },
  introduction: {
    type: 'string',
    max: 5000,
    required: false,
    maxMessage: "L'introduction doit faire un maximum de 5000 caractères",
  },
  introduction_rewrite: {
    type: 'string',
    max: 5000,
    required: false,
    maxMessage: "L'introduction doit faire un maximum de 5000 caractères",
  },
  conclusion_length: {
    type: 'string',
    max: 20,
    required: false,
    maxMessage:
      'La longueur de la conclusion doit faire un maximum de 20 caractères',
  },
  transition_article: {
    type: 'string',
    max: 2000,
    required: false,
    maxMessage:
      "La transition de l'article doit faire un maximum de 2000 caractères",
  },
  // nb_conclusion: {
  //   type: 'number',
  //   min: 1,
  //   max: 5,
  //   required: true,
  //   requiredMessage: 'Le nombre de conclusions est requis',
  //   minMessage: 'Le nombre de conclusions minimum est 1',
  //   maxMessage: 'Le nombre de conclusions maximum est 5',
  // },
  // conclusion_ideas: {
  //   type: 'array',
  //   required: false,
  // },
  conclusion: {
    type: 'string',
    max: 5000,
    required: false,
    maxMessage: 'La conclusion doit faire un maximum de 5000 caractères',
  },
  conclusion_rewrite: {
    type: 'string',
    max: 5000,
    required: false,
    maxMessage: "La conclusion doit faire un maximum de 5000 caractères",
  },
  nb_meta_title: {
    type: 'number',
    min: 1,
    max: 5,
    required: true,
    requiredMessage: 'Le nombre de titres méta est requis',
    minMessage: 'Le nombre de titres méta minimum est 1',
    maxMessage: 'Le nombre de titres méta maximum est 5',
  },
  meta_title_ideas: {
    type: 'array',
    required: false,
  },
  meta_title: {
    type: 'string',
    max: 200,
    required: false,
    maxMessage: 'Le titre méta doit faire un maximum de 200 caractères',
  },
  nb_meta_description: {
    type: 'number',
    min: 1,
    max: 5,
    required: true,
    requiredMessage: 'Le nombre de descriptions méta est requis',
    minMessage: 'Le nombre de descriptions méta minimum est 1',
    maxMessage: 'Le nombre de descriptions méta maximum est 5',
  },
  meta_description_ideas: {
    type: 'array',
    required: false,
  },
  meta_description: {
    type: 'string',
    max: 300,
    required: false,
    maxMessage: 'La description méta doit faire un maximum de 300 caractères',
  },
  // ADDITIONAL SEO FIELDS THAT WILL BE USED IN MILESTONE 2
};

export const Schema = generateYupSchema(globalValidationConfig);
