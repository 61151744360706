import { BubbleMenu } from '@tiptap/react';
import { useState, useEffect, useRef } from 'react';
import { GrReturn } from 'react-icons/gr';
import { IoMdSettings } from 'react-icons/io';
import { IoIosLink } from 'react-icons/io';
import { GrEdit } from 'react-icons/gr';
import { GoUnlink } from 'react-icons/go';
import InsertUrlModal from './insert-url-modal';

const InsertUrlBubbleMenu = ({ editor }) => {
  const hasLink = editor.getAttributes('link').href;
  const linkAttribute = editor.getAttributes('link');
  const [linkState, setLinkState] = useState(false);
  const [tempLink, setTempLink] = useState('');
  const [showLinkInput, setShowLinkInput] = useState(true);
  const [linkInserting, setLinkInserting] = useState(false);
  const linkRef = useRef(linkState);
  const [openModal, setOpenModal] = useState(false);

  const hasTextSelection = () => {
    const { from, to } = editor.state.selection;
    return to > from; // Selection range exists
  };

  const handleClick = () => {
    if (linkState) {
      setLinkState(false);
      return;
    }
    if ((!hasLink && hasTextSelection()) || hasLink) {
      setLinkState(true);
      editor.chain().focus().run();
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setLinkState(false);
    setShowLinkInput(false);
    editor.chain().focus().run();
    setOpenModal(true);
  };

  const handleInsertLink = (e) => {
    e.preventDefault();
    setShowLinkInput(false);
    setLinkInserting(true);
    setTimeout(() => {
      setLinkInserting(false);
    }, 0);
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({
        href: tempLink,
        target: '_blank',
        //rel: follow ? 'follow' : 'nofollow',
      })
      .run();
  };

  const handleRemoveLink = () => {
    setLinkState(false);
    setLinkInserting(true);
    setTimeout(() => {
      setLinkInserting(false);
    }, 0);
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
  };

  useEffect(() => {
    if (linkState && linkRef.current === false) {
      linkRef.current = true;
    } else {
      linkRef.current = false;
    }
  }, [linkState]);

  useEffect(() => {
    if (!linkInserting) {
      setLinkState(false);
      setShowLinkInput(!Boolean(hasLink));
      setTempLink(hasLink ? hasLink : '');
    }
  }, [editor.state.selection.head, linkInserting]);

  return (
    <div>
      <button
        className={`${editor.isActive('link') ? 'is-active' : ''} toolbar-btn`}
        onClick={handleClick}
      >
        <IoIosLink />
      </button>
      <div>
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            zIndex: 99,
            duration: 100,
            placement: 'bottom', // Choose 'top', 'bottom', 'left', or 'right'
          }}
          shouldShow={({ editor }) => {
            return editor.isActive('link') || linkRef.current;
          }}
        >
          {showLinkInput ? (
            <form
              onSubmit={handleInsertLink}
              className=" gap-2 relative items-center overflow-visible text-sm flex border-[2px] drop-shadow-md border-neutral-300 p-1 bg-white rounded-md"
            >
              {/* <div className="h-5 w-5 z-[-1] border-t-[2px] border-l-[2px] border-neutral-300 bg-white absolute left-1/2 transform -translate-x-1/2 top-[-12px] rotate-45" /> */}
              <input
                className="p-1 w-[250px] focus:outline-blue-500 border border-neutral-300"
                required
                value={tempLink}
                onChange={(e) => setTempLink(e.target.value)}
              />
              <div className="flex gap-1">
                <button
                  type="submit"
                  className="bg-blue-400 p-1 hover:bg-blue-500 rounded-md transition-all ease-in "
                >
                  <GrReturn size={20} color="white" />
                </button>
                <button
                  onClick={handleOpenModal}
                  className="p-1 hover:bg-neutral-200 rounded-md transition-all ease-in"
                >
                  <IoMdSettings size={20} color="grey" />
                </button>
              </div>
            </form>
          ) : (
            <div className=" gap-2 w-fit relative items-center overflow-visible text-sm flex border-[2px] drop-shadow-md border-neutral-300 p-1 bg-white rounded-md">
              {/* <div className="h-5 w-5 z-[-1] border-t-[2px] border-l-[2px] border-neutral-300 bg-white absolute left-1/2 transform -translate-x-1/2 top-[-12px] rotate-45" /> */}
              <a
                href={hasLink}
                className="p-1 overflow-hidden text-ellipsis max-w-[250px] underline text-blue-200"
                target="_blank"
              >
                {hasLink}
              </a>
              <div className="flex gap-1">
                <button
                  onClick={() => setShowLinkInput(true)}
                  className="p-1 hover:bg-neutral-200 rounded-md transition-all ease-in"
                >
                  <GrEdit size={20} color="grey" />
                </button>
                <button
                  onClick={handleRemoveLink}
                  className="p-1 hover:bg-neutral-200 rounded-md transition-all ease-in"
                >
                  <GoUnlink size={20} color="grey" />
                </button>
              </div>
            </div>
          )}
        </BubbleMenu>
      </div>

      {openModal ? (
        <InsertUrlModal
          editor={editor}
          linkAttribute={linkAttribute}
          tempLink={tempLink}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default InsertUrlBubbleMenu;
