import React from 'react';

const InfoButton = ({
  placement = 'top-center',
  text
}) => {
  // Define positioning classes based on the placement prop
  const positionClasses = {
    'top-center': 'bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2',
    'top-left': 'bottom-full left-0 -translate-y-2',
    'top-right': 'bottom-full right-0 -translate-y-2',
    'bottom-center': 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    'bottom-left': 'top-full left-0 mt-2',
    'bottom-right': 'top-full right-0 mt-2',
  };

  return (
    <div className="flex items-center">
      <div className="relative group">
        {/* Info Button Icon */}
        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-slate-500 text-white text-sm font-semibold cursor-pointer">
          i
        </div>

        {/* Tooltip Bubble */}
        <div
          className={`absolute ${positionClasses[placement]} hidden group-hover:flex flex-col items-center`}
        >
          {/* Render arrow based on placement */}
          {placement.startsWith('bottom') && (
            <div
              className="w-3 h-2 bg-slate-600/80"
              style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }}
            ></div>
          )}

          {/* Tooltip Text */}
          <span className="bg-slate-600/80 text-white text-xs rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
            {text}
          </span>

          {/* Render arrow at the bottom for top placements */}
          {placement.startsWith('top') && (
            <div
              className="w-3 h-2 bg-slate-600/80"
              style={{ clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)' }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoButton;
