import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { FieldErrorMessage } from "../../../others"

const GlobalNumberField = ({ formikProps, text, name, isRequired, minValue, maxValue, step=1, isReadOnly=false }) => {
    return (
        <Box pt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel
                    shrink
                    sx={{ paddingRight: "100%" }}
                >
                    {text}
                </InputLabel>

                <TextField
                    variant="outlined"
                    value={formikProps.values[name]}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    name={name}
                    type="number"
                    inputProps={{ 
                        min: minValue,
                        max: maxValue,
                        step: step,
                        readOnly: isReadOnly
                    }}
                    error={
                        formikProps.touched[name] &&
                        Boolean(formikProps.errors[name]) &&
                        isRequired
                    }
                    required={isRequired ? true : undefined}
                    sx={{
                        width: "100%",
                        borderRadius: "20px !important"
                    }}
                />

                <FieldErrorMessage name={name} />
                
            </Grid>
        </Box>
    )
}

export default GlobalNumberField;