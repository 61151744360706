import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import theme from '../utils/Theme';
import { ThemeProvider, Toolbar, Alert, Button, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const token = useSelector((state) => state?.auth).accessToken;

  const createPortalSession = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/create-portal-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
  
    const result = await response.json();
    window.location.href = result.url
    return result;
  };

  return (
    <ThemeProvider theme={theme}>
    {
      <div className='flex'>
        <Sidebar />
        <div className='relative h-screen flex flex-1 flex-col overflow-y-auto xl:pt-[68px] pt-[72px] pb-[26px]'>

          {userInfo?.payment_status === "pending" && (
            <Grid item md={12} xs={12} lg={12} sm={12}>
              <Alert
                severity="error"
                action={
                  <Button color="inherit" size="small" onClick={createPortalSession}>
                    Régulariser mon paiement
                  </Button>
                }
                sx={{
                  mb: 2
                }}
              >
                <b>Votre dernier paiement a échoué !</b> Vous pouvez toujours <u>accéder à vos contenus créés pendant 7 jours, mais vous ne pouvez pas les modifier</u>.
                Après ce délais, vous devrez souscrire à un nouvel abonnement pour continuer à utiliser BotBlogR.
              </Alert>
            </Grid>
          )}

          {(userInfo?.openai_api_key === null && (userInfo?.payment_status === "active" || userInfo?.payment_status === "trial")) && (
            <Grid item md={12} xs={12} lg={12} sm={12}>
              <Alert
                severity="error"
                action={
                  <Button color="inherit" size="small" onClick={()=>navigate('/profile?tab=integrate-openai')}>
                    Ajouter ma clé
                  </Button>
                }
                sx={{
                  mb: 2
                }}
              >
                Vous devez intégrer votre clé OpenAI avant de pouvoir utiliser l'application.
              </Alert>
            </Grid>
          )}

          <div className='pt-[10px]'>
            <Outlet />
          </div>
          
        </div>
      </div> 
    }
    </ThemeProvider>
  );
};

export default Layout;
