import { mergeAttributes, Node } from '@tiptap/core';
import './styles.scss';
import { v4 as uuidv4 } from 'uuid';

const H2Zone = Node.create({
  name: 'h2headingzone',
  group: 'block',
  content: 'heading block+',
  draggable: false,

  addAttributes() {
    return {
      id: {
        default: null, // Generate a unique ID only once when the node is created
        parseHTML: (element) => element.getAttribute('id'), // Parse the id from HTML
        renderHTML: (attributes) => {
          return {
            id: attributes.id || uuidv4(), // Keep the existing id or generate a new one
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'h2headingzone',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'h2headingzone',
      mergeAttributes(HTMLAttributes, {
        'data-type': 'h2headingzone',
        class: 'h2headingzone',
      }),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const pos = editor.state.selection.head;
        const node = editor.$pos(pos);
        const prevZone = editor.$pos(pos - 2).name;
        const currentZone = editor.$pos(pos - 1).name;
        const nextZone = editor.$pos(pos + 2).name;
        const multipleSelection = editor.state.selection.to > editor.state.selection.from;

        if (prevZone === 'doc') {
          if (multipleSelection) {
            return false;
          }
          return true;
        }

        if (
          prevZone.includes('headingzone') &&
          nextZone.includes('headingzone')
        ) {
          editor
            .chain()
            .deleteRange({ from: pos - 1, to: pos + 1 })
            .run();
          editor
            .chain()
            .setTextSelection(pos - 3)
            .run();
          return true;
        }

        if (node.name === 'heading' && currentZone.includes('headingzone')) {
          if (multipleSelection) {
            return false;
          }
          return true;
        }

        if (isHeadingZone(node)) {
          if (
            currentZone.includes('headingzone') &&
            (prevZone === 'heading' || prevZone === 'contentzone')
          ) {
            if (multipleSelection) {
              return false;
            }
            return true;
          }
        }

        return false;
      },

      Delete: ({ editor }) => {
        const pos = editor.state.selection.head;
        const node = editor.$pos(pos);
        const prevZone = editor.$pos(pos - 1).name;
        const currentZone = editor.$pos(pos + 1).name;
        const nextZone = editor.$pos(pos + 2).name;

        if (prevZone === node.name && nextZone.includes('headingzone')) {
          editor
            .chain()
            .setTextSelection(pos + 3)
            .run();
          return true;
        }

        if (isHeadingZone(node)) {
          if (node.name === 'heading' && currentZone !== 'heading') {
            return true;
          }
          if (
            currentZone.includes('headingzone') &&
            nextZone === 'contentzone'
          ) {
            return true;
          }
          return false;
        }
        if (nextZone === 'doc') {
          return true;
        }
        return false;
      },

      Enter: ({ editor }) => {
        const pos = editor.state.selection.head;
        const node = editor.$pos(pos);
        const prevNode = editor.$pos(pos - 1).name;
        const nextNode = editor.$pos(pos + 1).name;

        if (isHeadingZone(node)) {
          if (
            (prevNode.includes('headingzone') || prevNode === 'heading') &&
            nextNode === 'heading'
          ) {
            return true;
          }
          if (node.parent.name !== 'listItem') {
            editor
              .chain()
              .insertContentAt(pos + 1, '<p></p>', {
                updateSelection: true,
              })
              .run();
            return true;
          }
          return false;
        }
        return false;
      },

      Escape: ({ editor }) => {
        const pos = editor.state.selection.head;
        const node = editor.$pos(pos);
        const isHZone = isHeadingZone(node);

        if (isHZone) {
          const endOfZone = isHZone.to;
          const y = editor.$pos(endOfZone + 1).name;
          if (y === 'doc' || y === 'contentzone' || y.includes('headingzone'))
            editor.chain().insertContentAt(endOfZone, '<p></p>').run();
          else editor.chain().setTextSelection(endOfZone).run();
        }
        return true;
      },

      'Shift-Escape': ({ editor }) => {
        const pos = editor.state.selection.head;
        const node = editor.$pos(pos);
        const isHZone = isHeadingZone(node);

        if (isHZone) {
          const soz = isHZone.from;
          const y = editor.$pos(soz - 1).name;
          if (y === 'doc' || y === 'contentzone' || y.includes('headingzone'))
            editor
              .chain()
              .insertContentAt(soz - 1, '<p></p>')
              .run();
          else editor.chain().setTextSelection(soz).run();
        }
        return true;
      },

      'Shift-Backspace': ({ editor }) => {
        const pos = editor.state.selection.head;
        const isHZone = isHeadingZone(editor.$pos(pos));
        if (isHZone) {
          const soz = isHZone.from;
          editor.chain().deleteNode(isHZone.name).focus().run();
          editor
            .chain()
            .setTextSelection(soz - 1)
            .run();
        }
        return true;
      },

      'Ctrl-Backspace': ({ editor }) => {
        return true;
      },
    };
  },
});

export const isHeadingZone = (node) => {
  if (!node || node?.name === 'doc') return false;
  if (node?.name?.includes('headingzone')) {
    return node;
  }
  return isHeadingZone(node.parent);
};

// const getStartOfZone = (node) => {
//   if (!node || node?.name === 'doc') return null;
//   if (node?.name?.includes('headingzone')) {
//     return node?.from;
//   }
//   return getStartOfZone(node.parent);
// };

// const getEndOfZone = (node) => {
//   if (!node || node?.name === 'doc') return null;
//   if (node?.name?.includes('headingzone')) {
//     return node?.to;
//   }
//   return getEndOfZone(node.parent);
// };

export default H2Zone;
