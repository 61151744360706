import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { ClipLoader } from 'react-spinners';

const FeaturesTemplate = ({ 
  title, 
  description,
  formikProps,
  onSave, 
  onDelete, 
  loadingSave, 
  loadingDelete, 
  deleteButton, 
  children,
  isReadOnly=false
}) => {
  return (
    <Box id="content">

      {/* --- PAGE HEADER --- */}
      <Box id="features-header">
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontSize={{ lg: 25, md: 25, sm: 25, xs: 20 }} fontWeight={600}>
            {title}
          </Typography>
          <Box display={"flex"}>
            <Button 
              variant="contained" 
              color="success"
              onClick={() => onSave(formikProps, formikProps.values)}
              disabled={loadingSave || isReadOnly}
            >
              {loadingSave ? <ClipLoader size={25} color="white" /> : "Save"}
            </Button>
            {deleteButton && (
              <Button
                variant="contained"
                color="error"
                onClick={() => onDelete(formikProps?.values?.id)}
                disabled={loadingDelete}
              >
                {loadingDelete ? <ClipLoader size={25} color="white" /> : "Delete"}
              </Button>
            )}
          </Box>
        </Box>
        <Typography variant="subtitle">{description}</Typography>
        <Box style={{ borderStyle: "outset" }}></Box>
      </Box>

      {/* --- GRID PAGE CONTENT --- */}
      <Grid id="content" container spacing={2} mt={1}>

        {children}

      </Grid>

    </Box>
  );
};

export default FeaturesTemplate;