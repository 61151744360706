import React from 'react';
import { useTranslation } from "react-i18next";
import { useRestartSubscriptionMutation } from '../../../redux/slices/paymentApiSlice';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const RestartPlan = ({
    closeModal,
    returnToOptions,
    currentSubscription,
    refetchCurrentSub
}) => {
    const { t } = useTranslation();

    const [restartSubscription, { isLoading: restartSubscriptionLoading }] = useRestartSubscriptionMutation();

    const handleRestartSubscription = async () => {
        const response= await restartSubscription();
        if (response?.data?.status===200) {
          toast.success(response?.data?.message)
          refetchCurrentSub();
          closeModal();
        } else {
          toast.error(response?.error?.data?.detail)
        };
    };

    return (
        <div className="flex justify-center items-center bg-gray-50">
            <div className="bg-white rounded-lg pt-6 w-full">
    
            <div className="mb-4 space-y-3">
                <h3 className="text-lg font-semibold">{currentSubscription.subscription.name}</h3>
                <p className="text-2xl font-bold">{currentSubscription.subscription.price.toFixed(2)} € <span className="text-base font-normal">{currentSubscription.subscription.period === "annual" ? t("per-year", "par an") : t("per-month", "par mois")}</span></p>
                <p className="text-gray-600">{t("Your-plan-will-be-restarted-and-you-will-not-be-billed-until", "Votre abonnement sera récupéré, et vous ne serez pas débité avant la fin de votre période de facturation le")} {new Date(currentSubscription.subscription.nex_payment_date*1000).toLocaleDateString('fr-FR')}.</p>
            </div>

            <div className="flex max-md:justify-center space-x-2">
                <button 
                    onClick={()=>handleRestartSubscription()}
                    className="bg-blue-100 hover:bg-blue-100/70 text-white px-4 py-2 rounded"
                >
                    {restartSubscriptionLoading ? <ClipLoader color="white"/> : t("restart-plan", "Reprendre l'abonnement")}
                </button>
                
                <button onClick={returnToOptions} className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white px-4 py-2 rounded">
                    {t("Return", "Retour")}
                </button>
            </div>
            </div>
        </div>
    );
}

export default RestartPlan;