import * as Yup from 'yup';

const generateYupSchema = (config) => {
  const shape = {};

  Object.keys(config).forEach((key) => {
    const rules = config[key];
    let schema;

    switch (rules.type) {
      case 'string':
        schema = Yup.string();
        if (rules.min !== undefined) {
          schema = schema.min(rules.min, rules.minMessage);
        }
        if (rules.max !== undefined) {
          schema = schema.max(rules.max, rules.maxMessage);
        }
        if (rules.required) {
          schema = schema.required(rules.requiredMessage);
        }
        break;

      case 'number':
        schema = Yup.number();
        if (rules.min !== undefined) {
          schema = schema.min(rules.min, rules.minMessage);
        }
        if (rules.max !== undefined) {
          schema = schema.max(rules.max, rules.maxMessage);
        }
        if (rules.required) {
          schema = schema.required(rules.requiredMessage);
        }
        break;

      case 'boolean':
        schema = Yup.boolean();
        if (rules.required) {
          schema = schema.required(rules.requiredMessage);
        }
        break;

      default:
      //throw new Error(`Unsupported type: ${rules.type}`);
    }

    shape[key] = schema;
  });

  return Yup.object().shape(shape);
};

export default generateYupSchema;
