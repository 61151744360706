import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { tableCellFillNa } from './TableCellFillNa';

const useDataFetch = (
    output_key,
    useGenerateContentMutation,
    useCreateContentMutation,
    useGetSingleContentMutation,
    useUpdateContentMutation,
    useDeleteContentMutation,
    useGetAllContentsQuery,
    isTable,
    isNbOutputs,
    streamGeneration=false
) => {
    const [tableOutput, setTableOutput] = useState([]);
    const [mainOutput, setMainOutput] = useState("");
    const [outputs, setOutputs] = useState([]);
    const [rewritenOutput, setRewritenOutput] = useState("");

    const [singleContent, setSingleContent] = useState(null);
    const [onEdit, setOnEdit] = useState(false);
    const [deleteButton, setDeleteButton] = useState(false);

    const [generateContent, { isLoading: generateLoading }] = useGenerateContentMutation();
    const [createContent, { isLoading: createLoading }] = useCreateContentMutation();
    const [getSingleContent, { isLoading: getSingleLoading }] = useGetSingleContentMutation();
    const [updateContent, { isLoading: updateLoading }] = useUpdateContentMutation();
    const [deleteContent, { isLoading: deleteLoading }] = useDeleteContentMutation();

    const [rewriteOpen, setRewriteOpen] = useState(false);
    const handleRewriteClose = () => setRewriteOpen(false);
    const handleRewriteOpen = () => {setRewriteOpen(true);};

    const { data: contents, isLoading: contentsLoading, refetch } = useGetAllContentsQuery();
    const userInfo = useSelector((state) => state?.auth).userInfo;


    const handleGetSingle = async (id) => {
        try {
            const res = await getSingleContent({id}).unwrap();
            const sanitizedData = Object.fromEntries(
                Object.entries(res?.data || {}).map(([key, value]) => [
                    key,
                    value === null ? "" : value
                ])
            );
            setOnEdit(true);
            setSingleContent(sanitizedData);
            if (isTable) {
                setTableOutput(sanitizedData[output_key])
            } else {
           
                setMainOutput(sanitizedData[output_key])
             
                
                if (isNbOutputs) {
                    setOutputs(sanitizedData.outputs);
                } else {
                    setRewritenOutput(sanitizedData.rewrite)
                }
            }
        } catch (error) {}
    };

    const handleSave = async (formikProps, values) => {
        if((formikProps.dirty && formikProps.isValid) || onEdit) {
            if (onEdit) {
                try {
                    const payload = Object.fromEntries(
                        Object.entries(values).map(([key, value]) => [key, value === null || value === "" ? null : value])
                    );
                    if (isTable) {
                        payload[output_key] = tableOutput;
                    } else {
                        payload[output_key] = mainOutput;
                        if (isNbOutputs) {
                            payload["outputs"] = outputs;
                        } else {
                            payload["rewrite"] = rewritenOutput;
                        }
                    }
                    const res = await updateContent(payload).unwrap();
                    if (res?.status === 200) {
                        refetch();
                        toast.success("Update successfully");
                    }
                } catch (err) {
                    toast.error('Error saving data');
                }
            } else {
                try {
                    const payload = Object.fromEntries(
                        Object.entries(values).filter(([key]) => key !== "id").map(([key, value]) => [key, value === null || value === "" ? null : value])
                    );
                    if (isTable) {
                        payload[output_key] = tableOutput;
                    } else {
                        payload[output_key] = mainOutput;
                        if (isNbOutputs) {
                            payload["outputs"] = outputs;
                        } else {
                            payload["rewrite"] = rewritenOutput;
                        }
                    }
                    const res = await createContent(payload).unwrap();
                    if (res?.status === 201) {
                        refetch();
                        handleGetSingle(res.data.id);
                        setDeleteButton(true);
                        toast.success("Data Saved Successfully");
                    }
                } catch (err) {
                    toast.error('Error saving data');
                }
            }
        } else {
            toast.warning("Please complete your Form Fields")
        }
    };

    const handleCreateNew = (formikProps) => {
        setSingleContent(null);
        setTableOutput([]);
        setMainOutput("");
        setOutputs([]);
        setRewritenOutput("");
        setOnEdit(false);
        setDeleteButton(false);
    
        return formikProps.resetForm();
    };

    const handleDelete = async (id) => {
        try {
            const res = await deleteContent({id}).unwrap()
            setSingleContent(null);
            setOnEdit(false);
            setTableOutput({});
            setMainOutput("");
            setOutputs([]);
            setRewritenOutput("");
            refetch();
            toast.success("Deleted Successfully");
            setDeleteButton(false)
          } catch (err) {
            toast.error("Error deleting data")
          }
    };

    const handleGenerate = async (values) => {
        try {
         
            if (userInfo?.openai_api_key === null) {
                toast.error("Vous devez ajouter une clé OpenAI avant de générer un contenu !");
            } else {
                const payload = Object.fromEntries(
                    Object.entries(values).filter(([key]) => key !== "id").map(([key, value]) => [key, value === null || value === "" ? null : value])
                );
           
                if (isTable) {
                    payload[output_key] = tableOutput;
                } else {
                    if (isNbOutputs) {
                        payload["outputs"] = outputs;
                    } else {
                        payload[output_key] = mainOutput;
                    }
                }
                
                const res = await generateContent({...payload, ...(streamGeneration && { stream: true })}).unwrap();

                if (res.status === 200) {
                    if (isTable) {
                        setTableOutput(tableCellFillNa(res.data));
                    } else {
                        if (isNbOutputs) {
                            setOutputs((prevOutputs) => [...res.data, ...prevOutputs]);
                        } else {
                            if (mainOutput) {
                                handleRewriteOpen();
                                setRewritenOutput(res.data);
                            } else {
                                setMainOutput(res.data);
                            }
                        }
                    }
                    toast.success("Data Generated Successfully");
                }
            }
        } catch (error) {
            toast.error("Error occur while generating data");
        }
    };

    const tableOutputHandler = (newRows) => {
        setTableOutput((prevTableOutput) => [...prevTableOutput, ...newRows]);
    };

    const tableOutputRemove = () => {
        setTableOutput([]);
    }

    const handleRowDelete = (index) => {
        setTableOutput((prevTableOutput) => prevTableOutput.filter((_, i) => i !== index));
    };
    
    const handleCellDelete = (index, field) => {
        const newTableOutput = tableOutput.map(row => ({ ...row }));
        newTableOutput[index][field] = null;

        setTableOutput(tableCellFillNa(newTableOutput));
    };

    const mainOutputHandler = (data) => {
        setMainOutput(data);
    };

    const rewritenOutputHandler = (data) => {
 
        setRewritenOutput(data);
    };

    const outputsHandler = (index, newContent) => {
       
        setOutputs((outputs) =>
          outputs.map((output, i) => (i === index ? newContent : output))
        );
    };

    const removeOutputHandler = (index) => {
        setOutputs((outputs) => outputs.filter((_, i) => i !== index));
    };

    return {
        tableOutput,
        tableOutputHandler,
        tableOutputRemove,
        handleRowDelete,
        handleCellDelete,
        mainOutput,
        mainOutputHandler,
        outputs,
        outputsHandler,
        removeOutputHandler,
        rewritenOutput,
        rewritenOutputHandler,
        handleCreateNew,
        singleContent,
        deleteButton, 
        setDeleteButton,
        handleGetSingle,
        handleGenerate,
        generateLoading,
        handleSave,
        createLoading,
        updateLoading,
        handleDelete,
        deleteLoading,
        contents, 
        contentsLoading,
        rewriteOpen,
        handleRewriteClose,
        handleRewriteOpen
    };
};

export default useDataFetch;