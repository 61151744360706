import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const ReferralPanel = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyReferralLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/signup?referral=${userInfo.unique_id}`)
      .then(() => {
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className='rounded-lg shadow-default bg-white px-4 py-4 mt-3'>
      
      {userInfo.plan === "Demo" ?
        <div>
          <p className='text-black text-xl font-bold'>
            {t("your-friends-could-like-this-demo-friendly", "Ton réseau pourrait aimer cette démo !")}
          </p>
          <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3'>
            {t("share-demo-link-to-gain-advantages-on-launch-friendly", "Tu aimes la démo et connais des personnes qui seraient intéressées à l'idée de la tester ? Invite-les via ton propre lien pour que toi et tes invités profitent d'avantages supplémentaires au lancement officiel !")}
          </p>
        </div>
      :
        <div>
          <p className='text-black text-xl font-bold'>
            {t("gain-3-articles-per-month-friendly", "Gagne 3 articles/mois + offre 3 articles/mois GRATUITEMENT !")}
          </p>
          <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3'>
            {t("share-link-to-gain-3-articles-per-month-friendly", "Tu connais des personnes qui seraient intéressées par BotBlogR ? S'ils s'inscrivent directement avec ton propre lien, toi et le nouvel inscrit gagnez 3 articles supplémentaires par mois pendant 3 mois !")}
          </p>
          <p>
            <a className='font-semibold mt-3 block' href="/affiliate-program">
              {t("learn-more-affiliate-program", "En savoir plus sur notre programme d'affiliation")}
            </a>
          </p>
        </div>
      }

      <div className='relative left-1/2 -translate-x-1/2'>
        <Tooltip
          title={t("link-copied", "Lien copié !")}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement="top"
        >
        <button onClick={copyReferralLink} className='bg-primary hover:bg-blue-200 font-light px-5 py-[6px] mt-3 text-white rounded'>
        <FileCopyIcon /> {t("copy-my-link", "Copier mon lien !")}
        </button>
        </Tooltip>
      </div>
      
    </div>
  )
}

export default ReferralPanel