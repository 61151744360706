import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ClipLoader } from 'react-spinners';
import { useChangeSubscriptionMutation } from '../../../redux/slices/paymentApiSlice';
import { setCredentials } from "../../../redux/slices/authSlice";
import { fetchUserInfo } from '../../../redux/me';
import { fetchSubscriptionDetails } from '../../../redux/change_plan_details';
import { CustomLoader } from '../others';
import stripeConfig from '../../utils/stripeConfig';

const SelectPlan = ({
    newPlan,
    currentSubscription,
    refetchCurrentSub,
    returnToOptions,
}) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state?.auth);
    const [isLoading,setLoading] = useState(true)
    const STANDARD_PRICE_ID = stripeConfig.priceIds.monthly.standard;
    const ANNUAL_STANDARD_PRICE_ID = stripeConfig.priceIds.yearly.standard;
    const PREMIUM_PRICE_ID = stripeConfig.priceIds.monthly.premium;
    const ANNUAL_PREMIUM_PRICE_ID = stripeConfig.priceIds.yearly.premium;
    const ULTIMATE_PRICE_ID = stripeConfig.priceIds.monthly.ultimate;
    const ANNUAL_ULTIMATE_PRICE_ID = stripeConfig.priceIds.yearly.ultimate;
    const [changeSubscription, { isLoading: changeSubscriptionLoading }] = useChangeSubscriptionMutation();
    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState(null);
    const [downgradeMessage, setDowngradeMessage] = useState(null);

    const subscriptions = {
        [STANDARD_PRICE_ID]: {
            "name": "Standard",
            "period": "monthly",
            "price": "34,90",
            "cost-today": "1,07"
        },
        [ANNUAL_STANDARD_PRICE_ID]: {
            "name": "Standard",
            "period": "annual",
            "price": "349.00",
            "cost-today": "1,07"
        },
        [PREMIUM_PRICE_ID]: {
            "name": "Premium",
            "period": "monthly",
            "price": "49,90",
            "cost-today": "1,07"
        },
        [ANNUAL_PREMIUM_PRICE_ID]: {
            "name": "Premium",
            "period": "annual",
            "price": "499.00",
            "cost-today": "1,07"
        },
        [ULTIMATE_PRICE_ID]: {
            "name": "Ultimate",
            "period": "monthly",
            "price": "99,90",
            "cost-today": "1,07"
        },
        [ANNUAL_ULTIMATE_PRICE_ID]: {
            "name": "Ultimate",
            "period": "monthly",
            "price": "999.00",
            "cost-today": "1,07"
        },
    };
   
  useEffect( ()=>{
    handleFetchDetails()
  },[])
    const handleFetchDetails = async () => {
        
        if (!showDetails && currentSubscription) {
            try {
                const data = await fetchSubscriptionDetails(newPlan, accessToken);

                if (data.message) {
                    setDowngradeMessage(data.message);
                    setDetails(null);
                    setLoading(false);
                } else {
                    setDetails({
                        ...data,
                        since_date: data.since_date ? new Date(data.since_date).toLocaleDateString('fr-FR') : 'N/A',
                        remaining_amount: data.remaining_amount || data.remaining_amount === 0 ? data.remaining_amount : 'N/A',
                    });
                    setDowngradeMessage(null);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        }else{
            setLoading(false);
        }
        
    };

    const handleSubscriptionChange = async () => {
        try {
            const response = await changeSubscription({ new_plan_id: newPlan });
            if (response?.data?.status === 200) {
                toast.success(response?.data?.message);

                const userInfoResult = await fetchUserInfo(accessToken);

                if (userInfoResult.success) {
                    refetchCurrentSub();
                    returnToOptions();
                    dispatch(setCredentials({
                        userInfo: userInfoResult.data,
                        accessToken: accessToken,
                    }));
                } else {
                    toast.error(userInfoResult.error);
                }
            } else {
                toast.error(response?.error?.data?.detail || "Subscription update failed.");
            }
        } catch (error) {
            toast.error("An error occurred while changing the subscription.");
        }
    };

    return (
        <div className="flex justify-center items-center bg-gray-50">
            <div className="bg-white rounded-lg pt-6 w-full">
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">{subscriptions[newPlan]["name"]}</h3>
                    <p className="text-sm text-gray-600">{t("What-you-will-pay-every", "Ce que vous paierez tous les")}{" "}
                        {subscriptions[newPlan]["period"] === "monthly" ? t("months", "mois") : t("years", "ans")}{" "}
                        {t("starting-from", "à compter du")} {subscriptions[newPlan]["period"] === "monthly" ?
                       new Date( new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString('fr-FR'):
                       new Date( new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString('fr-FR')}.</p>
                    <p className="text-sm text-gray-600"></p>
                    <p className="text-2xl font-bold mt-2">{subscriptions[newPlan]["price"]} €</p>
                </div>

                <div className="mb-4 rounded-2xl bg-gray_local-400/10 p-5">

                    {isLoading ? (
                        <div className="flex justify-center items-center">
                            <CustomLoader />
                        </div>
                    ) : (
                        <div>
                            <div className="flex justify-between items-center">
                                <p className="text-gray-600 font-semibold text-base">{t("Amount-due-today", "Montant dû aujourd'hui")}</p>

                    
                        <p className="text-xl font-bold">    {details?.remaining_amount !== 'N/A' && details?.remaining_amount
                                                ? `${(parseFloat(subscriptions[newPlan]["price"]) - parseFloat(details?.remaining_amount)).toFixed(2)} €`
                                                : `${subscriptions[newPlan]["price"]}  €`}</p>
                    </div>
                    {
                        currentSubscription && ( <div className="flex justify-between items-center">

                        <button
                            onClick={()=>{setShowDetails(!showDetails)}}
                            className="text-gray-700 font-normal text-sm  mt-2"
                        >
                            {showDetails ? t("Hide-details", "Cacher les détails") : t("Show-details", "Afficher les détails")}
                        </button>
                
                            <p className="text-sm text-gray-500">{currentSubscription?.subscription.payment_method?.type } {currentSubscription?.subscription.payment_method?.card_number }</p>
                    
                    </div>
                        )
                    }
                    {showDetails &&   (
                        <div className='h-[1px] bg-gray/30 w-full my-3'></div>
                    )}
                    {showDetails && details && !downgradeMessage && (

                                <>
                                    <div className="flex justify-between items-center mb-3">
                                        <p className='text-gray-700 font-normal text-sm  mt-2'>
                                            {t("Subscription", "Abonnement")} - {subscriptions[newPlan]["name"]}  ({t(subscriptions[newPlan]["period"])})
                                        </p>
                                        <p>

                                            {subscriptions[newPlan]["price"]} €
                                        </p>

                            </div>
                            <div className="flex justify-between items-center">
                                <div className='text-gray-700 font-normal text-sm mt-2 my-4'>
                                    {t("Subscription", "Abonnement")} - {details.current_sub_name} ({t(details.current_sub_period)})
                                    <br />
                                    {t("unused-time-and-tokens-since", "Temps et tokens inutilisés depuis")}{" "}{currentSubscription.subscription.period==='monthly'?new Date(new Date(currentSubscription.subscription.prev_payment_date*1000)).toLocaleDateString('fr-FR'):
                                    new Date(new Date(currentSubscription.subscription.prev_payment_date*1000)).toLocaleDateString('fr-FR')}
                                </div>
                                <p>
                                    - {details.remaining_amount !== 'N/A' ? details.remaining_amount.toFixed(2) : 'N/A'} €
                                </p>
                            </div>

                                    <div className="flex justify-between items-center">
                                        {details.since_date !== 'N/A' && (
                                            <>
                                                <p className="text-gray-700 font-normal text-sm mt-2">
                                                    Total
                                                </p>
                                                <p>
                                                    {details.remaining_amount !== 'N/A'
                                                        ? `${(parseFloat(subscriptions[newPlan]["price"]) - parseFloat(details.remaining_amount)).toFixed(2)} €`
                                                        : 'N/A'}
                                                </p>
                                            </>
                                        )}
                                    </div>

                                </>
                            )}

                            {showDetails && downgradeMessage && (
                                <div className="mt-3 text-sm text-red-600">
                                    <p>{t("You-will-be-pay-the-downgrade-plan-charged-at", "Vous paierez pour le plan de rétrogradation à")} {new Date(downgradeMessage).toLocaleDateString('fr-FR')}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex max-md:justify-center space-x-2">
                    <button onClick={handleSubscriptionChange} className="bg-blue-50 hover:bg-blue-100 text-white px-4 py-2 rounded">
                        {changeSubscriptionLoading ? <ClipLoader color="white" /> : t("Subscribe-and-pay", "S'abonner et payer")}
                    </button>

                    <button onClick={returnToOptions} className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white px-4 py-2 rounded">
                        {t("Return", "Retour")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectPlan;
