import React from 'react';
import { useTranslation } from "react-i18next";
import { useCancelSubscriptionMutation } from '../../../redux/slices/paymentApiSlice';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const CancelPlan = ({
    closeModal,
    returnToOptions,
    currentSubscription,
    refetchCurrentSub
}) => {
    const { t } = useTranslation();

    const [cancelSubscription, { isLoading: cancelSubscriptionLoading }] = useCancelSubscriptionMutation();

    const handleCancelSubscription = async () => {
        const response= await cancelSubscription();
        if (response?.data?.status===200) {
          toast.success(response?.data?.message)
          refetchCurrentSub();
          closeModal();
        } else {
          toast.error(response?.error?.data?.detail)
        };
    };

    return (
        <div className="flex justify-center items-center bg-gray-50">
            <div className="bg-white rounded-lg pt-6 w-full">
    
            <div className="mb-4 space-y-3">
                <h3 className="text-lg font-semibold">{currentSubscription.subscription.name}</h3>
                <p className="text-2xl font-bold">{currentSubscription.subscription.price.toFixed(2)} € <span className="text-base font-normal">{currentSubscription.subscription.period === "annual" ? t("per-year", "par an") : t("per-month", "par mois")}</span></p>
                <p className="text-gray-600">{t("Your-plan-will-be-canceled-but-it-will-remain-available-until", "Votre plan sera annulé, mais il restera disponible jusqu'à la fin de votre période de facturation le")} {new Date(currentSubscription.subscription.nex_payment_date*1000).toLocaleDateString('fr-FR')}.</p>
                <p className="text-gray-600">{t("If-you-change-your-mind-you-can-renew-your-subscription", "Si vous changez d'avis, vous pouvez renouveler votre abonnement.")}</p>
            </div>

            <div className="flex max-md:justify-center space-x-2">
                <button 
                    onClick={()=>handleCancelSubscription()}
                    className="bg-error hover:bg-error/70 text-white px-4 py-2 rounded"
                >
                    {cancelSubscriptionLoading ? <ClipLoader color="white"/> : t("Cancel-the-plan", "Annuler le plan")}
                </button>
                
                <button onClick={returnToOptions} className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white px-4 py-2 rounded">
                    {t("Return", "Retour")}
                </button>
            </div>
            </div>
        </div>
    );
}

export default CancelPlan;