import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, Stack, IconButton, Tooltip } from '@mui/material';
import { useFormik, useFormikContext } from 'formik';
import GlobalTextField from '../../FeaturesTemplate/FeaturesFields/GlobalTextField';
import GlobalNumberField from '../../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { Add, Check, Close } from '@mui/icons-material';
import { setActivePanel } from '../../../../../../redux/slices/panelSlice';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../../../redux/store';
import '../../Toolbar/Toolbar.css';
import { globalValidationConfig } from '../formik-function/helpers';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useGeneratePostURLSlugMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { BsMagic } from 'react-icons/bs';
import { LaunchAnalysisBtn } from '../../Others';
import { useState } from 'react';

const UrlSlug = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const formikProps = useFormikContext();
  const [generatePostURLSlug, { isLoading }] = useGeneratePostURLSlugMutation();
  const [copied, setCopied] = useState(null);

  const path =
    formikProps.values.url_path === ''
      ? 'https://botblogr/seo/'
      : formikProps.values.url_path;
  const urlSlug =
    formikProps.values.url_slug === ''
      ? t('slug-placeholder', 'ecrire-article-seo-avec-ia')
      : formikProps.values.url_slug;
  const urlSlugValue = `${path}${urlSlug}`;

  const handleCopy = (index, content) => {
    setCopied(index);
    navigator.clipboard.writeText(content);
    setTimeout(() => {
      setCopied(null);
    }, 1000);
  };

  const handleClose = () => {
    dispatch(setActivePanel(null));
  };

  const handleGenerateUrlSlug = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        url_path: formikProps.values.url_path,
        nb_outputs: formikProps.values.nb_url_slug,
        outputs: formikProps.values.url_slug_ideas,
      };
      if (formikProps.values.url_slug !== '') {
        payload['url_slug'] = formikProps.values.url_slug;
      }

      console.log('url slug payload: ', payload);

      const res = await generatePostURLSlug(payload).unwrap();

      if (res?.status !== 200) {
        toast.error('Error while generating url slug');
        return;
      }

      console.log('url slug res: ', res);

      const updatedUrlSlugIdeas = [
        ...formikProps.values.url_slug_ideas,
        ...res?.data,
      ];

      formikProps.setFieldValue('url_slug_ideas', updatedUrlSlugIdeas);
    } catch (error) {
      if (
        error.error ===
        'The model `gpt-4o` does not exist or you do not have access to it.'
      ) {
        toast.error(
          t(
            'problem-with-openai',
            'Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?'
          )
        );
      } else if (
        error?.data?.detail ===
        'Access forbidden: you must have registered a functional OpenAI API Key to generate contents'
      ) {
        toast.error(
          t(
            'missing-api-key',
            'Vous devez avoir une clé API active pour générer du contenu'
          )
        );
      } else {
        toast.error(
          t(
            'something-went-wrong-with-generation',
            "Une erreur s'est produite lors de la génération"
          )
        );
      }
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        overflow: { md: 'unset', xs: 'scroll' },
        height: { md: '100vh', xs: '100%' },
        width: '100%',
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 9999,
      }}
    >
      {/* MODAL */}
      <Box
        sx={{
          width: { md: 'calc(100vw - 350px)', xs: '100%' },
          display: 'flex',
          justifyContent: 'center',
          height: 'auto',
          //overflow: { md: 'auto', xs: 'unset' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            my: { md: 'unset', xs: '40px' },
          }}
        >
          <div className="urlslug-wrapper">
            <input
              readOnly
              className="urlslug-input"
              type="text"
              value={urlSlugValue}
            />
          </div>
          <div className="h-screen flex">
            <Box
              sx={{
                maxHeight: '550px',
                height: '70%',
                mx: 'auto',
                mt: 4,
                bgcolor: 'white',
                maxWidth: '900px',
                width: '95%',
                borderRadius: 4,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  position: 'relative',
                  borderBottom: '2px solid #e5e7eb',
                }}
              >
                <p className="text-2xl font-semibold">Slug d'URL</p>
                <Box
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: 0,
                    top: 0,
                    p: 2,
                  }}
                >
                  <Close sx={{ color: 'black', fontSize: 32 }} />
                </Box>
              </Box>
              <Box
                sx={{
                  bgcolor: 'white',
                  px: 3,
                  py: 2,
                  flex: 1,
                  overflow: 'auto',
                }}
              >
                <form>
                  <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                      <p>Path Url</p>
                      <GlobalTextField
                        formikProps={formikProps}
                        name="url_path"
                        placeholder="Ex: https://botblogr/seo/"
                        isRequired={globalValidationConfig.url_path.required}
                        maxLength={globalValidationConfig.url_path.max}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <p>Slug d'URL</p>
                      <GlobalTextField
                        formikProps={formikProps}
                        name="url_slug"
                        placeholder={
                          'Ex: ' +
                          t('slug-placeholder', 'ecrire-article-seo-avec-ia')
                        }
                        isRequired={globalValidationConfig.url_slug.required}
                        maxLength={globalValidationConfig.url_slug.max}
                      />
                      <div className="flex">
                        <LoadingButton
                          startIcon={<BsMagic />}
                          loading={isLoading}
                          onClick={handleGenerateUrlSlug}
                          variant="contained"
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            mt: 2,
                            bgcolor: '#8b5cf6',
                            '&:hover': {
                              bgcolor: '#7c3aed',
                            },
                          }}
                        >
                          {t(
                            'Generate-ideas-for-slug',
                            "Générer des idées de slug d'URL"
                          )}
                        </LoadingButton>

                        <GlobalNumberField
                          style={{
                            bgcolor: 'white',
                            borderRadius: 'none !important',
                          }}
                          size="small"
                          formikProps={formikProps}
                          name={'nb_url_slug'}
                          isRequired={
                            globalValidationConfig.nb_url_slug.required
                          }
                          minValue={globalValidationConfig.nb_url_slug.min}
                          maxValue={globalValidationConfig.nb_url_slug.max}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <div className="flex flex-col z-[99] max-h-[275px] overflow-y-auto mt-2 pr-2 gap-4">
                        {formikProps.values.url_slug_ideas?.map((item, index) => (
                            <div className="flex flex-col">
                              <div className="relative ml-auto mr-1 flex ">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCopy(index, item);
                                    formikProps.setFieldValue('url_slug', item);
                                  }}
                                  className="p-1 rounded-t-md hover:bg-neutral-200 transition-all ease-in"
                                >
                                  <Check />
                                </button>
                                {copied === index ? (
                                  <div className="absolute top-0 rounded-md left-[-7em] bg-slate-500 py-2 px-4 text-white text-sm shadow-lg ">
                                    {t('Modified!', 'Modifié !')}
                                  </div>
                                ) : null}
                                <button
                                  className="p-1 rounded-t-md hover:bg-neutral-200 transition-all ease-in"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.setFieldValue(
                                      'url_slug_ideas',
                                      formikProps.values.url_slug_ideas.filter(
                                        (i) => i !== item
                                      )
                                    );
                                  }}
                                >
                                  <Close />
                                </button>
                              </div>
                              <div className="bg-neutral-200 font-semibold p-4 rounded-md">
                                {item}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>

      {/* PANEL */}
      <Box
        sx={{
          backgroundColor: '#303539',
          height: '100vh',
          //overflow: 'auto',
          width: { md: '350px', xs: '100%' },
          color: 'white',
          pb: 5,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#376f41',
            p: 2,
            position: 'relative',
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: 0,
              top: 0,
              p: 2,
            }}
          >
            <Close sx={{ color: 'white', fontSize: 32 }} />
          </Box>

          <h1 className="text-2xl mb-2">{t('URL-Slug', "Slug d'URL")}</h1>

          <p className="text-xs mb-2">
            {t(
              'tutorial-text-url-slug',
              'Le choix d’un bon slug d’URL est important pour le SEO, mais surtout pour l’expérience utilisateur, qui influe sur le classement de votre page. Suivez nos indications, générez des idées et inspirez-vous de la la concurrence !'
            )}
          </p>

          <p className="text-xs">
            {t('For-more-info:', "Pour plus d'infos :")}{' '}
            <span>
              <a className="text-blue-500 underline cursor-pointer">
                {
                  ('url-slug-optimization-guide',
                  'Slug d’URL optimisé pour le SEO : le Guide Complet 2024')
                }
              </a>
            </span>
          </p>
        </Box>

        <LaunchAnalysisBtn
          userInfo={userInfo}
          formikProps={formikProps}
          disabled={true}
        />
      </Box>
    </Box>
  );
};

export default UrlSlug;
