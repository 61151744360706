import { mergeAttributes, Node } from '@tiptap/core';
import './styles.scss';
import { v4 as uuidv4 } from 'uuid';

const H3Zone = Node.create({
  name: 'h3headingzone',
  group: 'block',
  content: 'heading block+',
  draggable: false,

  addAttributes() {
    return {
      id: {
        default: null, // Generate a unique ID only once when the node is created
        parseHTML: (element) => element.getAttribute('id'), // Parse the id from HTML
        renderHTML: (attributes) => {
          return {
            id: attributes.id || uuidv4(), // Keep the existing id or generate a new one
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'h3headingzone',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'h3headingzone',
      mergeAttributes(HTMLAttributes, {
        'data-type': 'h3headingzone',
        class: 'h3headingzone',
      }),
      0,
    ];
  },
});

export default H3Zone;
