import {
  Box,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
// import FErrorMessage from '../../../FErrorMessage';

const GlobalNumberField = ({
  style,
  size,
  formikProps,
  text,
  name,
  isRequired,
  minValue,
  maxValue,
  step = 1,
}) => {
  return (
    <Box pt={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {text ? (
          <InputLabel shrink sx={{ paddingRight: '100%' }}>
            {text}
          </InputLabel>
        ) : null}

        <TextField
          size={size ?? 'large'}
          variant='outlined'
          value={formikProps.values[name]}
          onBlur={formikProps.handleBlur}
          onChange={formikProps.handleChange}
          name={name}
          type='number'
          inputProps={{ min: minValue, max: maxValue, step: step }}
          error={
            formikProps.touched[name] &&
            Boolean(formikProps.errors[name]) &&
            isRequired
          }
          required={isRequired ? true : undefined}
          sx={{
            width: '100%',
            borderRadius: '20px !important',
            ...style,
          }}
        />

        {/* <FErrorMessage name={name} /> */}
      </Grid>
    </Box>
  );
};

export default GlobalNumberField;
