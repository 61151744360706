import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import botblogrLogo from '../../assets/images/botblogrLogo.png';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import sideNavData from '../nav';
import Header from './Header';
import './sidebar.scss';

export const Sidebar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [openTools, setOpenTools] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.auth).userInfo;

  const isQueryParams = (value) => {
    if (location?.search) {
      return `${location.pathname}${location.search}` === value ? 'active' : '';
    } else {
      return isActive(value); 
    }
  };

  const isActive = (value) => (location?.pathname === value ? 'active' : '');

  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleMainToolsClick = () => {
    setOpenTools(true);
  };
  const renderSubMenu = (children) => {
    return (
      <>
        {
          children.map((child, childIndex) => (
            <li className={`flex items-center ${isQueryParams(child?.link_to) ? "bg-blue-50" : "bg-gray"} justify-center hover:bg-blue-50 py-2 `}
              key={childIndex} onClick={() => navigate(`${child.link_to}`, { replace: true })}>
              <button className="px-6 text-white w-full py-2 flex item-center gap-3 md:ml-6 ml-0">
                {child.icon}  {t(child.name)}
              </button>

            </li>
          ))
        }
      </>
    );
  };
  return (
    <>
      <div className='flex md:h-screen h-full'>
        <aside className={`absolute left-0 top-0 z-50 flex h-screen w-72 flex-col overflow-y-hidden bg-darkgray duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${open == true ? "-translate-x-full" : null}`}>
          <div className="h-full py-4 overflow-y-auto bg-gray-50">
            <Link to={"/"} className="flex items-center sm:space-x-3 rtl:space-x-reverse mb-4 pl-2">
              <img src={botblogrLogo} className='w-[176px] md:ml-6 ml-0  object-contain' />
            </Link>
            <ul className="font-medium sidbar_menu">
              {sideNavData?.map((item, index) => (
                (((item?.name !== "Main Tools" && item?.name !== "Error-logs") || (item?.name === "Error-logs" && userInfo.role === "admin"))) && (
                  <li key={index} className={`items-center justify-center ${isActive(item?.link_to) ? "bg-blue-50" : null}`}
                    onClick={() => {
                      if (item?.children) {
                        handleMainToolsClick()// Toggle Main Tools submenu
                      } else {
                        setOpenTools(false)
                        navigate(`${item?.link_to}`, { replace: true });
                      }
                    }}
                  >
                    <button className="px-4 text-white w-full py-4 flex item-center justify-between">
                      <span className='lex item-center gap-3'>{item?.icon}  {t(item?.name)}</span>
                      {item?.children &&
                        <>
                          {openTools ? <ExpandLessIcon style={{ color: 'white', fontSize: '20px' }} /> : <ExpandMoreIcon style={{ color: 'white', fontSize: '20px' }} />}
                        </>
                      }
                    </button>
                    {item?.children && (
                      <ul className=''>
                        {item?.children && openTools && renderSubMenu(item?.children)}
                      </ul>
                    )}
                  </li>
                )
              ))}
            </ul>
          </div>
          <div className='lg:hidden block'>
            <ArrowBackIcon onClick={handleDrawer} sx={{ color: "#fff", position: "absolute", top: "20px", right: "20px", rotate: "180deg" }} />
          </div>
          <div className='lg:hidden block'>
            <ArrowBackIcon onClick={handleDrawer} sx={{ color: "#fff", position: "absolute", top: "20px", right: "20px", rotate: "180deg" }} />
          </div>
          <div className="text-white text-center py-4">
            <p>Des questions ?</p>
            <a href="mailto:contact@botblogr.com" className="font-bold">contact@botblogr.com</a>
          </div>
        </aside >
        <Header handleDrawer={handleDrawer} />
      </div>
    </>
  );
};

export default Sidebar;