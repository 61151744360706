import React, { useEffect, useState ,useRef} from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import {  useSelector } from "react-redux";
import { FeaturesTemplate, InputFormZone, OutputEditorZone, GenerationVariablesZone } from '../../../../shared/components/features/FeaturesTemplate';
import { GlobalTextField, GlobalNumberField, GlobalChoiceField } from '../../../../shared/components/features/FeaturesTemplate/FeatureFields'
import withFormik from '../../../../shared/utils/features/withFormik';
import { connectSocket } from '../../../../shared/sockets/socket';
import {
  useGetAllPostOutlineQuery,
  useGeneratePostOutlineMutation,
  useCreatePostOutlineMutation,
  useGetSinglePostOutlineMutation,
  useUpdateSinglePostOutlineMutation,
  useDeleteSinglePostOutlineMutation
} from "../../../../redux/slices/featuresApiSlice";
import { InitialValues, validationConfig, Schema } from "./helper";

const output_key = "outline";

const PostOutlineObject = ({ formikProps, config }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [ws, setWs] = useState();
  const divRef = useRef(null);
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const isReadOnly = userInfo.payment_status === "pending";

  useEffect(() => {
    if (userInfo && !ws) {
      const ws = connectSocket(userInfo.unique_id)
      setWs(ws);

      ws.onmessage = (e) => {
        var data = JSON.parse(e.data);

        if (data.isRewrite){
          config.handleRewriteOpen();
          config.rewritenOutput+=data.data;
          config.rewritenOutputHandler(config.rewritenOutput);
        } else {
          config.mainOutput += data.data;
          config.mainOutputHandler(
            config.mainOutput
          );
        } 
      };
    }
  }, [userInfo]);

  return (
    <FeaturesTemplate
      title={location?.state?.data?.["h1-tag"]}
      description={location?.state?.data?.description}
      formikProps={formikProps}
      onSave={config.handleSave}
      onDelete={config.handleDelete}
      loadingSave={config.createLoading || config.updateLoading}
      loadingDelete={config.deleteLoading}
      deleteButton={config.deleteButton}
      isReadOnly={isReadOnly}
    >
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <InputFormZone
          selectText={t("select-text-post-outline", "Créer un nouveau ou modifier un ancien plan d'article")}
          generateText={t("generate-post-outline", "Générer un plan")}
          rewriteText={t("rewrite-post-outline", "Regénérer un autre plan")}
          formikProps={formikProps}
          contents={config.contents}
          contentNameKey="subject"
          isTable={false}
          isNbOutputs={validationConfig.hasOwnProperty('nb_outputs')}
          outputs={config.outputs}
          mainOutput={config.mainOutput}
          handleGetSingle={config.handleGetSingle}
          handleCreateNew={config.handleCreateNew}
          setDeleteButton={config.setDeleteButton}
          isLoading={config.generateLoading}
          validationConfig={validationConfig}
          isReadOnly={isReadOnly}
          generationVariablesPanel={
            <GenerationVariablesZone
              formikProps={formikProps}
              isWriting={true}
              isCompany={true}
              isProduct={true}
              writingOpen={config.writingOpen}
              companyOpen={config.companyOpen}
              productOpen={config.productOpen}
              writingStylesVariables={config.writingStyles}
              companyVariables={config.companyVariables}
              productVariables={config.productVariables}
              handleWritingOpen={config.handleWritingOpen}
              handleCompanyOpen={config.handleCompanyOpen}
              handleProductOpen={config.handleProductOpen}
              handleWritingClose={config.handleWritingClose}
              handleCompanyClose={config.handleCompanyClose}
              handleProductClose={config.handleProductClose}
              isReadOnly={isReadOnly}
            />
          }
        >
          <GlobalTextField
            formikProps={formikProps}
            text={t("subject")}
            name="subject"
            placeholder={t("subject-ex")}
            isRequired={validationConfig.subject.required}
            multipleLines={true}
            maxLength={validationConfig.subject.max}
            isReadOnly={isReadOnly}
          />

          <GlobalTextField
            formikProps={formikProps}
            text={t("seo-target-request")}
            name="seo_request"
            placeholder={t("seo-target-request-ex")}
            isRequired={validationConfig.seo_request.required}
            multipleLines={false}
            maxLength={validationConfig.seo_request.max}
            isReadOnly={isReadOnly}
          />

          <GlobalNumberField 
            formikProps={formikProps}
            text={t("nb-parts", "Nombre de grandes parties (h2)")}
            name="nb_parts"
            isRequired={validationConfig.nb_parts.required}
            minValue={validationConfig.nb_parts.min}
            maxValue={validationConfig.nb_parts.max}
            isReadOnly={isReadOnly}
          />

          <GlobalNumberField 
            formikProps={formikProps}
            text={t("nb-words", "Nombre (approximatif) de mots dans l'article complet")}
            name="nb_words"
            isRequired={validationConfig.nb_words.required}
            minValue={validationConfig.nb_words.min}
            maxValue={validationConfig.nb_words.max}
            step={100}
            isReadOnly={isReadOnly}
          />

          <GlobalChoiceField
            formikProps={formikProps}
            text={t("is-h1-tag", "Aussi générer un titre H1 ?")}
            name="is_h1_tag"
            isRequired={validationConfig.is_h1_tag.required}
            isReadOnly={isReadOnly}
          />
        </InputFormZone>
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
        <OutputEditorZone 
          mainOutput={config.mainOutput}
          mainOutputHandler={config.mainOutputHandler} 
          isNbOutputs={validationConfig.hasOwnProperty('nb_outputs')}
          rewritenOutput={config.rewritenOutput}
          rewritenOutputHandler={config.rewritenOutputHandler}  
          customClasses='text-output-editor'
          placeholder={t("post-outline-placeholder", "Votre plan d'article ...")}
          toolbarButtons={["tag-selector"]}
          rewriteOpen={config.rewriteOpen}
          handleRewriteClose={config.handleRewriteClose}
          handleRewriteOpen={config.handleRewriteOpen}
          isReadOnly={isReadOnly}
        />
      </Grid>
      <div ref={divRef}></div>
    </FeaturesTemplate>
  
  );
};

const PostOutline = withFormik(PostOutlineObject, {    
  output_key: output_key,
  useGenerateContentMutation: useGeneratePostOutlineMutation,
  useCreateContentMutation: useCreatePostOutlineMutation, 
  useGetSingleContentMutation: useGetSinglePostOutlineMutation, 
  useUpdateContentMutation: useUpdateSinglePostOutlineMutation, 
  useDeleteContentMutation: useDeleteSinglePostOutlineMutation, 
  useGetAllContentsQuery: useGetAllPostOutlineQuery,
  InitialValues: InitialValues,
  Schema: Schema,
  isTable: output_key.includes("_table"),
  isNbOutputs: validationConfig.hasOwnProperty('nb_outputs'),
  streamGeneration: true
});

export default PostOutline;