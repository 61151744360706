import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import OutlineSelector from './OutlineSelector';
import { useFormik, useFormikContext } from 'formik';
import GlobalTextField from '../../FeaturesTemplate/FeaturesFields/GlobalTextField';
import GlobalNumberField from '../../FeaturesTemplate/FeaturesFields/GlobalNumberField';
import { Close, Domain } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  Grid,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../../../redux/store';
import {
  setActivePanel,
  setIsPanelOpen,
  setOpenOutline,
} from '../../../../../../redux/slices/panelSlice';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/react';
import TypographySelect from '../../Toolbar/TypographySelect';
import './styles.css';
import { globalValidationConfig } from '../formik-function/helpers';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useGeneratePostOutlineMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { SlNote } from 'react-icons/sl';
import { MdOutlineQueryStats } from 'react-icons/md';
import { BsMagic } from 'react-icons/bs';
import { FaFlagCheckered, FaLastfmSquare } from 'react-icons/fa';
import RewriteModal from '../global-rewrite-modal/rewrite-modal';
import {
  WritingStyleModal,
  CompanyModal,
} from '../../../../../../shared/components/generationVariables';
import {
  useGetAllWritingStylesQuery,
  useGetAllCompanyVariablesQuery,
} from '../../../../../../redux/slices/profileApiSlice';
import { LaunchAnalysisBtn } from '../../Others';
import { useWebSocket } from '../../WebSocketContext';

const Outline = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);

  const { openOutlineRewrite, setOpenOutlineRewrite, bufferRef } =
    useWebSocket();

  const [generatePostOutline, { isLoading: outlineGenerating }] =
    useGeneratePostOutlineMutation();
  const formikProps = useFormikContext();
  const open = useSelector((state) => state.panelSlice.openOutline);

  const { data: writingStyles, isLoading: writingLoading } =
    useGetAllWritingStylesQuery();
  const { data: companies, isLoading: companyLoading } =
    useGetAllCompanyVariablesQuery();
  // const { data: products, isLoading: productLoading } = useGetAllProductVariableQuery();
  const [writingOpen, setWritingOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  // const [productOpen, setProductOpen] = useState(false);
  const handleWritingOpen = () => {
    setWritingOpen(true);
  };
  const handleCompanyOpen = () => {
    setCompanyOpen(true);
  };
  // const handleProductOpen = () => {setProductOpen(true);};
  const handleCompanyClose = () => setCompanyOpen(false);
  const handleWritingClose = () => setWritingOpen(false);
  // const handleProductClose = () => setProductOpen(false);

  const [check, setCheck] = useState({
    introduction: false,
    conclusion: false,
  });

  const editor = useEditor({
    extensions: [StarterKit],
    content: '',
    onCreate: ({ editor }) => {
      editor.view.dom.innerHTML = formikProps.values.outline;
    },
    onUpdate: ({ editor }) => {
      if (editor.view.dom.textContent !== '') {
        formikProps.setFieldValue('outline', editor.view.dom.innerHTML);
      } else {
        formikProps.setFieldValue('outline', '');
      }
    },
  });

  useEffect(() => {
    if (editor && formikProps.values.outline !== editor.getHTML()) {
      editor.commands.setContent(formikProps.values.outline);
    }
  }, [formikProps.values.outline, editor]);

  const handleWriteOutline = () => {
    handleClose();

    if (check.introduction)
      document.getElementById('introzone').innerHTML = '<h2>Introduction</h2>';
    if (check.conclusion)
      document.getElementById('conclusionzone').innerHTML =
        '<h2>Conclusion</h2>';

    let editorContent = editor.getHTML();

    // Insert a new empty paragraph before every <h2> (except the first) to ensure clean line breaks
    editorContent = editorContent.replace(/(<h2>)/g, (match, p1, offset) => {
      return offset === 0 ? p1 : `<p></p>${p1}`;
    });

    const contentzone = document.getElementById('contentzone');
    if (contentzone) contentzone.innerHTML = editorContent;
  };

  const handleGenerateOultine = async () => {
    try {
      const payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        nb_words: formikProps.values.nb_words,
        nb_parts: formikProps.values.nb_parts,
        is_h1_tag: false,
      };
      if (formikProps.values.outline !== '') {
        payload['outline'] = formikProps.values.outline;
      }
      console.log('generate oultine payload: ', payload);

      const res = await generatePostOutline({
        ...payload,
        ...{ stream: true },
      }).unwrap();

      if (res?.status !== 200) {
        toast.error('Error while generating oultine');
        return;
      }

      console.log('outline generations res: ', res);

      if (formikProps.values.outline === '') {
        formikProps.setFieldValue('outline', res?.data);
      } else {
        formikProps.setFieldValue('outline_rewrite', res?.data);
        setOpenOutlineRewrite(true);
      }

      bufferRef.current = '';
    } catch (error) {
      if (
        error.error ===
        'The model `gpt-4o` does not exist or you do not have access to it.'
      ) {
        toast.error(
          t(
            'problem-with-openai',
            'Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?'
          )
        );
      } else if (
        error?.data?.detail ===
        'Access forbidden: you must have registered a functional OpenAI API Key to generate contents'
      ) {
        toast.error(
          t(
            'missing-api-key',
            'Vous devez avoir une clé API active pour générer du contenu'
          )
        );
      } else {
        toast.error(
          t(
            'something-went-wrong-with-generation',
            "Une erreur s'est produite lors de la génération"
          )
        );
      }
      console.log(error);
    }
  };

  const saveSelectedVersion = (data) => {
    editor.view.dom.innerHTML = data;
    //formikProps.setFieldValue('outline', data);
    formikProps.setFieldValue('outline_rewrite', '');
    setOpenOutlineRewrite(false);
  };

  const handleClose = () => {
    dispatch(setActivePanel(null));
  };
  return (
    <Box
      sx={{
        position: 'fixed',
        overflow: { md: 'unset', xs: 'scroll' },
        height: { md: '100vh', xs: '100%' },
        width: '100%',
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        mt: { md: '48px', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: { md: 'calc(100vw - 350px)', xs: '100%' },
          display: 'flex',
          height: 'calc(100vh - 48px)',
          //justifyContent: 'center',
          //overflow: { md: 'auto', xs: 'unset' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '80%',
            bgcolor: 'white',
            maxWidth: '900px',
            maxHeight: '800px',
            width: '95%',
            borderRadius: 4,
            m: 'auto',
          }}
        >
          <Box
            sx={{
              py: 2,
              px: 3,
              position: 'relative',
              borderBottom: '3px solid #e5e7eb',
            }}
          >
            <p className="font-semibold">{t('Settings', 'Réglages')}</p>
            <Box
              onClick={handleClose}
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                right: 0,
                top: 0,
                p: 1,
              }}
            >
              <Close sx={{ color: 'black' }} />
            </Box>
          </Box>
          <Box
            sx={{
              px: 3,
              py: 2,
              flex: 1,
              overflow: 'auto',
              bgcolor: 'white',
            }}
          >
            <Stack direction={{ md: 'row', xs: 'column' }} mb={2} spacing={1}>
              <Button
                onClick={handleWritingOpen}
                startIcon={<SlNote />}
                sx={{
                  flex: 1,
                  bgcolor: '#e5e5e5',
                  borderRadius: 2,
                  textAlign: 'center',
                  textTransform: 'none',
                  color: 'black',
                }}
              >
                {t('Writing-style', "Style d'écriture")}{' '}
                {formikProps.values.style_id ? '✅' : '(Optional)'}
              </Button>

              <WritingStyleModal
                open={writingOpen}
                handleClose={handleWritingClose}
                formikProps={formikProps}
                writingStyles={writingStyles}
              />

              <Button
                onClick={handleCompanyOpen}
                startIcon={<SlNote />}
                sx={{
                  flex: 1,
                  bgcolor: '#e5e5e5',
                  borderRadius: 2,
                  textAlign: 'center',
                  textTransform: 'none',
                  color: 'black',
                }}
              >
                {t('Company-website', 'Entreprise / Site web')}{' '}
                {formikProps.values.company_id ? '✅' : '(Optional)'}
              </Button>

              <CompanyModal
                open={companyOpen}
                handleClose={handleCompanyClose}
                formikProps={formikProps}
                companyStyles={companies}
              />

              {/* <Button
                        startIcon={<SlNote />}
                        sx={{
                          flex: 1,
                          bgcolor: '#e5e5e5',
                          borderRadius: 2,
                          textAlign: 'center',
                          textTransform: 'none',
                          color: 'black',
                        }}
                      >
                        Product
                      </Button> */}
            </Stack>

            <Grid container spacing={4}>
              <Grid item lg={6} xs={12}>
                <p>
                  {t('Subject-of-the-post', "Sujet de l'article")}
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <GlobalTextField
                  formikProps={formikProps}
                  name={'subject'}
                  placeholder={
                    'Ex: ' + t('blog-post-guide-seo', 'un guide complet SEO sur ...')
                  }
                  isRequired={globalValidationConfig.subject.required}
                  multipleLines={true}
                  maxLength={globalValidationConfig.subject.max}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <p>
                  {t('SEO-target-request', 'Requête SEO cible')}
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <GlobalTextField
                  formikProps={formikProps}
                  name={'seo_request'}
                  placeholder={
                    'Ex: ' + t('seo-request-guide-seo', 'article seo ia')
                  }
                  isRequired={globalValidationConfig.seo_request.required}
                  maxLength={globalValidationConfig.seo_request.max}
                />
                <div className="relative flex justify-center items-center group">
                  <button
                    className="text-sm hover:bg-blue-200 *: bg-blue-100 text-white disabled:bg-slate-200 disabled:text-slate-400 font-light px-5 py-[6px] rounded flex items-center"
                    disabled={true}
                  >
                    <MdOutlineQueryStats />{' '}
                    {t(' Launch-analysis', " Lancer l'analyse SEO")}
                  </button>

                  <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 hidden group-hover:flex flex-col items-center">
                    <div
                      className="w-3 h-2 bg-slate-600/80"
                      style={{
                        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                      }}
                    ></div>
                    <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                      {t('Soon-available', 'Bientôt disponible')}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{t('Outline', 'Plan')}</p>
                <Box>
                  <GlobalNumberField
                    formikProps={formikProps}
                    name={'nb_words'}
                    text={t('Nb-words', "Nombre de mots dans l'article")}
                    isRequired={globalValidationConfig.nb_words.required}
                    minValue={globalValidationConfig.nb_words.min}
                    maxValue={globalValidationConfig.nb_words.max}
                    step={100}
                  />
                </Box>
                <Box>
                  <GlobalNumberField
                    formikProps={formikProps}
                    name={'nb_parts'}
                    text={t('Nb-big-parts', 'Nombre de grandes parties (H2)')}
                    isRequired={globalValidationConfig.nb_parts.required}
                    minValue={globalValidationConfig.nb_parts.min}
                    maxValue={globalValidationConfig.nb_parts.max}
                  />
                </Box>
                <LoadingButton
                  startIcon={<BsMagic />}
                  loading={outlineGenerating}
                  onClick={handleGenerateOultine}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    my: 3,
                    bgcolor: '#8b5cf6',
                    '&:hover': {
                      bgcolor: '#7c3aed',
                    },
                  }}
                >
                  {t('Generate-outline', 'Générer le plan')}
                </LoadingButton>
              </Grid>
              <Grid item lg={8} xs={12}>
                <MiniEditor editor={editor} />

                {formikProps.values.outline_rewrite !== '' ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenOutlineRewrite(true)}
                  >
                    {t('View-rewritten-version', 'Voir la version réécrite')}
                  </Button>
                ) : null}

                {openOutlineRewrite ? (
                  <div
                    style={{
                      width: 'calc(100vw - 350px)',
                    }}
                    className="fixed z-[99999] mt-[48px] top-0 bg-[#00000029] left-0 flex   h-screen"
                  >
                    <RewriteModal
                      previous={formikProps.values.outline}
                      rewritten={formikProps.values.outline_rewrite}
                      save={saveSelectedVersion}
                      setOpenModal={setOpenOutlineRewrite}
                    />
                  </div>
                ) : null}
              </Grid>

              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: { lg: 'row', xs: 'column' },
                  alignItems: 'center',
                  gap: 2,
                }}
                lg={8}
                xs={12}
              >
                <p>{t('Include-h2s-for', 'Inclure un H2 pour')}</p>
                <FormControl>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check.introduction}
                          onChange={(e) => {
                            setCheck({
                              ...check,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                          name="introduction"
                        />
                      }
                      label="Introduction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setCheck({
                              ...check,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                          checked={check.conclusion}
                          name="conclusion"
                        />
                      }
                      label="Conclusion"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
                lg={4}
                xs={12}
              >
                <Button
                  startIcon={<FaFlagCheckered />}
                  sx={{ textTransform: 'none' }}
                  variant="contained"
                  onClick={handleWriteOutline}
                >
                  {t('Start-with-this-outline', 'Commencer avec ce plan')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: '#303539',
          height: '100vh',
          width: { md: '350px', xs: '100%' },
          color: 'white',
          pb: 5,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#9b4843',
            p: 2,
            position: 'relative',
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: 0,
              top: 0,
              p: 1,
            }}
          >
            <Close sx={{ color: 'white' }} />
          </Box>
          <h1 className="text-2xl mb-2">{t('Outline', 'Plan')}</h1>

          <p className="text-xs mb-2">
            {t(
              'tutorial-text-outline',
              'La première étape dans la rédaction de votre contenu est d’établir un bon plan. Suivez les indications et comparez-vous à vos concurrents pour réaliser le meilleur plan possible !'
            )}
          </p>

          <p className="text-xs">
            {t('For-more-info:', "Pour plus d'infos :")}{' '}
            <span>
              <a className="text-blue-500 underline cursor-pointer">
                {
                  ('outline-optimization-guide',
                  'Guide complet pour réaliser le meilleur plan SEO en 2024')
                }
              </a>
            </span>
          </p>
        </Box>

        <LaunchAnalysisBtn
          userInfo={userInfo}
          formikProps={formikProps}
          disabled={true}
        />
      </Box>
    </Box>
  );
};

const MiniEditor = ({ editor }) => {
  return editor ? (
    <Box sx={{ width: '100%', height: 'auto', mb: 3 }}>
      <TypographySelect editor={editor} />
      <EditorContent className="mini-editor" editor={editor} />
    </Box>
  ) : null;
};

export default Outline;
