import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: null,
  accessToken: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state, action) => {
      state.userInfo = null;
      state.accessToken = null;
    },
    refreshAccessTokenSuccess: (state, action) => {
      state.accessToken = action.payload.accessToken;
    }
  },
});

export const { setCredentials, logout, refreshAccessTokenSuccess } = authSlice.actions;

export default authSlice.reducer;