import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from "react-redux";
import { ClipLoader } from 'react-spinners';
import stripeConfig from '../../utils/stripeConfig';

const PlansOptions = ({
    isPage,
    selectSubscription
}) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state?.auth).userInfo;
    const token = useSelector((state) => state?.auth?.accessToken);

    const [isYearly, setIsYearly] = useState(false);

    const [monthlyStandardLoading, setMonthlyStandardLoading]=useState(false)
    const [yearlyStandardLoading, setYearlyStandardLoading]=useState(false)

    const STANDARD_PRICE_ID = stripeConfig.priceIds.monthly.standard;
    const ANNUAL_STANDARD_PRICE_ID = stripeConfig.priceIds.yearly.standard;

    const stripePromise = loadStripe(stripeConfig.publishableKey);
    
    // CHECKOUT SESSIONS FOR WHEN USER DOES NOT HAVE ANY PLAN
    const createCheckoutSession = async (priceId) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                priceId: priceId
            })
        });

        const result = await response.json();
        return result;
    };

    const handleStandardCheckout = async (period) => {
    try {
        if (period === "annual") {
            setYearlyStandardLoading(true)
        } else {
            setMonthlyStandardLoading(true)
        }
        const data = await createCheckoutSession(period === "annual" ? ANNUAL_STANDARD_PRICE_ID : STANDARD_PRICE_ID);
        const stripe = await stripePromise;
        if (period === "annual") {
            setYearlyStandardLoading(false)
        } else {
            setMonthlyStandardLoading(false)
        }
        stripe.redirectToCheckout({
            sessionId: data.sessionId
        });
    } catch (error) {
        console.error('Error during premium checkout:', error);
    }
    };

    const tiers = [
    {
        title: 'Mensuel',
        price: "34.90",
        description: t("To-start-plan", "Idéal pour découvrir et exploiter les avantages de l'outil à votre rythme."),
        features_list: [
            t("7-days-free-trial", "7 jours d'essai gratuit"),
            t("unlimited-access-to-ai-editor", "Accès illimité à l’éditeur IA"),
            t("outline-intros-content-generated", "Plans, intros et contenus générés"),
            t("export-3-options", "Export en HTML, .docx ou copier-coller"),
            t("custom-writing-style-and-identity", "Style d’écriture et identité personnalisables"),
            t("auto-optimized-meta-tags", "Balises et méta optimisées automatiquement"),
            t("preview-serp-included", "Prévisualisation SERP incluse"),
            t("priority-support", "Support prioritaire et mises à jour fréquentes")
        ],
        buttonText: userInfo.payment_status === null ? t("start-for-free", "Démarrer gratuitement !") : ((userInfo.plan === "Standard" && userInfo.plan_period === "monthly" ? t("current-plan", "Abonnement actuel") : t("change-plan", "Changer d'abonnement"))),
        buttonVariant: 'contained',
        loading: monthlyStandardLoading,
        disabled: ((userInfo.plan === "Standard" && userInfo.plan_period === "monthly") ? true : monthlyStandardLoading),
        onClick: () => (userInfo.plan === null ? handleStandardCheckout("monthly") : selectSubscription(STANDARD_PRICE_ID)),
    },
    {
        title: 'Annuel',
        price: "349.00",
        description: t("Long-term-plan", "Une solution rentable pour une stratégie SEO sur le long terme."),
        features_list: [
            t("7-days-free-trial", "7 jours d'essai gratuit"),
            t("unlimited-access-to-ai-editor", "Accès illimité à l’éditeur IA"),
            t("outline-intros-content-generated", "Plans, intros et contenus générés"),
            t("export-3-options", "Export en HTML, .docx ou copier-coller"),
            t("custom-writing-style-and-identity", "Style d’écriture et identité personnalisables"),
            t("auto-optimized-meta-tags", "Balises et méta optimisées automatiquement"),
            t("preview-serp-included", "Prévisualisation SERP incluse"),
            t("priority-support", "Support prioritaire et mises à jour fréquentes")
        ],
        buttonText: userInfo.payment_status === null ? t("start-for-free", "Démarrer gratuitement !") : ((userInfo.plan === "Standard" && userInfo.plan_period === "annual" ? t("current-plan", "Abonnement actuel") : t("change-plan", "Changer d'abonnement"))),
        buttonVariant: 'contained',
        loading: yearlyStandardLoading,
        disabled: ((userInfo.plan === "Standard" && userInfo.plan_period === "annual") ? true : yearlyStandardLoading),
        onClick: () => (userInfo.plan === null ? handleStandardCheckout("annual") : selectSubscription(ANNUAL_STANDARD_PRICE_ID)),
    },
    ];

    return (
        <div className={`${isPage ? "mt-3" : "mt-1"}`}>
            
            <div className="flex flex-col justify-center items-center">

                {userInfo.payment_status === null
                && <p className="text-center mb-4">{t("choose-a-subscription-to-start", "Choisissez un abonnement pour commencer")}</p>}

            </div>


            <div className="mt-8 container mx-auto max-w-4xl space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-12 lg:gap-y-8">
                {tiers.map((tier, tierIndex) => (
                    <div
                        key={`${tier.name}-${tierIndex}`}
                        className="relative p-6 md:p-8 border border-gray-200 rounded-2xl shadow-lg flex flex-col bg-white text-black"
                    >
                        <div className="flex-1">
                            {tier.subheader && (
                                <p className="absolute top-0 left-1/2 py-1.5 px-4 uppercase text-xs font-semibold bg-blue-50 text-white rounded-full tracking-wide transform -translate-x-1/2 -translate-y-1/2">
                                    {tier.subheader}
                                </p>
                            )}

                            <h2 className="text-xl font-semibold text-center">{tier.title}</h2>

                            <p className="mt-4 flex items-baseline justify-center">
                            <span className="text-6xl font-bold text-blue-100">{tier.price}</span>
                            <span  className="text-4xl font-bold text-blue-100">€</span>
                            <span className="text-xl font-semibold ml-1">
                                /{tier.price == "349.00" ? t("year", "an") : t("month", "mois")}
                            </span>
                            </p>

                            <p className="text-center text-sm mt-4">{tier.description}</p>

                            <div className="flex flex-col items-center">
                                {/* Button */}
                                <button
                                    onClick={tier.onClick}
                                    className={`mt-6 block w-full py-3 px-6 rounded-md text-center font-semibold border-2 border-solid transition transform ${
                                        tier.disabled
                                            ? "bg-gray_local-400 text-white border-gray_local-400 cursor-not-allowed"
                                            : "bg-gradient-to-r from-blue-100 to-blue-50 text-white border-transparent hover:from-blue-50 hover:to-blue-100 hover:scale-105"
                                    }`}
                                    disabled={tier.disabled}
                                >
                                    {!tier.loading ? tier?.buttonText : <ClipLoader size={25} color="white" />}
                                </button>

                                {/* Secure Payment Text */}
                                <p className="flex items-center mt-2 text-sm text-gray-500">
                                    <span className="mr-1">🔒</span>
                                    Paiements sécurisés par<strong className="ml-1">Stripe</strong>
                                </p>
                            </div>


                            <p className="text-center text-sm mt-6 font-bold">Cet abonnement comprend :</p>
                            <ul role="list" className="mt-4 space-y-3">
                            {tier.features_list.map((line, lineIndex) => (
                                <li key={`${tier.name}-line-${lineIndex}`} className="flex items-start text-sm">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="w-5 h-5 mr-2 text-blue-100 flex-shrink-0"
                                    >
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" fill="none" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4" />
                                    </svg>

                                    {line}
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PlansOptions;