import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useCreateSetupIntentQuery } from '../../../redux/slices/paymentApiSlice';
import { useAddPaymentMethodMutation } from '../../../redux/slices/paymentApiSlice';
import stripeConfig from '../../utils/stripeConfig';
import { ClipLoader } from 'react-spinners';
import { CustomLoader } from '../others';

const stripePromise = loadStripe(stripeConfig.publishableKey);

const AddPaymentMethodForm = ({ refetch_card, closeModal, refetchCurrentSub }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [addPaymentMethod, { isLoading: addPaymentMethodLoading }] = useAddPaymentMethodMutation();

  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/add-payment-method`,
      }
    });

    if (result.error) {
      toast.error(result.error.message);
    } else {
      try {
        const response = await addPaymentMethod({ setup_intent_id: result.setupIntent.id });
        
        if ('data' in response && response.data.status === 201) {
          refetch_card();
          refetchCurrentSub();
          toast.success(t('payment-method-successfully-added', 'Votre moyen de paiement a bien été ajouté !'));
          closeModal()
        } else if ('error' in response) {
          toast.error(response.error.data?.detail || t('failed-to-add-payment-method', 'L\'ajout de votre moyen de paiement a échoué'));
        }
      } catch (error) {
        toast.error(t('failed-to-add-payment-method', 'L\'ajout de votre moyen de paiement a échoué'));
      }
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mt-4">
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || loading}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50 mt-4"
      >
        {loading || addPaymentMethodLoading ? <ClipLoader color='#fffff'/>: t("add-this-payment-method", "Ajouter ce moyen de paiement")}
      </button>
    </form>
  );
};

const AddPaymentMethod = ({ refetch_card, closeModal, refetchCurrentSub }) => {
  const { data: setupIntent, isLoading: setupIntentLoading } = useCreateSetupIntentQuery();

  if (setupIntentLoading || !setupIntent) {
    return <div className="flex justify-center items-center h-[65vh]">
        <CustomLoader />
    </div>;
  }

  const options = {
    clientSecret: setupIntent.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <AddPaymentMethodForm refetch_card={refetch_card} closeModal={closeModal} refetchCurrentSub={refetchCurrentSub} />
    </Elements>
  );
};

export default AddPaymentMethod;