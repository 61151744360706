import './index.css';
import Tiptap from './components/Tiptap';
import { FormikFunction } from './components/SidePanel/formik-function/formik-function';

const Editor = ({ blogPost }) => {
  return (
    <FormikFunction blogPost={blogPost}>
      <Tiptap />
    </FormikFunction>
  );
};

export default Editor;
