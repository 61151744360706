import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from "../../redux/slices/authSlice";
import { CustomLoader } from '../../shared/components/others';

const OauthLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.size === 0) {
      navigate("/dashboard");
    }

    const userInfo = {
      role: queryParams.get('role') === "None" ? null : queryParams.get('role'),
      first_name: queryParams.get('first_name') === "None" ? null : queryParams.get('first_name'),
      last_name: queryParams.get('last_name') === "None" ? null : queryParams.get('last_name'),
      username: queryParams.get('username') === "None" ? null : queryParams.get('last_name'),
      is_verified: queryParams.get('is_verified') === "False" ? false : true,
      email: queryParams.get('email') === "None" ? null : queryParams.get('email'),
      user_id: queryParams.get('user_id'),
      unique_id: queryParams.get('unique_id'),
      payment_status: queryParams.get('payment_status') === "None" ? null : queryParams.get('payment_status'),
      plan: queryParams.get('plan') === "None" ? null : queryParams.get('plan'),
      plan_period: queryParams.get('plan_period') === "None" ? null : queryParams.get('plan_period'),
      openai_organization: queryParams.get('openai_organization') === "None" ? null : queryParams.get('openai_organization'),
      openai_api_key: queryParams.get('openai_api_key') === "None" ? null : queryParams.get('openai_api_key'),
    };
    const accessToken = queryParams.get('access_token');

    dispatch(setCredentials({
      accessToken: accessToken,
      userInfo: userInfo
    }));
    navigate("/dashboard");

  }, [location.search, navigate, dispatch]);

  return (
    <CustomLoader />
  );
};

export default OauthLogin;