import { useTranslation } from 'react-i18next';
import React from "react";
import { Field } from "formik";
import { FieldErrorMessage } from "../others";


const GVModalTemplate = ({
  open,
  handleClose,
  formikProps,
  generationVariablesList,
  fieldName,
  modalTitle,
  modalIcon,
  modalDescription,
  noSelectionText,
}) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md">
        {/* Header */}
        <div className="flex items-center mb-4">
          {modalIcon && <img src={modalIcon} alt="icon" className="w-10 h-10 mr-3" />}
          <h3 className="text-2xl font-bold">
            {modalTitle} <span className="text-gray-500 text-sm">({t("Optional", "Optionnel")})</span>
          </h3>
        </div>

        {/* Description */}
        <p className="text-gray-700 mb-6">{modalDescription}</p>

        {/* Form Field */}
        <div className="mb-4">
          <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700">
            {modalTitle}
          </label>
          <Field
            as="select"
            name={fieldName}
            id={fieldName}
            className={`mt-1 block w-full p-2 border ${
              formikProps.touched[fieldName] && formikProps.errors[fieldName]
                ? "border-red-500"
                : "border-gray-300"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
          >
            <option value="">{`--${noSelectionText}--`}</option>
            {generationVariablesList?.map((item) => (
              <option key={`${fieldName}_${item.id}`} value={item.id}>
                {item.key}
              </option>
            ))}
          </Field>
          <FieldErrorMessage name={fieldName} />
        </div>

        {/* Close Button */}
        <button
          onClick={handleClose}
          className="w-full py-2 px-4 bg-gray_local-400 text-white rounded-md hover:bg-gray_local-400/70 transition duration-150"
        >
          {t("Close", "Fermer")}
        </button>
      </div>
    </div>
  );
};

export default GVModalTemplate;
