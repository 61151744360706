import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react'
import { ChangePasswordInitialValues, ChangePasswordSchema } from './helper';
import { ClipLoader } from 'react-spinners';
import { useUpdatePasswordMutation } from '../../redux/slices/profileApiSlice';
import { toast } from 'react-toastify';
import { InputProfile } from "../../shared/components/profile"

const ChangePassword = () => {
  const { t } = useTranslation();
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  
  return (
    <>
    <Formik
      initialValues={ChangePasswordInitialValues}
      enableReinitialize={true}
      validationSchema={ChangePasswordSchema}
      onSubmit={async (values,{resetForm}) => {
        try{
        const data={
          old_password:values.oldPassword,
          new_password:values.newPassword,
          confirm_password:values.confirmNewPassword,
        }
        if(values.newPassword!==values.confirmNewPassword){
          toast.error("New Password and Confirm New Password Doesn't Match")
          return;
        }
        const response=await updatePassword(data);
        if(response?.data?.status===200){
          toast.success(response?.data?.message)
          resetForm()
        }else{
          toast.error(response?.error?.data?.detail)
        }
      }catch(error){
        toast.error("Error Occur While Changing Password")
      }
      }}
    >
      {(props) => (
        <div className=" mx-auto h-[70vh] flex items-center justify-center bg-white shadow-default rounded-2xl">
          <form onSubmit={props.handleSubmit}>
              <div className="grid md:grid-cols-2 gap-x-11 gap-5">
                
                <InputProfile
                  formikProps={props}
                  type="password"
                  text={t("enter-ancient-password", "Entrez votre ancien mot de passe")}
                  name="oldPassword"
                  placeholder={t("enter-ancient-password", "Entrez votre ancien mot de passe")}
                />

                <InputProfile
                  formikProps={props}
                  type="password"
                  text={t("enter-new-password", "Entrez votre nouveau mot de passe")}
                  name="newPassword"
                  placeholder={t("enter-new-password", "Entrez votre nouveau mot de passe")}
                />

                <InputProfile
                  formikProps={props}
                  type="password"
                  text={t("confirm-new-password", "Confirmez votre nouveau mot de passe")}
                  name="confirmNewPassword"
                  placeholder={t("confirm-new-password", "Confirmez votre nouveau mot de passe")}
                />

                <div className="col-span-2">
                  <button className="px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light">
                  {isLoading ? (
                    <ClipLoader size={25} color="white" />
                  ) : (
                    t('Change-password','Changer le mot de passe')
                  )}
                  </button>
                </div>
                
              </div>
            </form>
          </div>
      )}
    </Formik>
  </>
  )
}

export default ChangePassword