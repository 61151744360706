import { ClipLoader } from "react-spinners";

const ButtonAuth = ({ 
    action,
    disabled,
    isLoading,
    type,
    text,
    secondary=false
}) => {
    if (secondary) {
        return (
            <button
                type={type}
                className="flex w-full justify-center rounded-md bg-gray_local-400 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={action}
                disabled={disabled}
            >
            {isLoading ? (
                <ClipLoader size={25} color="white" />
            ) : (
                text
            )}
            </button>
        )
    } else {
        return (
            <button
                type={type}
                className="flex w-full justify-center rounded-md bg-blue-50 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={action}
                disabled={disabled}
            >
            {isLoading ? (
                <ClipLoader size={25} color="white" />
            ) : (
                text
            )}
            </button>
        )
    }
}

export default ButtonAuth;