import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import pen from "../../../assets/images/pen.svg";
import { Formik } from "formik";
import { WritingStyleInitialValues, WritingStyleSchema } from "../helper";
import {
  useDeleteWritingStyleMutation,
  useGetAllWritingStylesQuery,
  useGetSingleWritingStyleMutation,
  useUpdateSingleWritingStyleMutation,
  useWritingStyleMutation,
} from "../../../redux/slices/profileApiSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../../shared/components/generationVariables/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import { InputProfile, SelectProfile } from "../../../shared/components/profile";

const WritingStyle = () => {
  const { t } = useTranslation();
  const [writing, { isLoading }] = useWritingStyleMutation();
  const [counter, setCounter] = useState(0);
  const [selectId, setSelectId] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [singleWriting, { isLoading: loadingSingleWriting }] =
    useGetSingleWritingStyleMutation();
  const [updateWriting, { isLoading: updateSingleWritingLoading }] =
    useUpdateSingleWritingStyleMutation();
  const [onEdit, setOnEdit] = useState(false);
  const [editWriting, setEditWriting] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    data: writingStyles,
    isLoading: loading,
    refetch,
  } = useGetAllWritingStylesQuery();
  const [deleteWritingStyle, { isLoading: loadingDelete }] = useDeleteWritingStyleMutation();

  const fstyles = [
    { name: "Vous", label: "Vous" },
    { name: "Tu", label: "Tu" },
  ];

  const tones = [
    { name: "Neutre", label: "Neutre" },
    { name: "Amical", label: "Amical" },
    { name: "Formel", label: "Formel" },
    { name: "Professionnel", label: "Professionnel" },
    { name: "Enthousiaste", label: "Enthousiaste" },
    { name: "Inspirant", label: "Inspirant" },
    { name: "Persuasif", label: "Persuasif" },
    { name: "Éducatif", label: "Éducatif" },
    { name: "Humoristique", label: "Humoristique" },
    { name: "Empathique", label: "Empathique" },
    { name: "Créatif", label: "Créatif" },
    { name: "Direct", label: "Direct" },
    { name: "Assertif", label: "Assertif" },
    { name: "Mystérieux", label: "Mystérieux" },
    { name: "Optimiste", label: "Optimiste" },
    { name: "Informel", label: "Informel" },
    { name: "Autoritaire", label: "Autoritaire" },
    { name: "Réconfortant", label: "Réconfortant" },
    { name: "Narratif", label: "Narratif" },
    { name: "Personnalisé", label: "Personnalisé" },
  ];

  useEffect(() => {
    refetch();
  }, [counter]);

  // To open the delete modal
  const handleDeleteWritingStyle = async (id) => {
    setOpen(true);
    setSelectId(id);
  };

  // To delete the object
  const handleDelete = async () => {
    try {
      const res = await deleteWritingStyle(selectId);
      if (res) {
        toast.success("Deleted successfully");
        setOpen(false);
        setOnEdit(false);
        setEditWriting(null);
        refetch();
      }
    } catch (error) {
      toast.error(error.error || "Something went wrong");
      setOpen(false);
    }
  };

  // To edit the product
  const handleWritingEdit = async (id) => {
    if (id) {
      try {
        setOnEdit(true);
        const res = await singleWriting({ id }).unwrap();
        setEditWriting(res);
        setIsCustom(!tones.map(tone => tone.name).includes(res.tone));
      } catch (error) {}
    }
  };

  const handleCreateNew = async (resetForm) => {
    setOnEdit(false);
    setEditWriting(null);
    setIsCustom(false);
    refetch();
    resetForm();
  };

  // Handle tone change
  const handleToneSelectChange = (props, e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "Personnalisé") {
      props.setFieldValue('tone', "");
    } else {
      props.setFieldValue('tone', selectedValue);
    }
    setIsCustom(selectedValue === "Personnalisé");
  };

  return (
    <div className="bg-white py-10 rounded-2xl px-6 mx-auto shadow-default items-center ">
      
      <div className="flex items-center">
        <img src={pen} width={35} height={35} />
        <p className="md:text-[28px] sm:text-lg font-medium ml-3">
          {t("writing-style", "Style d'écriture")}
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mt-4">
        <Formik
          initialValues={editWriting ? editWriting : WritingStyleInitialValues}
          enableReinitialize={true}
          validationSchema={WritingStyleSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              if (onEdit) {
                const res = await updateWriting(values).unwrap();
                if (res?.status === 200) {
                  toast.success(t("writing-style-updated-succesfully","Votre style d'écriture a bien été modifié !"));
                  setCounter(counter + 1);
                }
              } else {
                const res = await writing(values).unwrap();
                setCounter((prev) => prev + 1);
                if (res?.status === 201) {
                  toast.success(t("writing-style-created-succesfully","Votre style d'écriture a bien été créé !"));
                  handleWritingEdit(res.data.id);
                }
              }
            } catch (error) {
              toast?.error(error?.data?.detail);
            }
          }}
        >
          {(props) => (
            <div className=" mx-auto w-full flex items-center justify-center">
              <form onSubmit={props.handleSubmit} className="w-full">
                <div className="grid md:grid-cols-1 gap-y-5 w-full">
                  
                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("writing-style-key", "Nom du style d'écriture (utilisé pour vous permettre de l'identifier)")}
                      name="key"
                      placeholder={t("example-style-key", "Ex: BotBlogR blog")}
                    />
                  </div>

                  <div>
                    <div className="flex items-center gap-9">
                      <label className="relative cursor-pointer block w-fit">
                        <input
                          className="peer sr-only"
                          defaultValue=""
                          type="checkbox"
                          checked={props.values.is_default}
                          onChange={props.handleChange}
                          name="is_default"
                        />
                        <div className="peer h-[14px] w-9 rounded-full bg-gray_local-400 after:absolute after:top-[-3px] after:left-0 after:h-5 after:w-5 after:rounded-full after:shadow-2xl after:bg-[#e1e1e1] after:transition-all after:content-[''] peer-checked:bg-[#316694] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none" />
                      </label>
                      <p className="text-sm">{t("choose-as-default-writing-style", "Choisir comme style d'écriture par défaut")}</p>
                    </div>
                  </div>

                  <SelectProfile
                    formikProps={props}
                    options={fstyles}
                    text={t("writing-style-familiarity", "Mode de communication (Vous/Tu)")}
                    name="familiarity"
                  />
                  
                  <SelectProfile
                    formikProps={props}
                    options={tones}
                    text={t("writing-style-tone", "Ton de communication")}
                    name="select_tone"
                    customHandleChange={handleToneSelectChange}
                    toneList={tones.map(tone => tone.name)}
                  />

                  <div className={isCustom ? 'block' : 'hidden'}>
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("custom-tone-example", "Ton personnalisé")}
                      name="tone"
                      placeholder={t("example-custom-tone", "Ex: scientifique et précis")}
                      maxLength={80}
                    />
                  </div>

                  <InputProfile
                    formikProps={props}
                    type="text"
                    text={t("writing-style-example", "Exemple de style d'écriture (utilisé pour copier fidèlement votre façon d'écrire)")}
                    name="writing_example"
                    placeholder={t("example-style-writing-example", "Ex: Rédiger un article de blog optimisé pour le SEO et intéressant ? Pas ...")}
                    maxLength={1500}
                    multiline={true}
                  />

                  <div className="w-full flex justify-between items-center gap-x-4">
                    <button
                      className="px-5 py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                      onClick={props.handleSubmit}
                      disabled={isLoading || updateSingleWritingLoading}
                    >
                      {isLoading || updateSingleWritingLoading ? (
                        <ClipLoader size={25} color="white" />
                      ) : (
                        t("save-all-changes","Sauvegarder les changements")
                      )}
                    </button>
                    <button
                      type="button"
                      className="px-5 py-[6px] hover:bg-success_hover bg-success text-white rounded font-light"
                      onClick={() => handleCreateNew(props.resetForm)}
                    >
                      {t("create-new-writing-style","Créer un nouveau style d'écriture")}
                    </button>
                  </div>

                </div>
              </form>
            </div>
          )}
        </Formik>
        <div className="container">
          <div className="mt-6">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs text-white uppercase bg-blue-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-s-md text-base"
                    >
                      {t("name", "Nom")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-e-md w-48 text-base"
                    >
                      {t("action", "Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {writingStyles?.map((item) => (
                    <tr key={`writing-style-${item.id}`} className="odd:bg-white even:bg-black/10">
                      <td
                        scope="col"
                        className="px-6 py-4 text-sm font-medium text-gray-900 break-all"
                      >
                        {item.key}
                      </td>
                      <td
                        scope="col"
                        className="px-6 py-4 font-medium text-gray-900 flex gap-6 w-48"
                      >
                        <button
                          className="px-5 py-[6px] hover:bg-blue-200 bg-blue-100 text-white rounded font-light"
                          onClick={() => handleWritingEdit(item?.id)}
                        >
                          <EditIcon />
                        </button>
                        <button
                          className="px-5 py-[6px] hover:bg-[#f44336] bg-[#ff574b] text-white rounded font-light"
                          onClick={() => handleDeleteWritingStyle(item?.id)}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
      <DeleteDialog
        open={open}
        text={t("are-you-sure-to-delete-writing-style", "Es-tu sûr de vouloir supprimer ce style d'écriture ?")}
        handleDelete={handleDelete}
        setOpen={setOpen}
        loading={loadingDelete}
        title={t("delete-writing-style", "Supprimer le style d'écriture")}
      />
    </div>
  );
};

export default WritingStyle;