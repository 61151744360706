import * as Yup from 'yup';

export const AccountInitialValues = {
  username: '',
  first_name: '',
  last_name: '',
};

export const AccountSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9_-]{3,20}$/,
      'Username must be 3-20 characters long and can contain letters, numbers, underscores, and hyphens'
    )
    .required('Username is required'),
  first_name: Yup.string()
    .max(50, 'First name must be at most 50 characters')
    .required('First name is required'),
  last_name: Yup.string()
    .max(50, 'Last name must be at most 50 characters')
    .required('Last name is required'),
});

export const ChangePasswordInitialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmNewPassword: Yup.string()
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
});

export const ChangeEmailInitialValues = {
  old_email: '',
  new_email: '',
};

export const ChangeEmailSchema = Yup.object().shape({
  old_email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Old Email is required'),

  new_email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required(' New Email is required'),
});

export const WritingStyleInitialValues = {
  writing_example: '',
  tone: 'Neutre',
  familiarity: 'Vous',
  key: '',
  is_default: false,
};
export const WritingStyleSchema = Yup.object().shape({
  writing_example: Yup.string().max(1500),
  tone: Yup.string().required('Tone is required'),
  familiarity: Yup.string().required('Familiarity is required'),
  key: Yup.string().required('Style Name is required').max(80),
  is_default: Yup.boolean().required('Default Style is required'),
});

export const EntrePriseInitialValues = {
  key: '',
  name: '',
  description: '',
  audience: '',
  is_default: false,
  website: '',
};

export const EntrePriseSchema = Yup.object().shape({
  key: Yup.string().required('Company Name is required').max(80),
  name: Yup.string().required('Name is required').max(50),
  description: Yup.string().required('Description is required').max(250),
  audience: Yup.string().required('Target Audience is required').max(250),
  is_default: Yup.boolean().required('Default Style is required'),
  website: Yup.string()
    .url('Invalid URL format')
    .required('Website URL is required'),
});

export const ProductInitialValues = {
  key: '',
  name: '',
  description: '',
  is_default: false,
  link: '',
};

export const ProductSchema = Yup.object().shape({
  key: Yup.string().required('Product Name is required'),
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  is_default: Yup.boolean().required('Default Style is required'),
  link: Yup.string()
    .url('Invalid URL format')
    .required('Website URL is required'),
});

export const LinkOpenAIInitialValues = {
  openai_organization: '',
  openai_api_key: '',
};

export const LinkOpenAISchema = Yup.object().shape({
  openai_organization: Yup.string(),
    // .matches(
    //   /^org-[A-Za-z0-9]{1,50}$/,
    //   "Votre organisation OpenAI est toujours composée des caractères 'org-' suivis par une chaine de nombres et lettres"
    // ),
    // .required('Une organisation openai est requise pour lier votre compte !'),
  openai_api_key: Yup.string()
    .matches(
      /^sk-.+/,
      "Votre organisation OpenAI est toujours composée des caractères 'sk-' suivis par une chaine de nombres et de lettres"
    )
    .required('Une clé API openai est requise pour lier votre compte !'),
});
