import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CustomLoader } from '../../shared/components/others';

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/confirm-email/${token}/`,{token:token}, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 202) {
          toast.success(t("email-verification-successfull", "Votre email a bien été validé. Vous pouvez maintenant vous reconnecter !"))
          navigate('/login');
        } else {
          toast.error(t("email-verification-failed", "La vérification de votre adresse email a échoué !"))
        }
      } catch (error) {
        toast.error(t("something-went-wrong", "Une erreur s'est produite"))
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <CustomLoader />
  );
};

export default ConfirmEmail;