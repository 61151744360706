import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useState, useRef } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
  DialogPanel,
} from '@headlessui/react';
import { FaFileExport, FaFileDownload } from 'react-icons/fa';
import { html } from 'js-beautify';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useConvertHtmlToDocxMutation } from '../../../../../redux/slices/postEditorBlogSlice';

const ExportModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const formikProps = useFormikContext();
  const [modalType, setModalType] = useState('default');
  const contentEditorRef = useRef(null);
  const [copied, setCopied] = useState(null);
  const [convertHtmlToDocx, { isLoading: isConverting }] =
    useConvertHtmlToDocxMutation();

  const Editor = ({ content }) => {
    const editor = useEditor({
      extensions: [
        StarterKit,
        Table.configure({
          resizable: true,
          allowTableNodeSelection: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      content: content || '',
      editable: false,
    });

    if (editor && editor.getHTML !== contentEditorRef.current) {
      contentEditorRef.current = editor;
    }

    return editor ? <EditorContent className="" editor={editor} /> : null;
  };

  const cleanTiptapTables = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Helper function to clean tables
    const processTable = (table) => {
      if (!table) return;

      // Remove colgroups
      const colGroup = table.querySelector('colgroup');
      if (colGroup) colGroup.remove();

      // Remove specific styles and attributes from table elements
      table.removeAttribute('style');

      const rows = table.querySelectorAll('tr');
      rows.forEach((row) => {
        const cells = row.querySelectorAll('th, td');
        cells.forEach((cell) => {
          cell.removeAttribute('colspan');
          cell.removeAttribute('rowspan');
          cell.removeAttribute('colwidth');
          cell.removeAttribute('class');
          cell.removeAttribute('style');
          cell.removeAttribute('data-placeholder');

          // Unwrap <p> inside cells
          const paragraph = cell.querySelector('p');
          if (paragraph) {
            cell.innerHTML = paragraph.innerHTML;
          }

          // Remove <br> elements with "ProseMirror-trailingBreak"
          const trailingBreak = cell.querySelector(
            'br.ProseMirror-trailingBreak'
          );
          if (trailingBreak) {
            trailingBreak.remove();
          }

          // Remove any remaining widgets or handles
          const widget = cell.querySelector('.ProseMirror-widget');
          if (widget) {
            widget.remove();
          }
        });
      });
    };

    // Process tables inside `.tableWrapper`
    const tableWrappers = doc.querySelectorAll('.tableWrapper');
    tableWrappers.forEach((wrapper) => {
      const table = wrapper.querySelector('table');
      if (table) {
        wrapper.replaceWith(table); // Replace wrapper with table
        processTable(table);
      }
    });

    // Process standalone tables (not inside `.tableWrapper`)
    const standaloneTables = doc.querySelectorAll(
      'table:not(.tableWrapper table)'
    );
    standaloneTables.forEach((table) => {
      processTable(table);
    });

    return doc.body.innerHTML;
  };

  const copyToClipboard = (id) => {
    let content = '';
    if (id === 'content_export') {
      content = cleanTiptapTables(contentEditorRef.current.getHTML());
    } else if (id === 'html_page') {
      content = document.getElementById(id).textContent;
    } else {
      if (document.getElementById(id).innerHTML) {
        content = document.getElementById(id).innerHTML;
      } else {
        content = document.getElementById(id).value;
      }
    }
    console.log(content)
    navigator.clipboard.writeText(content);
    setCopied(id);
    setTimeout(() => setCopied(null), 1500);
  };

  function generateHtmlPage(values) {
    const {
      h1_title,
      introduction,
      content,
      conclusion,
      meta_title,
      meta_description,
    } = values;

    const fullContent = `${introduction}${content}${conclusion}`;

    const rawHtml = `
<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${meta_title || 'Untitled Page'}</title>
    <meta name="description" content="${meta_description || ''}">
</head>
<body>
    <header>
        <h1>${h1_title || 'Default Title'}</h1>
    </header>
    <main>
        ${cleanTiptapTables(fullContent)}
    </main>
</body>
</html>
`;

    return html(rawHtml, { indent_size: 2 });
  }

  const generateWordDocument = async () => {
    const seo_request = formikProps.values.seo_request;
    const url_slug = formikProps.values.url_slug;
    const h1_title = formikProps.values.h1_title;
    const introduction = formikProps.values.introduction;
    const content = formikProps.values.content;
    const conclusion = formikProps.values.conclusion;
    const meta_title = formikProps.values.meta_title;
    const meta_description = formikProps.values.meta_description;

    // Create beautified HTML content
    const rawHtml = `${introduction}${content}${conclusion}`;
    const beautifiedHtml = html(cleanTiptapTables(rawHtml), { indent_size: 2 });

    const htmlContentHtml = `
<h1>${h1_title}</h1>
${beautifiedHtml}
    `;

    const metaDatas = `
        <p><strong>Méta titre:</strong> <code>&lt;title&gt;${meta_title}&lt;/title&gt;</code></p>
        <p><strong>Méta description:</strong> <code>&lt;meta name="description" content="${meta_description}"&gt;</code></p>
    `;

    try {
      const passed_content = {
        urlSlug: url_slug,
        metaTitle: meta_title,
        metaDescription: meta_description,
        h1_title: h1_title,
        introduction: introduction,
        content: content,
        conclusion: conclusion,
        htmlContentHtml: htmlContentHtml,
      };

      const blob = await convertHtmlToDocx(passed_content).unwrap();

      // Create a downloadable link for the Blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        seo_request?.replaceAll(' ', '-').concat('.docx') || 'mon-article.docx'
      );
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error('Error details:', error); // Log the error for debugging
      toast.error(
        t(
          'error-during-document-creation',
          'Erreur lors de la création du document : '
        ) + error
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-[9999]"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in ${
              modalType === 'default'
                ? 'sm:w-full sm:max-w-xl'
                : 'sm:w-full sm:max-w-3xl'
            } sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
          >
            <div>
              <DialogTitle
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                <p
                  className={`text-2xl font-semibold leading-6 flex items-center ${
                    modalType === 'default' ? 'gap-3' : 'gap-1'
                  }`}
                >
                  <FaFileExport fill="black" />
                  <span>
                    {modalType === 'default' &&
                      t('export-blog-post', "Exporter l'article de blog")}
                  </span>
                  <span>
                    {modalType === 'CMS' &&
                      t('export-to-cms', 'Exporter vers un CMS')}
                  </span>
                  <span>
                    {modalType === 'HTML' &&
                      t('export-in-html', 'Exporter au format HTML')}
                  </span>
                  <span>
                    {modalType === 'DOCS' &&
                      t('export-in-docs', 'Exporter en .docs')}
                  </span>
                </p>
              </DialogTitle>

              {modalType === 'default' && (
                <div className="mt-4 space-y-3">
                  <button
                    onClick={() => setModalType('CMS')}
                    className="w-full py-3 px-4 bg-blue-50 text-white rounded-lg shadow hover:bg-blue-200"
                  >
                    {t('export-to-cms', 'Exporter vers un CMS')}
                  </button>

                  <button
                    onClick={() => setModalType('HTML')}
                    className="w-full py-3 px-4 bg-blue-50 text-white rounded-lg shadow hover:bg-blue-200"
                  >
                    {t('export-in-html', 'Exporter au format HTML')}
                  </button>

                  <button
                    onClick={() => setModalType('DOCS')}
                    className="w-full py-3 px-4 bg-blue-50 text-white rounded-lg shadow hover:bg-blue-200"
                  >
                    {t('export-in-docs', 'Exporter en .docs')}
                  </button>
                </div>
              )}

              {modalType === 'CMS' && (
                <div className="mt-4 space-y-3">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-medium">Slug d'URL</p>
                      <div className="relative">
                        <button
                          onClick={() => copyToClipboard('url_slug_export')}
                          className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                        >
                          Copier
                        </button>
                        {copied === 'url_slug_export' && (
                          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                            <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                              {t('Copied!', 'Copié !')}
                            </span>
                            <div
                              className="w-3 h-2 bg-slate-600/80"
                              style={{
                                clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      id="url_slug_export"
                      readOnly
                      className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 resize-none focus:outline-none focus:ring-0 focus:border-slate-400"
                      value={
                        formikProps.values['url_slug'] === ''
                          ? t(
                              'Your-url-slug-here ... ',
                              "Votre slug d'URL ici ..."
                            )
                          : formikProps.values['url_slug']
                      }
                    />
                  </div>

                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-medium">Titre</p>
                      <div className="relative">
                        <button
                          onClick={() => copyToClipboard('h1_title_export')}
                          className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                        >
                          Copier
                        </button>
                        {copied === 'h1_title_export' && (
                          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                            <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                              {t('Copied!', 'Copié !')}
                            </span>
                            <div
                              className="w-3 h-2 bg-slate-600/80"
                              style={{
                                clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <textarea
                      id="h1_title_export"
                      readOnly
                      className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 text-[1.5em] font-semibold resize-none focus:outline-none focus:ring-0 focus:border-slate-400"
                    >
                      {formikProps.values['h1_title'] === ''
                        ? t('Your title here ... ', 'Votre titre ici ...')
                        : formikProps.values['h1_title']}
                    </textarea>
                  </div>

                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-medium">Contenu</p>
                      <div className="relative">
                        <button
                          onClick={() => copyToClipboard('content_export')}
                          className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                        >
                          Copier
                        </button>
                        {copied === 'content_export' && (
                          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                            <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                              {t('Copied!', 'Copié !')}
                            </span>
                            <div
                              className="w-3 h-2 bg-slate-600/80"
                              style={{
                                clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="rewrite-editor">
                      <Editor
                        content={
                          formikProps.values['introduction'] +
                          formikProps.values['content'] +
                          formikProps.values['conclusion']
                        }
                      />
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <div className="flex justify-between items-center mb-2">
                        <p className="font-medium">Méta-titre</p>
                        <div className="relative">
                          <button
                            onClick={() => copyToClipboard('meta_title_export')}
                            className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                          >
                            Copier
                          </button>
                          {copied === 'meta_title_export' && (
                            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                              <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                                {t('Copied!', 'Copié !')}
                              </span>
                              <div
                                className="w-3 h-2 bg-slate-600/80"
                                style={{
                                  clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <textarea
                        id="meta_title_export"
                        readOnly
                        className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 font-semibold resize-none focus:outline-none focus:ring-0 focus:border-slate-400"
                      >
                        {formikProps.values['meta_title'] === ''
                          ? t('Your-meta-title', 'Votre méta titre')
                          : formikProps.values['meta_title']}
                      </textarea>
                    </div>

                    <div className="w-1/2">
                      <div className="flex justify-between items-center mb-2">
                        <p className="font-medium">Méta-description</p>
                        <div className="relative">
                          <button
                            onClick={() =>
                              copyToClipboard('meta_description_export')
                            }
                            className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                          >
                            Copier
                          </button>
                          {copied === 'meta_description_export' && (
                            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                              <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                                {t('Copied!', 'Copié !')}
                              </span>
                              <div
                                className="w-3 h-2 bg-slate-600/80"
                                style={{
                                  clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <textarea
                        id="meta_description_export"
                        readOnly
                        className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 font-semibold resize-none focus:outline-none focus:ring-0 focus:border-slate-400"
                      >
                        {formikProps.values['meta_description'] === ''
                          ? t('Your-meta-description', 'Votre méta description')
                          : formikProps.values['meta_description']}
                      </textarea>
                    </div>
                  </div>
                </div>
              )}

              {modalType === 'HTML' && (
                <div className="mt-4 space-y-3">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-medium">Slug d'URL</p>
                      <div className="relative">
                        <button
                          onClick={() => copyToClipboard('url_slug_export')}
                          className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                        >
                          Copier
                        </button>
                        {copied === 'url_slug_export' && (
                          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                            <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                              {t('Copied!', 'Copié !')}
                            </span>
                            <div
                              className="w-3 h-2 bg-slate-600/80"
                              style={{
                                clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      id="url_slug_export"
                      readOnly
                      className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 resize-none focus:outline-none focus:ring-0 focus:border-slate-400"
                      value={
                        formikProps.values['url_slug'] === ''
                          ? t(
                              'Your-url-slug-here ... ',
                              "Votre slug d'URL ici ..."
                            )
                          : formikProps.values['url_slug']
                      }
                    />
                  </div>
                  <div className="space-y-3">
                    <div className="flex justify-between items-center">
                      <p className="font-medium">Page HTML</p>
                      <div className="relative">
                        <button
                          onClick={() => copyToClipboard('html_page')}
                          className="bg-gray_local-400 hover:bg-gray_local-400/70 text-white text-sm font-light px-3 py-1 rounded flex items-center"
                        >
                          Copier
                        </button>
                        {copied === 'html_page' && (
                          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex flex-col items-center">
                            <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                              {t('Copied!', 'Copié !')}
                            </span>
                            <div
                              className="w-3 h-2 bg-slate-600/80"
                              style={{
                                clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)',
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <textarea
                      id="html_page"
                      readOnly
                      value={generateHtmlPage(formikProps.values)}
                      rows={12}
                      className="w-full py-[5px] px-[10px] rounded-[0.4rem] bg-white border-[0.5px] border-slate-400 resize-none focus:outline-none focus:ring-0 focus:border-slate-400 max-h-[300px] overflow-y-auto"
                    ></textarea>
                  </div>
                </div>
              )}

              {modalType === 'DOCS' && (
                <div className="mt-4 space-y-3">
                  <button
                    onClick={() => generateWordDocument()}
                    className="w-full gap-1 py-3 px-4 bg-blue-50 text-white rounded-lg shadow hover:bg-blue-200 flex items-center justify-center"
                  >
                    <FaFileDownload fill="white" />
                    Télécharger le fichier .docs
                  </button>
                </div>
              )}

              {modalType !== 'default' && (
                <div className="mt-4 space-y-3">
                  <button
                    onClick={() => setModalType('default')}
                    className="w-full py-3 px-4 bg-gray_local-400 text-white rounded-lg shadow hover:bg-gray_local-400/70"
                  >
                    Annuler
                  </button>
                </div>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ExportModal;
