import * as Yup from "yup";

export const InitialValues = {
  topic: "",
  target_seo: "",
  main_section: 1,
  total_words: 1,
  style_id:null,
  company_id:null,
  product_id:null,
  post_h1: "",
  postoutline_id:"",
  intro_length:"",
  conculsion_length:"",
  post_transition:"",
  url_link:"",
  post_introduction:"",
  post_conclusion:"",
  meta_title:"",
  meta_description:"",
  nb_meta_description:1,
  nb_meta_title:1,
  nb_post_url_slug:1,
  nb_post_h1_tag:1,
  nb_post_outline:1,
  post_outline:"",
  outline_main_part:1,
};

export const DefaultPostEditor = {
  subject: "",
  seo_request: "",
  nb_words: 1500,

  nb_parts: 3,
  outline: "",
  outline_rewrite: "",

  url_path: "",
  nb_url_slug: 3,
  url_slug_ideas: [],
  url_slug: "",

  nb_h1_title: 3,
  h1_title_ideas: [],
  h1_title: "",

  introduction_length: "courte",
  nb_introduction: 3,
  introduction_ideas: [],
  introduction: "",

  content: "",
  content_rewrite: {},

  conclusion_length: "courte",
  transition_article: "",
  nb_conclusion: 3,
  conclusion_ideas: [],
  conclusion: "",

  nb_meta_title: 3,
  meta_title_ideas: [],
  meta_title: "",
  nb_meta_description: 3,
  meta_description_ideas: [],
  meta_description: ""
}; 

export const Schema = Yup.object().shape({
  topic: Yup.string().max(500, "Topic can max of 500 words").required("Topic is required"),
  target_seo: Yup.string().max(60, "Target seo  must be at most 50 characters").required(),
  post_outline: Yup.string().required(),
  outline_main_part:Yup.number().positive("Number must be positive").min(0,'number can be minmum 0'),
  main_section: Yup.number().positive("Number must be positive").min(0,'number can be minmum 0'),
  total_words: Yup.number().positive("Number must be positive"),
  post_h1: Yup.string(),
  post_introduction: Yup.string(),

  input_nb_outputs:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive").required("Generation is required"),
  nb_meta_description:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive"),
  nb_meta_title:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive"),
  nb_post_url_slug:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive"),
  nb_post_h1_tag:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive"),
  nb_post_outline:Yup.number().min(0,'Number of outputs cannot be negative').positive("Number must be positive"),
});
