import { Box, Grid, Switch, Typography } from "@mui/material";
import { FieldErrorMessage } from "../../../others"

const GlobalChoiceField = ({ formikProps, text, name, isRequired, isReadOnly=false }) => {

    return (
        <Box pt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box textAlign={"start"} display={"flex"}>
                    <Box>
                        <Switch
                            checked={formikProps.values.is_h1_tag ? true : false}
                            onChange={formikProps.handleChange}
                            name={name}
                            required={isRequired ? true : undefined}
                            color="primary"
                            disabled={isReadOnly}
                        />
                    </Box>
                    <Box pt={1}>
                        <Typography>
                            { text }
                        </Typography>
                    </Box>
                </Box>

                <FieldErrorMessage name={name} />

            </Grid>
        </Box>
    )
}

export default GlobalChoiceField;