import { Box, Grid, Modal, Button } from '@mui/material';
import MainEditor from './MainEditor';
import { useEffect, useRef } from 'react';

const RewriteModal = ({ 
    rewriteOpen, 
    handleRewriteOpen, 
    handleRewriteClose,
    mainOutput,
    mainOutputHandler,
    rewritenOutput,
    rewritenOutputHandler,
    placeholder,
    customClasses,
    customExtensions,
    showToolbar,
    toolbarButtons,
    isReadOnly = false
}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: "80vh",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        flexDirection: 'column',
        overflow: "auto"
    };
    const divRef2 = useRef(null);
    useEffect(()=>{
       
        if(divRef2?.current){
            const offsetBottom = divRef2.current?.offsetTop + divRef2.current?.offsetHeight;
            window.scrollTo({ top: offsetBottom });
        divRef2?.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest'
            })}
      },[rewritenOutput])
    return (
        <Box>
            <Button variant="contained" onClick={handleRewriteOpen} disabled={isReadOnly}>
                Voir la version réécrite
            </Button>

            <Modal
                open={rewriteOpen}
                onClose={handleRewriteClose}
                id="rewrite-modal"
                title={'Réécriture'}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <h3>Contenu actuel</h3>
                            <Box mt={2}>
                                <MainEditor 
                                    content={mainOutput}
                                    handleDataChange={mainOutputHandler}
                                    placeholder={placeholder}
                                    customClasses={customClasses}
                                    customExtensions={customExtensions}
                                    showToolbar={showToolbar}
                                    toolbarButtons={toolbarButtons}
                                    isReadOnly={isReadOnly}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={5} sm={5} xs={12}>
                            <h3>Contenu réécrit</h3>
                            <Box mt={2}>
                                <MainEditor
                                    content={rewritenOutput}
                                    handleDataChange={rewritenOutputHandler}
                                    placeholder="Votre contenu réécrit ..."
                                    customClasses={customClasses}
                                    customExtensions={customExtensions}
                                    showToolbar={showToolbar}
                                    toolbarButtons={toolbarButtons}
                                    isReadOnly={isReadOnly}
                                />
                                <div ref={divRef2}></div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
};

export default RewriteModal;