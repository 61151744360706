import { Node } from '@tiptap/core';

// Define the custom document node that only allows a single H1 node
const CustomDocument = Node.create({
  name: 'doc',
  topNode: true,
  content: 'heading',
});

// Define a custom heading node that restricts the level to 1
const CustomHeading = Node.create({
  name: 'heading',
  addOptions() {
    return {
      levels: [1],
    };
  },
  content: 'inline*',
  group: 'block',
  defining: true,
  addAttributes() {
    return {
      level: {
        default: 1,
      },
    };
  },
  parseHTML() {
    return [
      { tag: 'h1' },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return ['h1', HTMLAttributes, 0];
  },
  addCommands() {
    return {
      setHeading: () => ({ commands }) => {
        return commands.setNode('heading', { level: 1 });
      },
    };
  },
});

export {CustomHeading, CustomDocument};