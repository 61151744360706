import { useTranslation } from "react-i18next";
import { MdOutlineQueryStats } from "react-icons/md";
import { Box } from "@mui/material";

const LaunchAnalysisBtn = ({ userInfo, disabled, formikProps}) => {
    const { t } = useTranslation();
    
    return (
        <Box sx={{ p: 2, mb: 10 }}>
            <p className="text-sm mb-2 font-semibold">
                {t("Launch the SEO optimization for the request", "Lancer l'optimisation SEO pour la requête")}{" "}
                <span className="text-sm text-blue-500">
                    {formikProps.values.seo_request === ""
                    ? "REQUEST"
                    : formikProps.values.seo_request}
                </span>{" "}
                ?
            </p>

            <div className="relative flex justify-center items-center group">
                <button
                    className="text-sm hover:bg-blue-200 *: bg-blue-100 text-white disabled:bg-slate-200 disabled:text-slate-400 font-light px-5 py-[6px] rounded flex items-center"
                    disabled={disabled}
                >
                    <MdOutlineQueryStats /> {t(" Launch-analysis", " Lancer l'analyse SEO")}
                </button>

                {/* Tooltip on hover */}
                {disabled &&
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 hidden group-hover:flex flex-col items-center">
                        <div className="w-3 h-2 bg-slate-600/80" style={{clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)"}}></div>
                        <span className="bg-slate-600/80 text-white text-sm rounded-md py-1 px-2 min-w-36 text-center shadow-lg">
                        {t("Soon-available", "Bientôt disponible")}
                        </span>
                    </div>
                }
            </div>
        </Box>
        // <Button
        //     startIcon={<MdOutlineQueryStats />}
        //     variant="contained"
        //     sx={{ textTransform: "none", display: "flex", m: "auto" }}
        //     disabled={true}
        // >
            
        // </Button>
    )
}

export default LaunchAnalysisBtn;