import './Tiptap.scss';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useState } from 'react';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import MainEditor from './MainEditor';
import Document from '@tiptap/extension-document';
import IntroZone from './Zones/IntroZone/Extension';
import ConclusionZone from './Zones/ConclusionZone/Extension';
import ContentZone from './Zones/ContentZone/Extension';
import H1Zone from './Zones/TopH1Zone/Extension';
import H2Zone, { H2 } from './Zones/HeadingZones/H2Zone/Extension';
import H4Zone from './Zones/HeadingZones/H4Zone/Extension';
import H5Zone from './Zones/HeadingZones/H5Zone/Extension';
import H6Zone from './Zones/HeadingZones/H6Zone/Extension';
import H3Zone from './Zones/HeadingZones/H3Zone/Extension';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Menu } from '@mui/icons-material';
import MobileSidePanel from './SidePanel/MobileSidePanel';
import DesktopSidePanel from './SidePanel/SidePanel';

export const LIMIT = 20000;

const CustomDocument = Document.extend({
  content: 'h1zone introzone contentzone conclusionzone ',
});

export const extensions = [
  CustomDocument,

  H1Zone,
  // H2Zone,
  // H3Zone,
  // H4Zone,
  // H5Zone,
  // H6Zone,

  IntroZone,
  ContentZone,
  ConclusionZone,

  Placeholder.configure({
    showOnlyWhenEditable: true,
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: ({ node }) => {
      return '';
    },
  }),

  Underline,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    document: false,
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    defaultAlignment: 'left',
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  Table.configure({
    resizable: true,
    allowTableNodeSelection: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  CharacterCount.configure({
    limit: LIMIT,
  }),
];

const content = ``;

export default () => {
  return (
    <div className="tiptap-container-editor">
      <MainEditor content={content} extensions={extensions} />
      {/* Side panel is now in MainEditor */}
    </div>
  );
};
