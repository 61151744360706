import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Modal, Button, Typography } from '@mui/material';
import axios from 'axios';

const ErrorLogs = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);
  const token = useSelector((state) => state?.auth?.accessToken);

  const handleFetchErrorLogs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/logs-error`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob', // Ensure the response is handled as a file
      });

      // Handle the file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'error_logs.csv'); // Adjust the filename as needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSuccess(true);
      setMessage('CSV file downloaded successfully.');
    } catch (error) {
      console.error('Error fetching data:', error);
      setSuccess(false);
      setMessage('Failed to download CSV file.');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>{t("error-logs", "Logs d'erreurs")} | BotBlogR</title>
      </Helmet>

      <h1>Error Logs</h1>
      <Button variant="contained" color="primary" onClick={handleFetchErrorLogs}>
        Fetch Error Logs
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '100px' }}>
          <Typography id="modal-title" variant="h6" component="h2">
            {success ? 'Success' : 'Error'}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <Button onClick={handleClose} color="primary" style={{ marginTop: '20px' }}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorLogs;
