import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, ENDPOINTS } from '../../shared/constants/urls';
import handleLogout from '../logout';
import { store } from '../store';
import { jwtDecode } from 'jwt-decode';
import { refreshAccessTokenSuccess } from './authSlice';

const getToken = () => {
  return store.getState().auth.accessToken;
};

// Base query function that handles token refresh
const baseQueryWithReauth = async (args, api, extraOptions) => {
  const { requiresAuth = true } = extraOptions;

  const isTokenExpired = (token) => {
    if (!token) return true;

    try {
      const decodedToken = jwtDecode(token);
      const expirationBuffer = 10;
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp - currentTime < expirationBuffer;
    } catch (error) {
      return false;
    }
  };

  if (requiresAuth) {
    const token = getToken();

    if (isTokenExpired(token)) {

      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh',
            method: 'POST',
            credentials: 'include',
          },
          api,
          extraOptions
        );

        if (refreshResult.data.status === 200) {
          api.dispatch(
            refreshAccessTokenSuccess({
              accessToken: refreshResult.data.access_token,
            })
          );
          args.headers = args.headers || {};
          args.headers[
            'Authorization'
          ] = `Bearer ${refreshResult.data.access_token}`;
        }
      } catch (error) {
        handleLogout(api.dispatch);
      }
    } else {
      args.headers = args.headers || {};
      args.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  // Detect binary response and handle it
  args.responseHandler = (response) => {
    const contentType = response.headers.get('content-type');
    if (
      contentType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return response.blob(); // Return Blob directly for binary content
    }
    return response.json(); // Default to JSON for other responses
  };

  return baseQuery(args, api, extraOptions);
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL.BASE_URL,
});

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Features',
    'Writing',
    'Company',
    'Product',
    'post_outline',
    'LexicalField',
    'Synonyms',
    'Semantical',
  ],
  endpoints: (builder) => ({}),
});
