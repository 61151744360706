import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate=useNavigate()
  const userInfo = useSelector((state) => state?.auth).userInfo;

  return (
    <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'60vh'}}>
      <Helmet>
        <title>{t("page-not-found", "Page non trouvée")} | BotBlogR</title>
        <meta name="description" content="Vision générale de votre compte et de vos activités." />
      </Helmet>

      <Typography fontSize={{lg:32}}>{t("page-not-found", "Page non trouvée")}</Typography>
      <Box pt={2}>
      {userInfo ?
        <Button onClick={()=>navigate('/dashboard')} variant='contained'>{t("back-to-dashboard", "Back To Dashboard")}</Button>
        :
        <Button onClick={()=>navigate('/login')} variant='contained'>{t("back-to-login", "Back To Login")}</Button>
      }
      </Box>
    </Box>
  )
}

export default NotFound
