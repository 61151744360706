import generateYupSchema from "../../../../shared/utils/features/CreateFeatureValidationSchema";

const InitialValues = {
  style_id: "",
  company_id: "",
  product_id: "",
  subject: "",
  seo_request: "",
  nb_words: 1500,
  is_h1_tag: false,
  nb_parts: 3,
  id: ""
};

const validationConfig = {
  subject: {
    type: "string",
    max: 2000,
    required: true,
    requiredMessage: "Le sujet est requis",
    maxMessage: "Le sujet doit faire un maximum de 2000 caractères"
  },
  seo_request: {
    type: "string",
    max: 100,
    required: true,
    requiredMessage: "La requête SEO cible est requise",
    maxMessage: "La requête SEO cible doit faire un maximum de 100 caractères"
  },
  nb_parts: {
    type: "number",
    min: 2,
    max: 100,
    required: true,
    minMessage: "Le nombre de h2 minimum est 2",
    maxMessage: "Le nombre de h2 maximum est 100",
    requiredMessage: "Le nombre de h2 est requis"
  },
  nb_words: {
    type: "number",
    min: 200,
    max: 50000,
    required: true,
    requiredMessage: "Le nombre de mots est requis",
    minMessage: "Le nombre de mots minimum est 200",
    maxMessage: "Le nombre de mots maximum est 50000"
  },
  is_h1_tag: {
    type: "boolean",
    required: true,
    requiredMessage: "Ce choix est requis"
  }
};

const Schema = generateYupSchema(validationConfig);

export {InitialValues, validationConfig, Schema};