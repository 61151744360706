import { mergeAttributes, Node } from '@tiptap/core';
import './styles.scss';

const H1Zone = Node.create({
  name: 'h1zone',
  group: 'block',
  content: 'heading',
  marks: '',
  draggable: false,

  parseHTML() {
    return [
      {
        tag: 'div[id=h1zone]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { id: 'h1zone' }), 0];
  },
});

export default H1Zone;
