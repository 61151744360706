import React from 'react';
import { Field } from "formik";
import { ClipLoader } from "react-spinners";
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FieldErrorMessage } from '../../others';

const InputFormZone = ({
    selectText, 
    generateText, 
    rewriteText, 
    formikProps, 
    contents,
    isTable,
    isNbOutputs,
    outputs=null,
    mainOutput=null,
    tableOutput=null,
    handleGetSingle, 
    handleCreateNew, 
    setDeleteButton, 
    isLoading, 
    validationConfig,
    generationVariablesPanel,
    children,
    isReadOnly=false
}) => {
  return (
    <>
        <Typography
            fontSize={{ lg: 16, md: 16, sm: 16, xs: 14 }}
            fontWeight={500}
        >
            {selectText}
        </Typography>

        <Box display={"flex"}>
            <Field
                as={Select}
                name="content_select"
                onChange={(e) => {
                    if (e.target.value) {
                        handleGetSingle(e.target.value);
                        setDeleteButton(true);
                    } else {
                        handleCreateNew(formikProps)
                    }
                }}
                value={formikProps.values.id}
                displayEmpty={true}
                sx={{ width: "100% !important", textAlign: "start" }}
            >
                <MenuItem value="">--Créer un nouveau contenu--</MenuItem>
                {contents?.data?.map((w) => {
                    return (
                    <MenuItem value={w.id} key={"content_"+w.id}>
                        {w.obj_name}
                    </MenuItem>
                    );
                })}
            </Field>

            <Button
                variant="contained"
                color="info"
                onClick={() => handleCreateNew(formikProps)}
                sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
            >
                Créer un nouveau
            </Button>
        </Box>

        {generationVariablesPanel}

        <Grid id="field-list" container spacing={2}>
            <Grid item md={12} xs={12} lg={12} sm={12}>
                <form onSubmit={formikProps.handleSubmit}>
                    {children}
                    <Box display="flex" justifyContent={"flex-start"} pt={1}>
                        {validationConfig.hasOwnProperty('nb_outputs') && (
                        <Box>
                            <TextField
                                variant="outlined"
                                type="number"
                                value={formikProps.values.nb_outputs}
                                name="nb_outputs"
                                error={
                                    formikProps.touched.nb_outputs &&
                                    Boolean(formikProps.errors.nb_outputs)
                                }
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                inputProps={{ min: validationConfig.nb_outputs.min, max: validationConfig.nb_outputs.max }}
                                sx={{
                                    width: "100% !important",
                                    borderRadius: "20px !important"
                                }}
                            />
                        </Box>
                        )}

                        <Button
                            variant="contained"
                            onClick={formikProps.handleSubmit}
                            disabled={isLoading || isReadOnly}
                            sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: "700",
                                marginLeft: validationConfig.hasOwnProperty('nb_outputs') ? "8px" : "0px",
                                marginRight: "8px",
                            }}
                            >
                            {" "}
                            {isLoading ? (
                                <ClipLoader size={25} color="white" />
                            ) : isTable ?
                                (tableOutput.length > 0 ?
                                    (rewriteText)
                                    :
                                    (generateText)
                                )
                                :
                                (isNbOutputs ? 
                                    (outputs.length > 0 ? 
                                        (rewriteText) 
                                        : 
                                        (generateText)
                                    )
                                    :
                                    (mainOutput ? 
                                        (rewriteText)
                                        : 
                                        (generateText)
                                    )
                                )
                            }
                        </Button>
                    </Box>
                    <FieldErrorMessage name="nb_outputs" />
                </form>
            </Grid>
        </Grid>

    </>
  );
};

export default InputFormZone;