import React from "react";
import { useTranslation } from "react-i18next";

const UserGuideStep = ({ data }) => {
  const { t } = useTranslation();
  const { step, title, link, subtitle, text } = data;
  
  return (
    <>
      <div className="max-w-[950px] mx-auto pt-4">
        <div className="grid xl:grid-cols-5 col-span-5 md:gap-5 relative">
          <div className="xl:col-span-5 col-span-5 ">
            <div className="rounded-lg pt-1 pb-0  h-full group">
              <div className="px-4">
                <div
                  className={`px-2 py-1 my-3 mt-0 relative text-black}`}
                  key={step}
                >
                  <h2 className="font-semibold text-2xl">
                    {title}
                  </h2>
                </div>
                <div className="col-span-5">
                <div className="w-full" style={{ height: "385px" }}>
                  <iframe
                    src={link}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full rounded-md"
                  ></iframe>
                  </div>
                </div>
                <p
                  className="text-base my-5 min-h-[71px]"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserGuideStep;
