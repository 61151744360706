import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activePanel: null,
  currentZone: {
    zone: null,
    isHeadingZone: null,
  },
};

const panelSlice = createSlice({
  name: 'panelSlice',
  initialState,
  reducers: {
    setEditor: (state, action) => {
      state.editor = action.payload;
    },
    setActivePanel: (state, action) => {
      state.activePanel = action.payload;
    },
    setCurrentZone: (state, action) => {
      state.currentZone = { ...action.payload };
    },
  },
});

export const { setCurrentZone, setActivePanel } = panelSlice.actions;

export default panelSlice.reducer;
