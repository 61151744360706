import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { useState } from 'react';

const InsertUrlModal = ({ linkAttribute, tempLink, editor, closeModal }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(tempLink);
  const [check, setCheck] = useState({
    newTab: linkAttribute.target === '_blank',
    noFollow: linkAttribute.rel === 'nofollow',
  });

  const handleClick = (e) => {
    e.preventDefault();
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({
        href: value,
        target: check.newTab ? '_blank' : '_self',
        rel: check.noFollow ? 'nofollow' : 'follow',
      })
      .run();
    closeModal();
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        //overflow: { md: 'unset', xs: 'scroll' },
        height: '100vh',
        width: { md: 'calc(100vw - 350px)', xs: '100%' },
        display: 'flex',
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 99999,
      }}
    >
      <div className="m-auto bg-white rounded-xl w-[80%] max-w-[500px] z-[99999999999]">
        <form onSubmit={handleClick}>
          <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
            <p>Modifier le lien</p>
            <Close
              onClick={closeModal}
              className="cursor-pointer"
              sx={{ fontSize: 32 }}
            />
          </div>

          <div className="p-4 space-y-4">
            <TextField
              fullWidth
              required
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="small"
            />
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.noFollow}
                      onChange={(e) => {
                        setCheck({
                          ...check,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      name="noFollow"
                    />
                  }
                  label="nofollow ?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheck({
                          ...check,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      checked={check.newTab}
                      name="newTab"
                    />
                  }
                  label="Open in a new tab ?"
                />
              </FormGroup>
            </FormControl>
          </div>

          <div className="p-4 flex flex-row-reverse">
            <Button
              type="submit"
              variant="contained"
              sx={{ textTransform: 'none' }}
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default InsertUrlModal;
