import { useTranslation } from 'react-i18next';
import React from "react";
import pen from "../../../assets/images/pen.svg";
import { GVModalTemplate } from "./index";

const CompanyModal = ({ 
  open, 
  handleClose,
  formikProps,
  companyStyles
}) => {
  const { t } = useTranslation();
 
  return (
    <GVModalTemplate
      open={open}
      handleClose={handleClose}
      formikProps={formikProps}
      generationVariablesList={companyStyles}
      fieldName="company_id"
      modalTitle={t("Company-website", "Entreprise / Site Web")}
      modalIcon={pen}
      modalDescription={t("company-website-description", "Fournissez davantage de contexte sur votre site web ou votre entreprise afin de personnaliser le contenu généré et de l'adapter à votre public cible.")}
      noSelectionText={t("No-company-selected","Aucune entreprise sélectionnée")}
    />
  );
};

export default CompanyModal;
