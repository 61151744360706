import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomLoader } from "../../../shared/components/others";
import { toast } from "react-toastify";
import { useAddPaymentMethodMutation } from "../../../redux/slices/paymentApiSlice";

const SetupIntentSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = location.search;

  const parseQueryString = (queryString) => {
    return queryString
      .substring(1)
      .split("&")
      .reduce((acc, pair) => {
        const [key, value] = pair.split("=");
        acc[key] = decodeURIComponent(value); // Decode in case of special characters
        return acc;
      }, {});
  };

  const queryParams = parseQueryString(queryString);
  const [addPaymentMethod] = useAddPaymentMethodMutation();

  useEffect(() => {
    const handleSetupIntent = async () => {
      if (queryParams?.setup_intent && queryParams?.redirect_status === "succeeded") {
        try {
          const response = await addPaymentMethod({
            setup_intent_id: queryParams.setup_intent,
          }).unwrap();

          if (response?.status === 201) {
            toast.success(
              t(
                "payment-method-successfully-added",
                "Votre moyen de paiement a bien été ajouté !"
              )
            );
            navigate(`/profile?tab=subscription`);
          } else {
            toast.error(
              t(
                "failed-to-add-payment-method",
                "L'ajout de votre moyen de paiement a échoué"
              )
            );
            navigate(`/profile?tab=subscription`);
          }
        } catch (error) {
          toast.error(
            t(
              "failed-to-add-payment-method",
              "L'ajout de votre moyen de paiement a échoué"
            )
          );
          navigate(`/profile?tab=subscription`);
        }
      } else {
        toast.error(
          t(
            "payment-method-setup-failed",
            "La configuration de votre moyen de paiement a échoué"
          )
        );
        navigate(`/profile?tab=subscription`);
      }
    };

    handleSetupIntent();
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"50vh"}
    >
      <CustomLoader />
    </Box>
  );
};

export default SetupIntentSuccess;