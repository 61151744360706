import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { connectSocket } from '../../../../shared/sockets/socket';
import { useFormikContext } from 'formik';
import DOMPurify from 'dompurify';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ editor, children }) => {
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const [ws, setWs] = useState(null);

  const formikProps = useFormikContext();

  const [openOutlineRewrite, setOpenOutlineRewrite] = useState(false);
  
  const introHeading = useRef("");
  const [openIntroductionModal, setOpenIntroductionModal] = useState(false);
  const [openIntroductionRewrite, setOpenIntroductionRewrite] = useState(false);

  const [partData, setPartData] = useState(null);
  const [openPartModal, setOpenPartModal] = useState(false);
  const [openPartRewrite, setOpenPartRewrite] = useState(false);

  const [customData, setCustomData] = useState(null);
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const concluHeading = useRef("");
  const [openConclusionModal, setOpenConclusionModal] = useState(false);
  const [openConclusionRewrite, setOpenConclusionRewrite] = useState(false);

  const bufferRef = useRef(''); // Buffer to accumulate chunks
  const counter = useRef(0)

  // Add a hook to remove all potential html attributes from rendered elements by gpt to avoid bugs
  DOMPurify.addHook('uponSanitizeElement', (node) => {
    if (node.attributes) {
      [...node.attributes].forEach(attr => node.removeAttribute(attr.name));
    }
  });

  const cleanGeneratedContent = (content) => {
    const cleanedContent = content.replace(/>\s*\n\s*</g, '><');
    let sanitizedHtml = DOMPurify.sanitize(cleanedContent, { USE_PROFILES: { html: true } });

    // Add a default space to empty nested tags (like <td><strong></strong></td>)
    sanitizedHtml = sanitizedHtml.replace(/<(\w+)>(\s*<[^>]+>\s*)<\/\1>/g, (match, outerTag, innerContent) => {
        const filledInnerContent = innerContent.replace(/(<[^>]+>)(\s*)(<\/[^>]+>)/, '$1 $3');
        return `<${outerTag}>${filledInnerContent}</${outerTag}>`;
    });

    // Handle lists with empty content or empty list element
    sanitizedHtml = sanitizedHtml.replace(/<ul>\s*<\/ul>/g, '<ul><li> </li></ul>');
    sanitizedHtml = sanitizedHtml.replace(/<ol>\s*<\/ol>/g, '<ol><li> </li></ol>');
    sanitizedHtml = sanitizedHtml.replace(/<li>(\s*<[^>]+>\s*<\/[^>]+>|\s*)<\/li>/g, '<li> </li>');

    // Handle blockquotes with empty content
    sanitizedHtml = sanitizedHtml.replace(/<blockquote>(\s*<[^>]+>\s*<\/[^>]+>|\s*)<\/blockquote>/g, '<blockquote> </blockquote>');

    // Handle tables with empty content
    sanitizedHtml = sanitizedHtml.replace(/<table>\s*<\/table>/g, '<table><thead><tr><th> </th></tr></thead><tbody><tr><td> </td></tr></tbody></table>');
    sanitizedHtml = sanitizedHtml.replace(/<thead>\s*<\/thead>/g, '<thead><tr><th> </th></tr></thead>');
    sanitizedHtml = sanitizedHtml.replace(/<tbody>\s*<\/tbody>/g, '<tbody><tr><td> </td></tr></tbody>');
    sanitizedHtml = sanitizedHtml.replace(/<tr>\s*<\/tr>/g, (match, offset, string) => {
      const precedingPart = string.slice(0, offset).trimEnd();
      const isFirstTr = precedingPart.endsWith('<thead>') || (precedingPart.endsWith('<tbody>') && !/<thead>/.test(precedingPart));
      return isFirstTr ? '<tr><th> </th></tr>' : '<tr><td> </td></tr>';
    });
    sanitizedHtml = sanitizedHtml.replace(/<(td|th)>\s*<\/\1>/g, '<$1> </$1>');

    // Handle spaces and all that
    sanitizedHtml = sanitizedHtml.replace(/\n\s{2,}/g, '');
    return sanitizedHtml;
  }

    useEffect(() => {
        if (userInfo && !ws) {
            const socket = connectSocket(userInfo.unique_id); // Connect to WebSocket
            setWs(socket);
            console.log("SOCKET CONNECTED");

            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                bufferRef.current = bufferRef.current + data.data
                counter.current = counter.current + 1

                if (data.contentType === "outline") {
                    if (data.isRewrite) {
                        setOpenOutlineRewrite(value=>true);
                        formikProps.setFieldValue('outline_rewrite', bufferRef.current);
                    } else {
                        formikProps.setFieldValue('outline', bufferRef.current);
                        const zone = document.getElementById('introzone');
                    }
                } else if (data.contentType === "introduction") {
                    if (data.isRewrite) {
                        setOpenIntroductionRewrite(value=>true);
                        formikProps.setFieldValue('introduction_rewrite', bufferRef.current);
                    } else {
                        setOpenIntroductionModal(value=>false);
                        const zone = document.getElementById('introzone');
                        if (zone) {
                          zone.innerHTML = introHeading.current + bufferRef.current;
                        }
                    }
                } else if (data.contentType === "part") {
                    if (data.isRewrite) {
                        setOpenPartRewrite(value=>true);
                        setPartData(currentPartData => {
                            formikProps.setFieldValue('content_rewrite', {
                              ...formikProps.values.content_rewrite,
                              [currentPartData?.title]: cleanGeneratedContent(bufferRef.current),
                            });
                            return currentPartData;
                        });
                    } else {
                        setOpenPartModal(value=>false);
                        setPartData(currentPartData => {
                            editor
                            .chain()
                            .focus()
                            .deleteRange({ from: currentPartData.startOfPart, to: currentPartData.endOfPart })
                            .insertContentAt(currentPartData.startOfPart, cleanGeneratedContent(bufferRef.current))
                            .run();

                            const updatedPartData = {
                                ...currentPartData,
                                startOfPart: currentPartData.startOfPart,
                                endOfPart: editor.state.selection.$from.end(2),
                            };

                            return updatedPartData;
                        });
                    }
                } else if (data.contentType === "conclusion") {
                    if (data.isRewrite) {
                        setOpenConclusionRewrite(value=>true);
                        formikProps.setFieldValue('conclusion_rewrite', bufferRef.current);
                    } else {
                        setOpenConclusionModal(value=>false);
                        const zone = document.getElementById('conclusionzone');
                        if (zone) {
                          zone.innerHTML = concluHeading.current + bufferRef.current;
                        }
                    }
                } else if (data.contentType === "custom"){
                    setOpenCustomModal(value=>false);
                    setCustomData(currentCustomData => {
                        editor
                        .chain()
                        .focus()
                        .deleteRange({ from: currentCustomData.startOfCustom, to: currentCustomData.endOfCustom})
                        .insertContentAt(currentCustomData.startOfCustom, cleanGeneratedContent(bufferRef.current))
                        .run();

                        const updatedCustomData = {
                            ...currentCustomData,
                            startOfPart: currentCustomData.startOfCustom,
                            endOfCustom: editor.state.selection.$from.end(2)
                        };
                        return updatedCustomData;
                    });
                }
            };
        }
    }, [userInfo, ws]);

  return (
    <WebSocketContext.Provider value={{ 
        ws,
        openOutlineRewrite,
        setOpenOutlineRewrite,
        openIntroductionModal,
        setOpenIntroductionModal,
        openIntroductionRewrite,
        setOpenIntroductionRewrite,
        partData,
        setPartData,
        openPartModal,
        setOpenPartModal,
        openPartRewrite,
        setOpenPartRewrite,
        customData,
        setCustomData,
        openCustomModal,
        setOpenCustomModal,
        openConclusionModal,
        setOpenConclusionModal,
        openConclusionRewrite,
        setOpenConclusionRewrite,
        introHeading,
        concluHeading,
        bufferRef,
        cleanGeneratedContent
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook for accessing WebSocket context
export const useWebSocket = () => useContext(WebSocketContext);