import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  ListItemIcon,
  MenuItem,
  Popper
} from "@mui/material";
import "./index.css";
import { CaretDownIcon, ColumnsIcon, DeleteIcon, RowsIcon } from "../Icons";

const actions = [
  {
    label: "columns",
    icon: <ColumnsIcon />,
    subMenu: [
      {
        label: "add-column-before",
        action: (editor) => editor.commands.addColumnBefore(),
      },
      {
        label: "add-column-after",
        action: (editor) => editor.commands.addColumnAfter(),
      },
      {
        label: "delete-column",
        action: (editor) => editor.commands.deleteColumn(),
      },
    ],
  },

  {
    label: "rows",
    icon: <RowsIcon />,
    subMenu: [
      {
        label: "add-row-before",
        action: (editor) => editor.commands.addRowBefore(),
      },
      {
        label: "add-row-after",
        action: (editor) => editor.commands.addRowAfter(),
      },
      {
        label: "delete-row",
        action: (editor) => editor.commands.deleteRow(),
      },
    ],
  },
  {
    label: "delete-table",
    icon: <DeleteIcon />,
    action: (editor) => editor.commands.deleteTable(),
  },
];

export default function TablePopover({ editor, editorSection }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [secondaryAnchor, setSecondaryAnchor] = useState(null);

  const getTableElement = () => {
    const { state } = editor;
    const tableNode = state.schema.nodes.table;

    if (!tableNode) return null;

    const $pos = state.selection.$from;

    for (let d = $pos.depth; d >= 0; d--) {
      const node = $pos.node(d);
      if (node && node.type === tableNode) {
        return editor.view.nodeDOM($pos.before(d));
      }
    }

    return null;
  };

  const showPopper = () => {
    if (editor.isActive("table")) {
      const tableElement = getTableElement();
      setAnchorEl(tableElement);
    } else {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    showPopper();
  }, [editor.state.selection.from]);

  const handleClick = (event) => {
    if (!editor.isActive("table")) {
      editor
        .chain()
        .focus()
        .insertTable({ rows: 3, cols: 6, withHeaderRow: true })
        .run();
      return;
    } else {
      showPopper();
    }
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <button
        aria-describedby={id}
        onClick={handleClick}
        className={`${editor.isActive("table") ? "is-active" : ""} toolbar-btn`}
        disabled={editorSection === "title"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-table"
          viewBox="0 0 16 16"
        >
          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
        </svg>
      </button>

      <Popper
        id="simple-menu"
        anchorEl={anchorEl}
        placement="right"
        open={open}
        className="menu"
        sx={{
          zIndex: 9998,
          border: "1px solid #707070",
          borderRadius: "8px", // Ensure rounded corners
          backgroundColor: "#ffffff", // Clean white background
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          overflow: "hidden", // Prevent gray bar or overflow
        }}
      >
        <div style={{ padding: 0 }}> {/* Remove unnecessary padding */}
          {actions.map((el, index) => (
            <div key={index}>
              {el?.subMenu?.length ? (
                <>
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 16px", // Consistent padding for menu items
                      "&:hover": { backgroundColor: "#f5f5f5" }, // Hover effect
                    }}
                    onClick={() =>
                      setSecondaryAnchor(
                        secondaryAnchor === t(el.label) ? null : t(el.label)
                      )
                    }
                  >
                    {el.icon && <ListItemIcon>{el.icon}</ListItemIcon>}
                    {t(el.label)}
                    <ListItemIcon
                      className={`${
                        t(el.label) === secondaryAnchor ? "expanded" : "not-expanded"
                      }`}
                      sx={{
                        marginLeft: "auto",
                        transition: "transform 0.2s",
                        transform:
                          t(el.label) === secondaryAnchor ? "rotate(180deg)" : "none",
                      }}
                    >
                      <CaretDownIcon />
                    </ListItemIcon>
                  </MenuItem>

                  {secondaryAnchor === t(el.label) && (
                    <div className="sub-menu">
                      {el.subMenu.map((item, i) => (
                        <MenuItem
                          key={i}
                          sx={{
                            padding: "8px 16px", // Same padding for submenu
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                          onClick={() => {
                            item.action(editor);
                          }}
                        >
                          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start", // Ensures left alignment of content
                    padding: "8px 16px",
                    borderBottom: index === actions.length - 1 ? "none" : "1px solid #e0e0e0",
                    borderRadius: index === actions.length - 1 ? "0 0 8px 8px" : "0",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                  }}
                  onClick={() => {
                    el.action(editor);
                  }}
                >
                  {el.icon && (
                    <ListItemIcon sx={{ minWidth: "36px" }}> {/* Adjust space for icons */}
                      {el.icon}
                    </ListItemIcon>
                  )}
                  {t(el.label)}
                </MenuItem>
              )}
            </div>
          ))}
        </div>
      </Popper>

    </>
  );
}
