import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetAllFeaturesQuery, useGetFeatureFilterQuery } from "../../redux/slices/featuresApiSlice";
import { useGetAllFavoriteFeaturesIdsQuery } from "../../redux/slices/dashboardApiSlice";
import { CustomLoader } from '../../shared/components/others'
import FeatureCard from "../../shared/components/features/FeatureCard";
import { H1Header, HeaderDescription } from "../../shared/components/others";

const FeaturesList = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || 'all-features';
  const { data: featureFilters, isLoading: featureFiltersLoading } = useGetFeatureFilterQuery();
  const { data: featuresList, isLoading: featuresListLoading } = useGetAllFeaturesQuery();
  const { data: favoriteFeatures, isLoading: favoriteFeaturesLoading, refetch } = useGetAllFavoriteFeaturesIdsQuery();

  if (featuresListLoading || favoriteFeaturesLoading || featureFiltersLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"65vh"}>
        <CustomLoader />
      </Box>
    );
  }

  const currentFilter = type ? featureFilters.data[type] : featureFilters.data["all-features"];
  const filteredFeatures = featuresList.data.filter((feature) =>
    type === "all-features" ? true : feature.type.includes(type)
  );

  return (
    <div className="px-2 pl-6 pr-6">
    
      <div className="py-3">
        <H1Header title={t(currentFilter["h1-tag"], currentFilter["h1-tag"])}/>
        <HeaderDescription
          description={t(currentFilter["description"],currentFilter["description"])}
        />
      </div>

      <div className="grid auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {filteredFeatures.map((feature) => (
          <FeatureCard
            key={feature.feature_id}
            feature={feature}
            isFavorite={favoriteFeatures.data.includes(feature.feature_id)}
            refetch={refetch}
          />
        ))}
      </div>

    </div>
  );
};

export default FeaturesList;