import { mergeAttributes, Node } from '@tiptap/core';
import './styles.scss';
import { Plugin } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';

const ContentZone = Node.create({
  name: 'contentzone',
  group: 'block',
  content: 'block+',
  draggable: false,

  parseHTML() {
    return [
      {
        tag: 'div[id=contentzone]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { id: 'contentzone' }), 0];
  },
});

export default ContentZone;
