import React from "react";
import pen from "../../../assets/images/pen.svg";
import { GVModalTemplate } from "./index";

const ProductModal = ({ 
  open,
  handleClose,
  formikProps,
  productStyles
}) => {
  
  return (
    <GVModalTemplate
      open={open}
      handleClose={handleClose}
      formikProps={formikProps}
      generationVariablesList={productStyles}
      fieldName="product_id"
      modalTitle="Produit"
      modalIcon={pen}
      modalDescription="Is your article intended to sell a product? Include information about it so that the article encourages the reader to make a purchase."
      noSelectionText="Aucun produit sélectionné"
    />
  );
};

export default ProductModal;
