export const BASE_URL = {
  BASE_URL: `${process.env.REACT_APP_API_URL}`,
};
export const SOCKET_BASE_URL = {
  SOCKET_BASE_URL: `${process.env.REACT_APP_SOCKET_URL}`,
};
export const ENDPOINTS = {
  GET_FEATURE_FILTER: "/features/filters/",
  GET_FEATURE:"/features/all/",
  GET_ADMIN_PROFILE: "/admin/profile",
  GET_ALL_USERS: "/admin/users",
  ADMIN_LOGOUT: "/admin/logout",
  GET_PHARMACY_CATEGORIES: "/pharmacy/categories",
  AUTH_REGISTER:'/auth/register/',
  AUTH_LOGIN: "/auth/login/",
  AUTH_FORGOT_PASSWORD:"/auth/forgot-password/",
  AUTH_RESET_PASSWORD:'/auth/reset-password/',
  AUTH_RESEND_EMAIL_VERIFICATION:'/auth/resend-verification-email/',
  PROFILE_WRITING_STYLE:'/generation_variables/style/',
  PROFILE_COMPANY_VARIABLE:'/generation_variables/company/',
  PROFILE_PRODUCT_VARIABLE:'/generation_variables/product/',
  DASHBOARD_GET_STARTED_GUIDE:'/dashboard/guide-info/',
  DASHBOARD_USAGE: '/dashboard/usage/',
  DASHBOARD_FAVORITE_FEATURES: '/dashboard/favorite-features/',
  DASHBOARD_LAST_BLOG_POSTS: '/dashboard/last-blog-posts/',
  DASHBOARD_NOTIFICATIONS: 'dashboard/notifications/',
  AUTH_ME:"/auth/me/",
  AUTH_CHANGE_EMAIL:"/auth/change-email/",
  AUTH_CHANGE_EMAIL_VERIFICATION:"/auth/change-email-verification/",
  AUTH_UPDATE_PROFILE:'/auth/update-profile/',
  AUTH_CHANGE_PASSWORD:'/auth/change-password/',
  AUTH_LINK_OPENAI: '/auth/link-openai/',
  AUTH_UNLINK_OPENAI: '/auth/unlink-openai/',
  AUTH_GOOGLE_LOGIN: '/auth/google/',
  AUTH_GOOGLE_CALLBACK: '/auth/google/callback',
  AUTH_REFRESH_TOKEN: '/auth/refresh/',
  AUTH_LOGOUT: '/logout/',
  // AUTH_SET_PASSWORD:'/auth/set-password/',
  FEATURE_EDITOR:'/contents-crud',
  CONTENT_GENERATION: '/content-generation',
  PAYMENT_SUCCESS:'/payment/success/',
  CURRENT_SUBSCRIPTION:'/payment/current-subscription',
  CHANGE_SUBSCRIPTION:'/payment/change-subscription',
  CREATE_SETUP_INTENT: '/payment/create-setup-intent',
  CANCEL_SUBSCRIPTION:'/payment/cancel-subscription',
  RESTART_SUBSCRIPTION:'/payment/restart-subscription',
  PAYMENT_HISTORY:'/payment/payment-history',
  PAYMENT_METHODS:'/payment/payment-method',
  OTHERS:'/others',
  POST_EDITOR_BLOG:'/blog-post/',
  SOCKET_URL:'/content-generation/ws/'
};