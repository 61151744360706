import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    writingStyle: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.PROFILE_WRITING_STYLE,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    companyVariable: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.PROFILE_COMPANY_VARIABLE,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    productVariable: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.PROFILE_PRODUCT_VARIABLE,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getAllProductVariable: builder.query({
      query: () => ({
        url: ENDPOINTS.PROFILE_PRODUCT_VARIABLE,
      }),
      providesTags: ["Product"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllWritingStyles: builder.query({
      query: () => ({
        url: ENDPOINTS.PROFILE_WRITING_STYLE,
      }),
      providesTags: ["Writing"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllCompanyVariables: builder.query({
      query: () => ({
        url: ENDPOINTS.PROFILE_COMPANY_VARIABLE,
      }),
      providesTags: ["Company"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    deleteWritingStyle: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.PROFILE_WRITING_STYLE}${id}`,
        method: "DELETE",
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteCompanyVariable: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.PROFILE_COMPANY_VARIABLE}${id}`,
        method: "DELETE",
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteProductVariable: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.PROFILE_PRODUCT_VARIABLE}${id}`,
        method: "DELETE",
      }),
      extraOptions: { requiresAuth: true },
    }),
    changeEmail: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.AUTH_CHANGE_EMAIL}`,
        method: "PATCH",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    changeEmailVerification: builder.mutation({
      query: (token) => ({
        url: `${ENDPOINTS.AUTH_CHANGE_EMAIL_VERIFICATION}${token}/`,
        method: "POST"
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.AUTH_UPDATE_PROFILE}`,
        method: "PATCH",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.AUTH_CHANGE_PASSWORD}`,
        method: "PATCH",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleCompany: builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.PROFILE_COMPANY_VARIABLE}${id}`,
        method: "GET",
      }),
      providesTags: ["Company"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateSingleCompany: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.PROFILE_COMPANY_VARIABLE}${data.id}`,
        method: "PUT",
        body:data
      }),
      providesTags: ["Company"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getSingleWritingStyle: builder.mutation({
      query: ({id}) => ({
        url: `${ ENDPOINTS.PROFILE_WRITING_STYLE}${id}`,
        method: "GET",
      }),
      providesTags: ["Writing"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateSingleWritingStyle: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.PROFILE_WRITING_STYLE}${data.id}`,
        method: "PUT",
        body:data
      }),
      providesTags: ["Writing"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getSingleProduct: builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.PROFILE_PRODUCT_VARIABLE}${id}`,
        method: "GET",
      }),
      providesTags: ["Product"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateSingleProduct: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.PROFILE_PRODUCT_VARIABLE}${data.id}`,
        method: "PUT",
        body:data
      }),
      providesTags: ["Product"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    paymentSuccess: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.PAYMENT_SUCCESS,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    linkOpenAI: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.AUTH_LINK_OPENAI}`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    unlinkOpenAI: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.AUTH_UNLINK_OPENAI}`,
        method: "DELETE",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
  }),
});

export const {
  useWritingStyleMutation,
  useCompanyVariableMutation,
  useProductVariableMutation,
  useGetAllWritingStylesQuery,
  useDeleteWritingStyleMutation,
  useDeleteCompanyVariableMutation,
  useGetAllCompanyVariablesQuery,
  useGetAllProductVariableQuery,
  useDeleteProductVariableMutation,
  useUpdateProfileMutation,
  useGetSingleCompanyMutation,
  useUpdateSingleCompanyMutation,
  useGetSingleProductMutation,
  useUpdateSingleProductMutation,
  useGetSingleWritingStyleMutation,
  useUpdateSingleWritingStyleMutation,
  useUpdatePasswordMutation,
  useChangeEmailMutation,
  useChangeEmailVerificationMutation,
  usePaymentSuccessMutation,
  useLinkOpenAIMutation,
  useUnlinkOpenAIMutation
} = profileApiSlice;
