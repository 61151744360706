import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { initialValues, Schema } from './helpers';
import { useGetPostEditorBlogMutation } from '../../../../../../redux/slices/postEditorBlogSlice';
import { useParams } from 'react-router-dom';

export const FormikFunction = ({ blogPost, children }) => {
  const [singleContent, setSingleContent] = useState(blogPost ?? null);

  // const handleGetSingle = async (id) => {
  //   try {
  //     const res = await getPostEditor({ id }).unwrap();
  //     const sanitizedData = Object.fromEntries(
  //       Object.entries(res?.data || {}).map(([key, value]) => [
  //         key,
  //         value === null ? '' : value,
  //       ])
  //     );
  //     console.log('get single in formik function: ', sanitizedData);
  //     // Set the actual object that will be used instead of the initial values
  //     //setSingleContent(sanitizedData);
  //     // Handle potential values that can not be set automatically as a value to a field (as i told you in generate function)
  //   } catch (error) {}
  // };

  // const handleDelete = async (id) => {
  //   try {
  //     const res = await deleteContent({ id }).unwrap();
  //     // Here when we delete it we just then navigate to another page (don't worry the page is already done from my side)
  //     navigate('/blog-post/all/');
  //     toast.success('Deleted Successfully');
  //   } catch (err) {
  //     toast.error('Error deleting data');
  //   }
  // };

  return (
    <Formik
      initialValues={singleContent ? singleContent : initialValues}
      enableReinitialize={true}
      validationSchema={Schema}
    >
      {children}
    </Formik>
  );
};
