import '../../Tiptap.scss';
import { EditorContent, useEditor } from '@tiptap/react';
import { useEffect, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import './styles.css';

const RewriteModal = ({ label, previous, save, rewritten, setOpenModal }) => {
  const { t } = useTranslation();

  // Create refs to access each editor instance
  const oldEditorRef = useRef(null);
  const newEditorRef = useRef(null);

  return (
  <div className="m-auto bg-white rounded-xl w-[80%] max-w-[900px] h-[95%] max-h-[600px] flex flex-col overflow-hidden">
      <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
        <p className="font-semibold">{label}</p>
        <Close
          onClick={() => setOpenModal(false)}
          sx={{ fontSize: 32, cursor: 'pointer' }}
        />
      </div>

      <div className="p-4 flex-1 overflow-auto flex flex-col lg:flex-row gap-4">
        <div className="flex-1">
          <div className="flex items-center mb-4 justify-between">
            <p className="mb-2">{t("old", "Ancien")}</p>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => save(oldEditorRef.current?.editor.getHTML())} // Save modified content
              variant="contained"
            >
              {t("save-old", "Sauvegarder l'ancien")}
            </Button>
          </div>

          <div className="rewrite-editor">
            <Editor content={previous} ref={oldEditorRef} />
          </div>
        </div>
        <div className="flex-1">
          <div className="flex items-center mb-4 justify-between">
            <p className="mb-2">{t("new", "Nouveau")}</p>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => save(newEditorRef.current?.editor.getHTML())} // Save modified content
              variant="contained"
            >
              {t("save-new", "Sauvegarder le nouveau")}
            </Button>
          </div>
          <div className="rewrite-editor">
            <Editor content={rewritten} ref={newEditorRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Editor = forwardRef(({ content }, ref) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Table.configure({
        resizable: true,
        allowTableNodeSelection: true,
      }),
      TableRow,
      TableHeader,
      TableCell
    ],
    content: content || '',
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  // Attach the editor instance to the ref
  useEffect(() => {
    if (ref) ref.current = { editor };
  }, [editor, ref]);

  return editor ? <EditorContent className="" editor={editor} /> : null;
});

export default RewriteModal;