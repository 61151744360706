import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import handleLogout from '../../../redux/logout';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useChangeEmailVerificationMutation } from '../../../redux/slices/profileApiSlice';
import CustomLoader from '../../../shared/components/others/CustomLoader';

const ChangeEmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [changeEmailVerification, { isLoading }] = useChangeEmailVerificationMutation()
  const dispatch = useDispatch();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await changeEmailVerification(token);

        if (response.data.status === 200) {
          toast.success("Change Email verification successfully. You have to log yourself again.");
          handleLogout(dispatch);
          navigate('/login');
        } else {
          toast.error("Change Email verification failed")
        }
      } catch (error) {
        toast.error("Someting went wrong")
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <div>
      <CustomLoader />
    </div>
  );
};

export default ChangeEmailVerification;
