import React from 'react';
import { Box, Button } from "@mui/material";
import { WritingStyleModal, CompanyModal, ProductModal } from '../../generationVariables'

const GenerationVariablesZone = ({ 
    formikProps, 
    isWriting = false,
    isCompany = false,
    isProduct = false,
    writingOpen = false,
    companyOpen = false,
    productOpen = false,
    writingStylesVariables = [],
    companyVariables = [],
    productVariables = [],
    handleWritingOpen = () => {},
    handleCompanyOpen = () => {},
    handleProductOpen = () => {},
    handleWritingClose = () => {},
    handleCompanyClose = () => {},
    handleProductClose = () => {},
    isReadOnly=false
}) => {
    return (
        <>
        {(isWriting || isCompany || isProduct) && (
            <Box id="generation-variables" display={"flex"} mt={1}>
                {isWriting && (
                    <Box>
                        {formikProps.values.style_id ? 
                            <Button variant="contained" onClick={handleWritingOpen} disabled={isReadOnly}>
                                Writing Style ✅
                            </Button> :
                            <Button variant="outlined" onClick={handleWritingOpen} disabled={isReadOnly}>
                                Writing Style (Optional)
                            </Button>
                        }
                        <WritingStyleModal
                            open={writingOpen}
                            handleClose={handleWritingClose}
                            formikProps={formikProps}
                            writingStyles={writingStylesVariables}
                        />
                    </Box>
                )}
                {isCompany && (
                    <Box>
                        {formikProps.values.company_id ? 
                            <Button variant="contained" onClick={handleCompanyOpen} disabled={isReadOnly}>
                                Company ✅
                            </Button> :
                            <Button variant="outlined" onClick={handleCompanyOpen} disabled={isReadOnly}>
                                Company (Optional)
                            </Button>
                        }
                        <CompanyModal
                            open={companyOpen}
                            handleClose={handleCompanyClose}
                            formikProps={formikProps}
                            companyStyles={companyVariables}
                        />
                    </Box>
                )}
                {isProduct && (
                    <Box>
                        {formikProps.values.product_id ? 
                            <Button variant="contained" onClick={handleProductOpen} disabled={isReadOnly}>
                                Product ✅
                            </Button> :
                            <Button variant="outlined" onClick={handleProductOpen} disabled={isReadOnly}>
                                Product (Optional)
                            </Button>
                        }
                        <ProductModal
                            open={productOpen}
                            handleClose={handleProductClose}
                            formikProps={formikProps}
                            productStyles={productVariables}
                        />
                    </Box>
                )}
            </Box>
        )}
        </>
)};

export default GenerationVariablesZone;