import React from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const CommunityPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const joinCommunity = async () => {
        navigate("https://chat.whatsapp.com/K7WzNfCj0pMFzD4ASrp72u")
    }

    return (
        <div className='rounded-lg shadow-default bg-white px-4 py-4 mt-3'>

        <p className='text-black text-xl font-bold'>{t("join-our-community", "Rejoins la communauté !")}</p>
        
        {/* <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3'>{t("join-community-text", "Rejoignez notre groupe Facebook pour communiquer avec notre équipe et les autres utilisateurs du logiciel pour trouver des réponses à vos questions, et même nous demander d'intégrer des fonctionnalités spécialement pour vous !")}</p> */}
        <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3'>{t("join-whatsapp-community", "Rejoins notre groupe WhatsApp pour communiquer avec notre équipe et les autres utilisateurs du logiciel pour poser tes questions et même participer à la création des nouvelles fonctionnalités !")}</p>

        <div>
            <button 
                className='bg-success hover:bg-success_hover px-5 py-[6px] mt-3 text-white rounded font-light' 
                onClick={() => joinCommunity()}
            >
                {/* {t("join-facebook-group", "Rejoindre le groupe Facebook")} */}
                {t("join-whatsapp-group", "Rejoindre le groupe WhatsApp")}
            </button>
        </div>

    </div>
  )
}

export default CommunityPanel