import generateYupSchema from "../../../../shared/utils/features/CreateFeatureValidationSchema";

const InitialValues = {
  id:"",
  style_id:"",
  company_id:"",
  product_id:"",
  subject: "",
  seo_request: "",
  outline: "",
  nb_outputs:1
};

const validationConfig = {
  subject: {
    type: "string",
    max: 2000,
    required: true,
    requiredMessage: "Le sujet est requis",
    maxMessage: "Le sujet doit faire un maximum de 2000 caractères"
  },
  seo_request: {
    type: "string",
    max: 100,
    required: true,
    requiredMessage: "La requête SEO cible est requise",
    maxMessage: "La requête SEO cible doit faire un maximum de 100 caractères"
  },
  outline: {
    type: "string",
    max: 2000,
    required: false,
    maxMessage: "La requête SEO cible doit faire un maximum de 100 caractères"
  },
  nb_outputs: {
    type: "number",
    min: 1,
    max: 5,
    required: true,
    minMessage: "Le nombre d'outputs minimum est 1",
    maxMessage: "Le nombre d'outputs maximum est 5",
    requiredMessage: "Le nombre d'outputs est requis"
  }
};

const Schema = generateYupSchema(validationConfig);

export {InitialValues, validationConfig, Schema};