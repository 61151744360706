import generateYupSchema from "../../../../shared/utils/features/CreateFeatureValidationSchema";

const InitialValues = {
  words: "",
  excluded_words: "",
  nb_words: 10,
  id: ""
};

const validationConfig = {
  words: {
    type: "string",
    max: 1000,
    required: true,
    requiredMessage: "Le(s) mot(s) de départ est/sont requis",
    maxMessage: "Le(s) mot(s) de départ doit/vent faire un maximum de 1000 caractères"
  },
  excluded_words: {
    type: "string",
    max: 1000,
    required: false,
    maxMessage: "Le(s) mot(s) exclus doit/vent faire un maximum de 1000 caractères"
  },
  nb_words: {
    type: "number",
    min: 5,
    max: 50,
    required: true,
    minMessage: "Le nombre de mots minimum est 5",
    maxMessage: "Le nombre de mots maximum est 30",
    requiredMessage: "Le nombre de mots est requis"
  }
};

const Schema = generateYupSchema(validationConfig);

const tableColumns = [
  {
    field: "nomsCommuns", 
    headerName: "Noms communs", 
    width: 90, 
    flex: 2 
  },
  {
    field: "verbes",
    headerName: "Verbes",
    flex: 2,
  },
  {
    field: "adjectifs",
    headerName: "Adjectifs",
    flex: 2,
  },
];

const tableHeaders = tableColumns.map(column => ({
  label: column.headerName,
  key: column.field,
}));

export {InitialValues, validationConfig, Schema, tableColumns, tableHeaders };