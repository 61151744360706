import {
  Box,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
// import FErrorMessage from '../../../FErrorMessage';

const GlobalTextField = ({
  style,
  formikProps,
  text,
  name,
  placeholder,
  isRequired,
  multipleLines,
  maxLength,
}) => {
  return (
    <Box pt={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InputLabel shrink sx={{ paddingRight: '100%' }}>
          {text}
        </InputLabel>

        <TextField
          value={formikProps.values[name]}
          onBlur={formikProps.handleBlur}
          onChange={formikProps.handleChange}
          name={name}
          placeholder={placeholder}
          error={
            formikProps.touched[name] &&
            Boolean(formikProps.errors[name]) &&
            isRequired
          }
          required={isRequired ? true : undefined}
          multiline={multipleLines ? true : undefined}
          minRows={multipleLines ? 2 : 1}
          inputProps={{ maxLength: maxLength }}
          sx={{
            width: '100%',
            borderRadius: '10px !important',
            ...style,
          }}
        />

        <Grid container justifyContent='space-between'>
          <Grid item>{/* <FErrorMessage name={name} /> */}</Grid>
          {maxLength && (
            <Grid item>
              <Typography
                variant='caption'
                display='block'
                sx={{ textAlign: 'right' }}
              >
                {formikProps.values[name].length} / {maxLength}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlobalTextField;
