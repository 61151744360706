import { useState, useEffect } from 'react';

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.matchMedia('(max-width: 640px)').matches);
    };

    checkMobileView();

    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  return isMobileView;
};

export default useMobileView;