import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDismissNotificationMutation, useGetAllNotificationQuery } from '../../redux/slices/dashboardApiSlice';
import { Box, Button, Typography } from '@mui/material'
import { toast } from 'react-toastify';
import { CustomLoader } from '../../shared/components/others';
import CloseIcon from '@mui/icons-material/Close';

const NotificationPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: notifications, isLoading: notificationsLoading, refetch } = useGetAllNotificationQuery();
    const [dismissNotification, { isLoading: deleteLoading }] = useDismissNotificationMutation();

    useEffect(() => {
        refetch();
    }, [refetch]);

    const deleteNotification = async (notification_id, notification_type) => {        
        try {
            await dismissNotification({notification_id, notification_type});
            refetch();
        } catch (error) {
            toast.error(error?.response?.data?.detail || t("something-went-wrong"));
        }
    };

    const viewNotification = (link, notification_id, notification_type) => {
        navigate(link);
        dismissNotification({notification_id, notification_type});
    };

    return (
        <div className="rounded-lg shadow-default bg-white py-4 group">
        <p className="text-black text-xl font-bold mx-4">
            {t("notifications", "Notifications")}
        </p>

        {notificationsLoading ? (
            <div className="flex justify-center items-center h-[65vh]">
                <CustomLoader />
            </div>
        ) : (
            <div className="grid grid-cols-1 items-center max-h-[435px] overflow-y-auto px-4">
            {notifications.length > 0 ? (
                notifications.map((notification) => (
                    <div key={`notification-${notification.notification_type}-${notification.id}`} className="border px-2 py-1 mt-3 shadow-lg rounded-lg relative">
                        <span className="text-lg font-sans antialiased font-semibold tracking-tight">
                            {notification.title}
                        </span>
                        <p className="px-0 font-normal text-gray-700 min-w-[128px] text-base my-1">
                            {t(notification.message, notification.message)}
                        </p>
                        <div className="flex items-center justify-end">
                            <button
                                className="px-5 py-[3px] hover:bg-blue-200 bg-primary text-white rounded font-light text-[1.15rem]"
                                onClick={() =>
                                viewNotification(
                                    notification.link,
                                    notification.id,
                                    notification.notification_type
                                )
                                }
                            >
                                {notification.button_text}
                            </button>
                        </div>
                        <div className="absolute  top-2 right-3 shadow-xl">
                            <button
                                type="button"
                                className="rounded-md bg-white focus:outline-none"
                            >
                                <span className="sr-only">Close</span>
                                <CloseIcon style={{ height: "25px", width: "25px" }}
                                    onClick={() =>
                                    deleteNotification(
                                        notification.id,
                                        notification.notification_type
                                    )}
                                />
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <p className="block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3">
                {t("no-notification", "Aucune notification pour le moment.")}
                </p>
            )}
            </div>
        )}
    </div>
    )

    return (
        <Box style={style}>
            <Typography fontSize={{ lg: 20, md: 20, sm: 20, xs: 16 }} fontWeight={600}>
                {t("notifications", "Notifications")}
            </Typography>
            {notificationsLoading ? (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"65vh"}
                >
                    <CustomLoader />
                </Box>
            ) : (
                <Box>
                    {notifications.length > 0 ? notifications.map((notification) => (
                        <div style={{ display: 'flex', alignItems: 'center' }} key={`notification-${notification.id}`}>
                            <Typography style={{ color: notification.color, marginRight: '8px' }}>
                                {t(notification.title, notification.title)}
                            </Typography>
                            <Button variant="contained" color="success" onClick={() => viewNotification(notification.link, notification.id, notification.notification_type)}>
                                {t(notification.button_text, notification.button_text)}
                            </Button>
                            <Button variant="contained" color="error" onClick={() => deleteNotification(notification.id, notification.notification_type)}>
                                {t("delete", "Supprimer")}
                            </Button>
                        </div>
                    )) : (
                        <p>{t("no-notification", "Aucune notification pour le moment.")}</p>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default NotificationPanel;