import { FieldErrorMessage } from "../others"
import { useEffect, useRef } from "react"

const InputProfile = ({ 
    formikProps,
    type,
    text, 
    name, 
    placeholder,
    maxLength,
    disabled = false,
    multiline = false,
}) => {
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "auto";  // Reset height
            textarea.style.height = `${5+textarea.scrollHeight}px`; // Max height of 8 rows (128px)
        }
    };

    useEffect(() => {
        if (multiline) {
            adjustTextareaHeight();
        }
    }, [formikProps.values[name], multiline]);

    const handleInputChange = (e) => {
        if (!maxLength || e.target.value.length <= maxLength) {
            formikProps.handleChange(e);
            if (multiline) adjustTextareaHeight();
        }
    };

    return (
        <div>
            <label
                htmlFor={name}
                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
            >
                {text}
            </label>
            {multiline ? (
                <textarea
                    ref={textareaRef}
                    className="w-full bg-white p-2.5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 resize-none overflow-y-auto disabled:bg-slate-200 disabled:text-slate-400"
                    placeholder={placeholder}
                    value={formikProps.values[name]}
                    onChange={(e) => {
                        formikProps.handleChange(e);
                        adjustTextareaHeight();
                    }}
                    onBlur={formikProps.handleBlur}
                    name={name}
                    disabled={disabled}
                    rows={2}
                    style={{ maxHeight: "128px" }}
                    maxLength={maxLength}
                />
            ) : (
                // Render input if multiline is false
                <input
                    className="w-full bg-white p-2.5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 h-fit overflow-hidden disabled:bg-slate-200 disabled:text-slate-400"
                    type={type}
                    placeholder={placeholder}
                    value={formikProps.values[name]}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    name={name}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            )}
            <div className="flex justify-between items-center text-xs mt-1">
                <FieldErrorMessage name={name} />
                {maxLength && (
                <div className="text-gray-500">
                    {formikProps.values[name].length} / {maxLength}
                </div>
                )}
            </div>
        </div>
    )
}

export default InputProfile;