import { useTranslation } from 'react-i18next';
import React from "react";
import pen from "../../../assets/images/pen.svg";
import { GVModalTemplate } from "./index";

const WritingModal = ({ 
  open, 
  handleClose,
  formikProps,
  writingStyles
}) => {
  const { t } = useTranslation();

  return (
    <GVModalTemplate
      open={open}
      handleClose={handleClose}
      formikProps={formikProps}
      generationVariablesList={writingStyles}
      fieldName="style_id"
      modalTitle={t("Writing-style", "Style d'écriture")}
      modalIcon={pen}
      modalDescription={t("description-writing-style", "Personnalisez la rédaction de votre article pour qu'elle s'aligne parfaitement avec le style rédactionnel de votre site web et les préférences de vos lecteurs.")}
      noSelectionText={t("No-style-selected","Aucun style sélectionné")}
    />
  );
};

export default WritingModal;
