import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import { BsMagic } from 'react-icons/bs';
import { useGeneratePostIntroductionMutation } from '../../../../../../redux/slices/featuresApiSlice';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import RewriteModal from '../../SidePanel/global-rewrite-modal/rewrite-modal';
import { useWebSocket } from "../../WebSocketContext";
import { toast } from 'react-toastify';

const IntroductionModal = ({ isIntroGenerated }) => {
  const { t } = useTranslation();
  const {
    introHeading,
    setOpenIntroductionModal,
    openIntroductionRewrite,
    setOpenIntroductionRewrite,
    bufferRef
  } = useWebSocket()
  const [generatePostIntroduction, { isLoading }] =
    useGeneratePostIntroductionMutation();
  const formikProps = useFormikContext();

  const handleGenerateIntroduction = async () => {
    try {
      let payload = {
        style_id:
          formikProps.values.style_id === null ||
          formikProps.values.style_id === ''
            ? null
            : formikProps.values.style_id,
        company_id:
          formikProps.values.company_id === null ||
          formikProps.values.company_id === ''
            ? null
            : formikProps.values.company_id,
        product_id:
          formikProps.values.product_id === null ||
          formikProps.values.product_id === ''
            ? null
            : formikProps.values.product_id,
        subject: formikProps.values.subject,
        seo_request: formikProps.values.seo_request,
        outline: formikProps.values.outline,
        length: formikProps.values.introduction_length,
        //nb_outputs: formikProps.values.nb_introduction,
      };
      if (isIntroGenerated) {
        payload['introduction'] = formikProps.values.introduction;
      } else {
        introHeading.current = formikProps.values.introduction;
      }

      console.log(`introduction generation payload: `, payload);

      const res = await generatePostIntroduction({...payload, ...{ stream: true }}).unwrap();

      if (res?.status !== 200) {
        toast.error(t("something-went-wrong-with-generation", "Une erreur s'est produite lors de la génération"))
        return;
      }

      console.log(`introduction generation res: `, res);

      if (!isIntroGenerated) {
        const introzone = document.getElementById('introzone');
        if (introzone) {
          introzone.innerHTML = introHeading.current + res?.data;
        }
      } else {
        formikProps.setFieldValue('introduction_rewrite', res?.data);
      }

      introHeading.current = "";
      bufferRef.current = "";
    } catch (error) {
      if (error.error === "The model `gpt-4o` does not exist or you do not have access to it.") {
        toast.error(t("problem-with-openai", "Une erreur OpenAI est survenue (avez-vous bien ajouté un moyen de paiement à votre compte ?"))
      } else if (error?.data?.detail === "Access forbidden: you must have registered a functional OpenAI API Key to generate contents") {
        toast.error(t("missing-api-key", "Vous devez avoir une clé API active pour générer du contenu"))
      } else {
        toast.error(t("something-went-wrong-with-generation", "Une erreur s'est produite lors de la génération"))
      }
      console.log(error);
    }
  };

  const saveSelectedVersion = (data) => {
    const zone = document.getElementById('introzone');
    if (zone) {
      zone.innerHTML = data;
    }
    formikProps.setFieldValue('introduction_rewrite', '');
    setOpenIntroductionRewrite(false);
    setOpenIntroductionModal(false);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        //overflow: { md: 'unset', xs: 'scroll' },
        height: '100vh',
        width: { md: 'calc(100vw - 350px)', xs: '100%' },
        display: 'flex',
        mt: { md: '48px !important', xs: 'unset' },
        left: 0,
        top: 0,
        bgcolor: '#000000a1',
        zIndex: 99999,
      }}
    >
      {openIntroductionRewrite ? (
        <RewriteModal
          previous={formikProps.values.introduction}
          rewritten={formikProps.values.introduction_rewrite}
          save={saveSelectedVersion}
          label={t('Introduction-Rewrite', 'Introduction (réécriture)')}
          setOpenModal={setOpenIntroductionRewrite}
        />
      ) : (
        <div className="m-auto bg-white rounded-xl w-[80%] max-w-[500px]">
          <div className="flex justify-between p-4 items-center border-b-[3px] border-slate-200">
            <p>
              {!isIntroGenerated
                ? 'Introduction'
                : `(Re) Introduction`}
            </p>
            <Close
              onClick={() => setOpenIntroductionModal(false)}
              className="cursor-pointer"
              sx={{ fontSize: 32 }}
            />
          </div>
          <div className="p-4">
            <FormControl sx={{ mb: 2 }} fullWidth>
              <p className="mb-2">
                {t('Generate-introduction', 'Générer une introduction')}
              </p>
              <Select
                sx={{ bgcolor: 'white' }}
                value={formikProps.values.introduction_length}
                onChange={(e) =>
                  formikProps.setFieldValue(
                    'introduction_length',
                    e.target.value
                  )
                }
              >
                <MenuItem value={'courte'}>{t('Short', 'Courte')}</MenuItem>
                <MenuItem value={'moyenne'}>{t('Medium', 'Moyenne')}</MenuItem>
                <MenuItem value={'longue'}>{t('Long', 'Longue')}</MenuItem>
              </Select>
            </FormControl>

            <div className="flex items-center gap-4">
              <LoadingButton
                startIcon={<BsMagic />}
                loading={isLoading}
                onClick={handleGenerateIntroduction}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  bgcolor: '#8b5cf6',
                  '&:hover': {
                    bgcolor: '#7c3aed'
                  }
                }}
              >
                {t('Generate-introduction', 'Générer une introduction')}
              </LoadingButton>

                {formikProps.values.introduction_rewrite !== '' ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenIntroductionRewrite(true)}
                  >
                    {t("See-rewriten","Voir la version réécrite")}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )}

        {/* {rewrite ? (
          <RewriteModal
            previous={formikProps.values.introduction}
            rewritten={formikProps.values.introduction_rewrite}
          />
        ) : null} */}
      </Box>
  );
};

export default IntroductionModal;
