import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomLoader } from "../../shared/components/others";
import { toast } from "react-toastify";
import { usePaymentSuccessMutation } from "../../redux/slices/profileApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../redux/slices/authSlice";

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [payment]=usePaymentSuccessMutation()
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const queryString = location.search;
  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  
  const parseQueryString = (queryString) => {
    return queryString
      .substring(1)
      .split("&")
      .reduce((acc, pair) => {
        const [key, value] = pair.split("=");
        acc[key] = value;
        return acc;
      }, {});
  };
  const queryParams = parseQueryString(queryString);

  useEffect(() => {
    if (queryParams) {
      if (queryParams?.checkout_session_id) {
        const confirmPayment = async () => {
          try {
            const response = await payment({ 
              checkout_session_id: queryParams.checkout_session_id,
              subscription_id: queryParams.subscription_id
            }).unwrap()
  
            if (response?.message==='success') {
              dispatch(setCredentials({
                accessToken: accessToken,
                userInfo: response?.data
              }));
              setTimeout(() => {
                toast.success(t("subscribed-successfully", "Vous êtes maintenant abonné !"));
               navigate(`/dashboard`,{
                state: { is_verified: response.data.is_verified }
               })
              }, 2000);
            } else {
              toast.error(t("payment-failed", "Le paiement a échoué"));
            }
          } catch (error) {
            toast.error(t("something-went-wrong"));
          }
        };
        confirmPayment();
      } else {
        const returnPortal = async () => {
          try {
            const response = await payment().unwrap()
  
            dispatch(setCredentials({
              accessToken: accessToken,
              userInfo: response?.data
            }));
            setTimeout(() => {
              toast.success(t("changes-saved", "Vos changements ont bien été sauvegardés !"));
              navigate(`/profile`)
            }, 2000);
          } catch (error) {
            toast.error(t("something-went-wrong"));
          }
        };
        returnPortal();
      }
    }
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"50vh"}
      >
        <CustomLoader />
      </Box>
    </>
  );
};

export default PaymentSuccess;
