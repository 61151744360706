import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif', // Use 'Poppins' as the primary font
  },
  palette: {
    primary: {
      main: '#316694',
    },
  },
});

export default theme;
