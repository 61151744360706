import './index.css';
import { useTranslation } from 'react-i18next';
import { dispatch } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import { IoMdArrowBack, IoMdSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { setActivePanel } from '../../../../../redux/slices/panelSlice';
import PostEditorActions from './post-editor-actions';

const SettingsPopover = ({ editor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="settings-container flex justify-between">
      <button onClick={() => navigate('/blog-posts')}>
        <IoMdArrowBack size={20} />
      </button>

      <button
        onClick={() => dispatch(setActivePanel('outline'))}
        className="flex rounded-md items-center text-sm hover:bg-neutral-200 px-4 transition-all ease-in"
      >
        <IoMdSettings size={15} />
        <p className="ml-1 hidden sm:block">{t('Settings', 'Réglages')}</p>
      </button>

      <PostEditorActions />
    </div>
  );
};

export default SettingsPopover;
