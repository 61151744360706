import { logout } from './slices/authSlice';
import { persistor } from './store';
import { toast } from 'react-toastify';
import axios from 'axios';

const handleLogout = async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout/`, {}, { withCredentials: true });

    dispatch(logout());

    persistor.purge();
  } catch (error) {
    toast.error("Error logging out: " + error.message);
  }
};

export default handleLogout;