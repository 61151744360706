import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { Box, IconButton } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import StarterKit from '@tiptap/starter-kit';
import './Tiptap.scss';

const GenerationEditor = ({ 
  content, 
  handleDataChange,
  customExtensions=[],
  customClasses = "",
  onAdd,
  onRemove,
  isReadOnly = false
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      ...customExtensions
    ],

    editorProps: {
      attributes: {
        class: customClasses,
    }},

    content: content || '',

    editable: !isReadOnly,

    onUpdate: ({ editor }) => {
      if (editor.getHTML() === '<p></p>') {
        handleDataChange("");
      } else {
        if (editor.getHTML() === '<p></p>') {
          handleDataChange("");
        } else {
          handleDataChange(editor.getHTML());
        }
      }    
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return (
    <Box p={1}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton size="small" onClick={() => onAdd(editor.getHTML())}>
          <CheckIcon />
        </IconButton>
        <IconButton size="small" onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      </Box>
      <EditorContent editor={editor} />
    </Box>
  );
};

export default GenerationEditor;