import { useTranslation } from 'react-i18next';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PaymentRoute = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const notify = () => {
    toast.error(t('you-need-active-paid-plan', 'Vous devez avoir un abonnement actif pour accéder à cette page.'));
  };
  return (
    <>
      {userInfo.payment_status ? (
        <Outlet />
      ) : (
        <>
          {notify()} <Navigate to='/pricing' replace />
        </>
      )}
    </>
  );
};
export default PaymentRoute;
