export const fetchUserInfo = async (accessToken) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me/`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const userInfo = await response.json();
            return { success: true, data: userInfo };
        } else {
            const errorDetail = await response.text();
            return { success: false, error: errorDetail || "Failed to fetch user information" };
        }
    } catch (error) {
        return { success: false, error: error.message || "An error occurred during the API call." };
    }
};
