export const fetchSubscriptionDetails = async (newPlan, accessToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/payment/change-subscription-details?new_plan_id=${newPlan}`,
            {
                method: 'POST', 
                headers: {
                    'Authorization': `Bearer ${accessToken}`, 
                    'Content-Type': 'application/json',
                }
            }
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch subscription details. Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 200 && data.message) {
            return { message: data.message }; 
        }

        return data; 
    } catch (error) {
        console.error('Error fetching subscription details: ', error); 
        throw error; 
    }
};