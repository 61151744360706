import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import authSliceReducer from './slices/authSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import panelSlice from './slices/panelSlice';

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY,
  onError: (error) => {
    console.error('Encryption error', error);
  },
});

// Persist configuration for only the auth slice
const persistConfig = {
  key: 'auth',
  storage,
  transforms: [encryptor],
  whitelist: ['userInfo', 'accessToken'],
};

// Create the persisted reducer for authSliceReducer
const persistedAuthReducer = persistReducer(persistConfig, authSliceReducer);

// Configure the store
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedAuthReducer,
    panelSlice: panelSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE',
          'persist/REGISTER',
          'panelSlice/setCurrentZone',
        ],
        ignoredPaths: ['panelSlice.currentZone'],
      },
    }).concat(apiSlice.middleware),
  devTools: true,
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export const { dispatch, getState } = store;

export { store, persistor };
