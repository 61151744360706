import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import BaseToolbar from './BaseToolbar';
import './Tiptap.scss';

const MainEditor = ({
  content, 
  handleDataChange, 
  placeholder,
  customExtensions = [],
  customClasses = "",
  showToolbar = true,
  toolbarButtons = [],
  isReadOnly = false
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      ...customExtensions
    ],

    editorProps: {
      attributes: {
        class: customClasses,
    }},

    content: content || "",

    editable: !isReadOnly,

    onUpdate: ({ editor }) => {
      if (editor.getHTML() === '<p></p>') {
        handleDataChange("");
      } else {
        handleDataChange(editor.getHTML());
      }
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return (
    <>
      {showToolbar && <BaseToolbar editor={editor} buttons={toolbarButtons} />}
      <EditorContent editor={editor} style={{ minHeight: "10em" }} />
    </>
  );
};

export default MainEditor;