import { start } from "slate";

const tableCellFillNa = (
    tableOutput
) => {
    if (tableOutput.length === 0) return tableOutput;
    
    const columns = Object.keys(tableOutput[0]);
    
    const columnValuesMap = columns.reduce((acc, column) => {
        acc[column] = [];
        return acc;
    }, {});
    
    tableOutput.forEach(row => {
        columns.forEach(column => {
            columnValuesMap[column].push(row[column]);
        });
    });
    
    columns.forEach(column => {
        columnValuesMap[column].sort((a, b) => (a === null) - (b === null));
    });
    
    const newTableOutput = [];
    for (let i = 0; i < tableOutput.length; i++) {
        const newRow = {};
        let isAllNull = true;
        columns.forEach(column => {
            newRow[column] = columnValuesMap[column][i];
            if (newRow[column] !== null) {
                isAllNull = false;
            }
        });
        if (!isAllNull) {
            newTableOutput.push(newRow);
        }
    }
    
    return newTableOutput;
};

export { tableCellFillNa };