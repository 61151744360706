import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../../redux/store';
import { setActivePanel } from '../../../../../redux/slices/panelSlice';
import { LaunchAnalysisBtn } from '../Others';

const Medias = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const formikProps = useFormikContext();

  const handleClose = () => {
    dispatch(setActivePanel(null));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        overflow: { md: 'unset', xs: 'scroll' },
        height: { md: '100vh', xs: '100%' },
        width: { md: 'auto', xs: '100%' },
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'end',
        mt: { md: '48px !important', xs: 'unset !important' },
        right: 0,
        top: 0,
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#303539',
          height: '100vh',
          width: { md: '350px', xs: '100%' },
          color: 'white',
          pb: 5,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#785e35',
            p: 2,
            position: 'relative',
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: 0,
              top: 0,
              p: 2,
            }}
          >
            <Close sx={{ color: 'white' }} />
          </Box>
          <h1 className="text-2xl mb-2">
            {t('Medias', 'Médias (Images et vidéos)')}
          </h1>

          <p className="text-xs mb-2">
            {t(
              'tutorial-text-medias',
              'Les vidéos et encore plus les images ont une part importante dans la rédaction de contenu web. Ces médias permettent d’attirer l’oeil, d’imager ses propos et de donner du dynamisme à son contenu. Utilisez-en !'
            )}
          </p>

          <p className="text-xs">
            {t('For-more-info:', "Pour plus d'infos :")}{' '}
            <span>
              <a className="text-blue-500 underline cursor-pointer">
                {
                  ('semantics-optimization-guide',
                  'Renforcer la sémantique en SEO : le Guide Complet 2024')
                }
              </a>
            </span>
          </p>
        </Box>

        <LaunchAnalysisBtn
          userInfo={userInfo}
          formikProps={formikProps}
          disabled={true}
        />
      </Box>
    </Box>
  );
};

export default Medias;
