import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import i18next from 'i18next';
import { Formik } from 'formik';
import {
  signInInitialValues as initialValues,
  SignInSchema as Schema,
} from './helper';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../redux/slices/authApiSlice';
import {
  InputAuth,
  ButtonAuth,
  GoogleAuth,
} from '../../shared/components/auth';
import { useSelector } from 'react-redux';

const SignIn = () => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // !!! to hide in production
  const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' },
  };

  const handleLanguageChange = (lng) => {
    i18next.changeLanguage(lng);
  };

  return (
    <>
      <div className="language-toggle">
        {Object.keys(lngs).map((lng) => (
          <button
            key={lng}
            type="button"
            onClick={() => handleLanguageChange(lng)}
            className="p-2 m-1 border rounded-md bg-gray-200 hover:bg-gray-300"
          >
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>

      <Helmet>
        <title>{t("Login", "Se connecter")} | BotBlogR</title>
        <meta name="description" content="Page de connexion à la plateforme." />
      </Helmet>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Schema}
        onSubmit={async (values) => {
          try {
            const formData = new FormData();
            formData.append('username', values.email);
            formData.append('password', values.password);
            const rememberMe = values.rememberMe;
            const res = await login({ formData, rememberMe }).unwrap();
            if (res?.status_code === 200) {
              dispatch(
                setCredentials({
                  accessToken: res.access_token,
                  userInfo: res.user_info,
                })
              );
              setTimeout(() => {
                if (res?.payment_status !== null) {
                  navigate('/dashboard');
                } else {
                  navigate('/pricing');
                }
              }, 1000);
            }
          } catch (error) {
            toast?.error(error?.data?.detail);
            if (error?.data?.detail === 'Account Not Verified') {
              navigate('/confirmation');
            }
          }
        }}
      >
        {(props) => (
          <div>
            <div className='mb-3'>
              <p
                className='text-sm leading-6 text-gray-500 flex items-center gap-3'
                onClick={() => navigate('/signup', { replace: true })}
              >
                {t(
                  "You Don't have Account yet ?",
                  "Vous n'avez pas encore de compte ?"
                )}{' '}
                <span className='cursor-pointer font-semibold text-blue-600'>
                  {t('signup', 'Inscrivez-vous !')}
                </span>
              </p>
            </div>

            <form action='#' method='POST' className='space-y-6'>
              <InputAuth
                formikProps={props}
                type='text'
                text={t('email', 'Email')}
                name='email'
                placeholder={t('enter-email', 'Entrez votre email')}
              />

              <InputAuth
                formikProps={props}
                type='password'
                text={t('password', 'Mot de passe')}
                name='password'
                placeholder={t('enter-password', 'Entrez votre mot de passe')}
              />

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    type='checkbox'
                    name='rememberMe'
                    checked={props.values.rememberMe} // Use the Formik value here
                    onChange={props.handleChange} // Ensure Formik's handleChange is used
                    onBlur={props.handleBlur}
                  />
                  <label
                    htmlFor='remember-me'
                    className='ml-3 block text-sm leading-6 text-gray-700'
                  >
                    {t('remember-me', 'Se souvenir de moi')}
                  </label>
                </div>

                <div className='text-sm leading-6'>
                  <p
                    className='font-semibold cursor-pointer text-blue-500'
                    onClick={() =>
                      navigate('/forgot-password', { replace: true })
                    }
                  >
                    {t('forgot-password', 'Mot de passe oublié ?')}
                  </p>
                </div>
              </div>

              <ButtonAuth
                action={props.handleSubmit}
                disabled={isLoading}
                isLoading={isLoading}
                type='submit'
                text={t('signin', 'se connecter')}
              />
            </form>

            <GoogleAuth
              text={t('continue-with', 'continuez avec')}
              btnText={t('signin-google', 'Se connecter via Google')}
            />
          </div>
        )}
      </Formik>
    </>
  );
};

export default SignIn;
