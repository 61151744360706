import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NodeViewWrapper,
  NodeViewContent,
  EditorContent,
  useCurrentEditor,
  Editor,
  useEditor,
  EditorContext,
  BubbleMenu,
} from '@tiptap/react';
import Toolbar from './Toolbar';
import './MainEditor.scss';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { dispatch } from '../../../../redux/store';
import { useFormikContext } from 'formik';
import { isHeadingZone } from './Zones/HeadingZones/H2Zone/Extension';
import {
  setActivePanel,
  setCurrentZone,
  setEditor,
} from '../../../../redux/slices/panelSlice';
import { Close } from '@mui/icons-material';
import IntroZoneBubbleMenu from './bubble-menus/introduction-menu/introzone-bubble-menu';
import ConclusionBubbleMenu from './bubble-menus/conclusion-menu/conclusion-bubble-menu';
import PartBubbleMenu from './bubble-menus/part-bubble-menu/part-bubble-menu';
import MobileSidePanel from './SidePanel/MobileSidePanel';
import DesktopSidePanel from './SidePanel/SidePanel';
import { WebSocketProvider } from './WebSocketContext';
import InsertUrlBubbleMenu from './bubble-menus/insert-url-menu/insert-url-bubble-menu';
import { useSelector } from 'react-redux';
import Outline from './SidePanel/Outline';
import UrlSlug from './SidePanel/UrlSlug';
import H1Title from './SidePanel/h1-title-panel';
import Content from './SidePanel/content-panel';
import Metadata from './SidePanel/metadata/metadata-panel';
import Semantics from './SidePanel/semantics-panel';
import Linking from './SidePanel/linking-panel';
import Medias from './SidePanel/medias-panel';

const loadFormikValues = (values) => {
  const h1zone = document.getElementById('h1zone');
  const introzone = document.getElementById('introzone');
  const contentzone = document.getElementById('contentzone');
  const conclusionzone = document.getElementById('conclusionzone');

  if (h1zone) {
    h1zone.textContent = values.h1_title;
  }
  if (introzone) {
    introzone.innerHTML = values.introduction;
  }
  if (contentzone) {
    contentzone.innerHTML = values.content;
  }
  if (conclusionzone) {
    conclusionzone.innerHTML = values.conclusion;
  }
};

const MainEditor = ({ content, extensions }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const h1zone = document.getElementById('h1zone');
  const introzone = document.getElementById('introzone');
  const contentzone = document.getElementById('contentzone');
  const conclusionzone = document.getElementById('conclusionzone');
  const [characterCount, setCharacterCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const editor = useEditor({
    extensions: extensions,
    content: content,
    onCreate: () => {
      loadFormikValues(values);
      updateCounts(); // Initialize word and character count on creation
    },
    onUpdate: () => {
      if (h1zone) {
        setFieldValue('h1_title', h1zone.textContent);
      }
      if (introzone) {
        const d = introzone.textContent !== '' ? introzone.innerHTML : '';
        setFieldValue('introduction', d);
      }
      if (contentzone) {
        const d = contentzone.textContent !== '' ? contentzone.innerHTML : '';
        setFieldValue('content', d);
      }
      if (conclusionzone) {
        const d =
          conclusionzone.textContent !== '' ? conclusionzone.innerHTML : '';
        setFieldValue('conclusion', d);
      }
      updateCounts(); // Update word and character count on content change
    },
  });

  const updateCounts = () => {
    const textContent = editor?.getText().trim() || '';
    setCharacterCount(textContent.length);
    setWordCount(
      textContent.split(/\s+/).filter((word) => word.length > 0).length
    );
  };

  const getCurrentZone = (node) => {
    if (!node) return null;
    if (node?.parent?.name === 'doc') return node.name;
    return getCurrentZone(node.parent);
  };

  useEffect(() => {
    const node = editor?.$pos(editor?.state?.selection.head);
    const zone = getCurrentZone(node);
    const headingZone = isHeadingZone(node)?.element ?? null;

    dispatch(setCurrentZone({ zone: zone, isHeadingZone: headingZone }));
  }, [editor?.state?.selection?.head]);

  useEffect(() => {
    if (h1zone) {
      h1zone.addEventListener('mousedown', () => {
        dispatch(setActivePanel('h1Title'));
      });
    }
  }, [h1zone]);

  return editor ? (
    <WebSocketProvider editor={editor}>
      <Box
        sx={{
          width: { md: 'calc(100vw - 350px)', xs: '100%' },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
        className="post-editor-container"
      >
        <Toolbar editor={editor} />
        <IntroZoneBubbleMenu editor={editor} />
        <ConclusionBubbleMenu editor={editor} />
        <PartBubbleMenu editor={editor} />

        <EditorContent className="editor-wrapper" editor={editor} />

        <div className="text-sm ml-[16px] mt-2">
          {wordCount} {t('words-count', 'mots')} {characterCount}{' '}
          {t('characters', 'caractères')}
        </div>
      </Box>
      {isLarge ? <DesktopSidePanel /> : <MobileSidePanel />}
      <Panels />
    </WebSocketProvider>
  ) : null;
};

const Panels = () => {
  const panelsMap = {
    outline: <Outline />,
    urlSlug: <UrlSlug />,
    h1Title: <H1Title />,
    content: <Content />,
    metaData: <Metadata />,
    semantics: <Semantics />,
    mesh: <Linking />,
    medias: <Medias />,
  };

  const activePanel = useSelector((state) => state.panelSlice.activePanel);

  return <>{panelsMap[activePanel] ?? null}</>;
};

export default MainEditor;
