import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const AdminRoute = () => {
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const notify = () => {
    toast.error('You must be admin to access this page.');
  };
  return <>{userInfo.role === "admin" ? <Outlet /> : <>{notify()} <Navigate to="/dashboard" replace /></>}</>;
};
export default AdminRoute;
