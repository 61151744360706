import React from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, Grid } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { 
    useAddFavoriteFeatureMutation,
    useDeleteFavoriteFeatureMutation 
} from '../../../redux/slices/dashboardApiSlice';

const FeatureCard = ({ 
    feature,
    isFavorite,
    refetch
}) => {
  const navigate = useNavigate();
  const [addFavoriteFeature] = useAddFavoriteFeatureMutation();
  const [deleteFavoriteFeature] = useDeleteFavoriteFeatureMutation();

  const toggleFavorite = (feature_id) => {
    if (isFavorite) {
      deleteFavoriteFeature(feature_id);
    } else {
      addFavoriteFeature(feature_id);
    }
    refetch();
  };

  return (
    <div
      onClick={() => navigate(`/feature/${feature.feature_id}`, { state: { data: feature } })}
      className="block p-5 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-pointer hover:border-blue-50"
    >
      <div className='flex items-start justify-between'>

        <h5 className="mb-2 text-lg font-sans antialiased font-semibold tracking-tight text-blue-gray-900 max-w-[260px]">
          {feature?.["h1-tag"]}
        </h5>

        <button id="dropdownButton" data-dropdown-toggle="dropdown" className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-sm" type="button" onClick={(e) => {
          e.stopPropagation();
          toggleFavorite(feature.feature_id);
        }}>
          {isFavorite ? <Star sx={{ color: "#5094cf" }} /> : <StarBorder />}
        </button>

      </div>
      <p className="text-gray-700 dark:text-gray-400 font-sans text-sm antialiased font-light leading-relaxed text-inherit">
        {feature?.description}
      </p>
    </div>
  );
};

export default FeatureCard;