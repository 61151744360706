import React from 'react';
import { Formik } from 'formik';
import CustomLoader from '../../components/others/CustomLoader';
import { useState } from 'react';
import { Box } from '@mui/material';
import useDataFetch from './useDataFetch';
import { useGetAllWritingStylesQuery, useGetAllCompanyVariablesQuery, useGetAllProductVariableQuery } from '../../../redux/slices/profileApiSlice';

const withFormik = (WrappedComponent, config) => (props) => {

    const { data: writingStyles, isLoading: writingLoading } = useGetAllWritingStylesQuery();
    const { data: companyVariables, isLoading: companyLoading } = useGetAllCompanyVariablesQuery();
    const { data: productVariables, isLoading: productLoading } = useGetAllProductVariableQuery();
    const handleWritingOpen = () => {setWritingOpen(true);};
    const handleCompanyOpen = () => {setCompanyOpen(true);};
    const handleProductOpen = () => {setProductOpen(true);};
    const handleCompanyClose = () => setCompanyOpen(false);
    const handleWritingClose = () => setWritingOpen(false);
    const handleProductClose = () => setProductOpen(false);
    const [writingOpen, setWritingOpen] = useState(false);
    const [productOpen, setProductOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
  
    const {
        tableOutput,
        tableOutputHandler,
        tableOutputRemove,
        handleRowDelete,
        handleCellDelete,
        mainOutput,
        mainOutputHandler,
        outputs,
        outputsHandler,
        removeOutputHandler,
        rewritenOutput,
        rewritenOutputHandler,
        handleCreateNew,
        singleContent,
        deleteButton,
        setDeleteButton,
        handleGetSingle,
        handleGenerate,
        generateLoading,
        handleSave,
        createLoading,
        updateLoading,
        handleDelete,
        deleteLoading,
        contents,
        contentsLoading,
        rewriteOpen,
        handleRewriteClose,
        handleRewriteOpen
    } = useDataFetch(
        config.output_key,
        config.useGenerateContentMutation,
        config.useCreateContentMutation,
        config.useGetSingleContentMutation,
        config.useUpdateContentMutation,
        config.useDeleteContentMutation,
        config.useGetAllContentsQuery,
        config.isTable,
        config.isNbOutputs,
        config.streamGeneration
    );

    const componentConfig = {
        writingStyles: writingStyles,
        companyVariables: companyVariables,
        productVariables: productVariables,
        handleWritingOpen: handleWritingOpen,
        handleCompanyOpen: handleCompanyOpen,
        handleProductOpen: handleProductOpen,
        handleCompanyClose: handleCompanyClose,
        handleWritingClose: handleWritingClose,
        handleProductClose: handleProductClose,
        writingOpen: writingOpen,
        productOpen: productOpen,
        companyOpen: companyOpen,
        contents: contents,
        singleContent: singleContent,
        tableOutput: tableOutput,
        tableOutputHandler: tableOutputHandler,
        tableOutputRemove: tableOutputRemove,
        handleRowDelete: handleRowDelete,
        handleCellDelete: handleCellDelete,
        mainOutput: mainOutput,
        mainOutputHandler: mainOutputHandler,
        outputs: outputs,
        outputsHandler: outputsHandler,
        removeOutputHandler: removeOutputHandler,
        rewritenOutput: rewritenOutput,
        rewritenOutputHandler: rewritenOutputHandler,
        handleCreateNew: handleCreateNew,
        deleteButton: deleteButton,
        setDeleteButton: setDeleteButton,
        handleSave: handleSave,
        handleDelete: handleDelete,
        createLoading: createLoading,
        updateLoading: updateLoading,
        deleteLoading: deleteLoading,
        handleGetSingle: handleGetSingle,
        generateLoading: generateLoading,
        rewriteOpen: rewriteOpen,
        handleRewriteClose: handleRewriteClose,
        handleRewriteOpen: handleRewriteOpen
    }

    const getDefaultId = (items) => {
        const defaultItem = items.find(item => item.is_default);
        return defaultItem ? defaultItem.id : "";
    };

    const updateInitialValuesWithDefaults = (initialValues, writingStyles, companyWebsites, products) => {
        const updatedValues = { ...initialValues };
      
        if ('style_id' in initialValues && writingStyles) {
          updatedValues.style_id = getDefaultId(writingStyles) || initialValues.style_id;
        }
      
        if ('company_id' in initialValues && companyWebsites) {
          updatedValues.company_id = getDefaultId(companyWebsites) || initialValues.company_id;
        }
      
        if ('product_id' in initialValues && products) {
          updatedValues.product_id = getDefaultId(products) || initialValues.product_id;
        }
      
        return updatedValues;
    };

    const updatedInitialValues = updateInitialValuesWithDefaults(config.InitialValues, writingStyles, companyVariables, productVariables);

    return (
    <>
        {writingLoading ||
        companyLoading ||
        productLoading ||
        contentsLoading ? (
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "100vh" }}
            >
                <CustomLoader />
            </Box>
        ) : (
            <>
                <Formik
                    initialValues={singleContent ? singleContent : updatedInitialValues}
                    enableReinitialize={true}
                    validationSchema={config.Schema}
                    onSubmit={handleGenerate}
                >
                    {(formikProps) => (
                        <WrappedComponent {...props} formikProps={formikProps} config={componentConfig}/>
                    )}
                </Formik>
            </>
        )}
    </>
  );
};

export default withFormik;