import * as Yup from "yup";

export const resetPasswordInitialValues = {
  new_password: "",
  confirm_password: ""
};

export const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("New Password is required"),
  confirm_password: Yup.string()
    .required("Confirm pasword required")
    .oneOf([Yup.ref("new_password"), null], "Password must match"),
});

export const setPasswordInitialValues = {
  new_password: "",
  confirm_password: ""
};

export const SetPasswordSchema = Yup.object({
  new_password: Yup.string().required('New password is required'),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref("new_password"), null], "Password must match")
});

export const forgotPasswordInitialValues = {
  email: '',
};
export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
});

export const signInInitialValues = {
  email: '',
  password: '',
  rememberMe: true
};
export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  rememberMe: Yup.boolean().required('Remember Me is required'),
});

export const signUpInitialValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  refferal: '',
};

export const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().required('Password is required'),
  first_name: Yup.string().min(3).max(255).required('First Name is required'),
  last_name: Yup.string().min(3).max(255).required('Last Name is required'),
  refferal: Yup.string().min(5).max(15),
});
